<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="110.5"
    height="91.566"
    viewBox="0 0 110.5 91.566"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_520" data-name="Retângulo 520" width="110.5" height="91.566" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_562" data-name="Grupo 562" transform="translate(0 -15.112)">
      <g id="Grupo_594" data-name="Grupo 594" transform="translate(0 15.112)">
        <g id="Grupo_593" data-name="Grupo 593" clip-path="url(#clip-path)">
          <path
            id="Caminho_1378"
            data-name="Caminho 1378"
            d="M162.728,165.98a9.611,9.611,0,0,0-9.46,7.988H139.111V156.942a1.609,1.609,0,1,0-3.219,0v18.634a1.612,1.612,0,0,0,1.609,1.61h15.767a9.6,9.6,0,1,0,9.46-11.207m6.379,9.6a6.379,6.379,0,1,1-6.379-6.379,6.386,6.386,0,0,1,6.379,6.379"
            transform="translate(-81.892 -93.608)"
            fill="#960b38"
          />
          <path
            id="Caminho_1379"
            data-name="Caminho 1379"
            d="M70.361,144.1a1.591,1.591,0,0,0-1.171.509L57.543,156.246A9.608,9.608,0,1,0,59.8,158.54l11.7-11.691a1.63,1.63,0,0,0,.471-1.145,1.609,1.609,0,0,0-1.609-1.606m-12.05,19.928a6.379,6.379,0,1,1-6.379-6.378,6.386,6.386,0,0,1,6.379,6.378"
            transform="translate(-25.513 -86.838)"
            fill="#960b38"
          />
          <path
            id="Caminho_1380"
            data-name="Caminho 1380"
            d="M61.522,34.2a9.611,9.611,0,0,0,9.459-7.987H85.291v15.71a1.609,1.609,0,1,0,3.219,0V24.6a1.612,1.612,0,0,0-1.609-1.61H70.982A9.6,9.6,0,1,0,61.522,34.2m-6.379-9.6a6.378,6.378,0,1,1,6.379,6.378A6.386,6.386,0,0,1,55.144,24.6"
            transform="translate(-31.291 -9.041)"
            fill="#960b38"
          />
          <path
            id="Caminho_1381"
            data-name="Caminho 1381"
            d="M40.381,108.116a1.61,1.61,0,0,0,0-3.219H19.057a9.6,9.6,0,1,0,0,3.219Zm-24.405-1.61A6.379,6.379,0,1,1,9.6,100.127a6.386,6.386,0,0,1,6.379,6.379"
            transform="translate(0 -58.4)"
            fill="#960b38"
          />
          <path
            id="Caminho_1382"
            data-name="Caminho 1382"
            d="M205.9,96.909a9.611,9.611,0,0,0-9.46,7.988h-20.6a1.61,1.61,0,0,0,0,3.219h20.6a9.6,9.6,0,1,0,9.46-11.207m6.379,9.6a6.379,6.379,0,1,1-6.379-6.379,6.386,6.386,0,0,1,6.379,6.379"
            transform="translate(-104.997 -58.4)"
            fill="#960b38"
          />
          <path
            id="Caminho_1383"
            data-name="Caminho 1383"
            d="M164.6,38.949a1.6,1.6,0,0,0,1.138-.471L176.12,28.094a1.6,1.6,0,0,0,.472-1.138v-7.9a9.6,9.6,0,1,0-3.219,0V26.29L163.461,36.2a1.609,1.609,0,0,0,1.138,2.747M168.6,9.6a6.379,6.379,0,1,1,6.379,6.379A6.386,6.386,0,0,1,168.6,9.6"
            transform="translate(-98.222 -0.001)"
            fill="#960b38"
          />
          <path
            id="Caminho_1384"
            data-name="Caminho 1384"
            d="M134.993,106.506a9.6,9.6,0,1,0-9.6,9.6,9.608,9.608,0,0,0,9.6-9.6m-15.976,0a6.379,6.379,0,1,1,6.379,6.379,6.386,6.386,0,0,1-6.379-6.379"
            transform="translate(-69.783 -58.4)"
            fill="#ffb500"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

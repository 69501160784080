<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 27.461 27.454">
    <path
      id="Caminho_900"
      data-name="Caminho 900"
      d="M22.318-20.561a12.91,12.91,0,0,0-9.2-3.814A13.023,13.023,0,0,0,.111-11.367a13.022,13.022,0,0,0,1.734,6.5L0,1.875,6.9.064a12.968,12.968,0,0,0,6.217,1.582h.006A13.133,13.133,0,0,0,26.25-11.361,13.059,13.059,0,0,0,22.318-20.561ZM13.119-.545A10.793,10.793,0,0,1,7.611-2.051l-.393-.234L3.129-1.213,4.219-5.2l-.258-.41a10.776,10.776,0,0,1-1.652-5.754A10.827,10.827,0,0,1,13.125-22.178a10.716,10.716,0,0,1,7.641,3.17,10.854,10.854,0,0,1,3.287,7.646A10.932,10.932,0,0,1,13.119-.545Zm5.93-8.1c-.322-.164-1.922-.949-2.221-1.055s-.516-.164-.732.164-.838,1.055-1.031,1.277-.381.246-.7.082a8.847,8.847,0,0,1-4.424-3.867c-.334-.574.334-.533.955-1.775a.6.6,0,0,0-.029-.568c-.082-.164-.732-1.764-1-2.414-.264-.633-.533-.545-.732-.557s-.4-.012-.621-.012a1.2,1.2,0,0,0-.867.4A3.652,3.652,0,0,0,6.5-14.25a6.368,6.368,0,0,0,1.324,3.363,14.524,14.524,0,0,0,5.555,4.91c2.062.891,2.871.967,3.9.814a3.329,3.329,0,0,0,2.191-1.547,2.718,2.718,0,0,0,.187-1.547C19.588-8.4,19.371-8.484,19.049-8.643Z"
      transform="translate(0.711 24.875)"
      :fill="color"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <svg
    id="Grupo_590"
    data-name="Grupo 590"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="95.45"
    height="96.559"
    viewBox="0 0 95.45 96.559"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_518" data-name="Retângulo 518" width="95.45" height="96.559" fill="#ffb500" />
      </clipPath>
    </defs>
    <g id="Grupo_589" data-name="Grupo 589" clip-path="url(#clip-path)">
      <path
        id="Caminho_1366"
        data-name="Caminho 1366"
        d="M92.781,2.268c-.03-.028-.062-.051-.092-.078-.01-.008-.019-.017-.029-.025A8.337,8.337,0,0,0,87.051,0H8.4a8.33,8.33,0,0,0-5.61,2.165l-.027.023c-.028.025-.058.047-.085.073A8.392,8.392,0,0,0,0,8.4V57.741a8.416,8.416,0,0,0,8.4,8.4H33.905a1.746,1.746,0,0,0,0-3.491H8.4a4.859,4.859,0,0,1-1.581-.268L34.127,39.208a1.746,1.746,0,1,0-2.26-2.662L4.1,60.106a4.9,4.9,0,0,1-.613-2.365V8.4A4.89,4.89,0,0,1,4.1,6.035L46.6,42.1a1.746,1.746,0,0,0,2.259,0L91.346,6.034A4.9,4.9,0,0,1,91.959,8.4V57.741a4.888,4.888,0,0,1-.613,2.363L73.3,44.789a1.745,1.745,0,1,0-2.259,2.662L88.632,62.382a4.878,4.878,0,0,1-1.581.267H82.72a1.746,1.746,0,0,0,0,3.491h4.331a8.415,8.415,0,0,0,8.4-8.4V8.4a8.385,8.385,0,0,0-2.669-6.131m-4.15,1.492L47.725,38.475,6.818,3.759A4.883,4.883,0,0,1,8.4,3.491H87.051a4.869,4.869,0,0,1,1.58.268"
        fill="#960b38"
      />
      <path
        id="Caminho_1367"
        data-name="Caminho 1367"
        d="M104.133,103.52a9.328,9.328,0,0,0-16.157,0L67.6,138.813a9.328,9.328,0,0,0,8.079,13.993h40.753a9.328,9.328,0,0,0,8.078-13.993ZM121.486,146.4a5.777,5.777,0,0,1-5.055,2.918H75.678a5.837,5.837,0,0,1-5.055-8.755L91,105.265a5.837,5.837,0,0,1,10.111,0l20.376,35.294a5.777,5.777,0,0,1,0,5.837"
        transform="translate(-37.743 -56.246)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1368"
        data-name="Caminho 1368"
        d="M132.983,135.352a1.747,1.747,0,0,0-1.746,1.746v15.365a1.746,1.746,0,1,0,3.491,0V137.1a1.747,1.747,0,0,0-1.746-1.746"
        transform="translate(-74.671 -77.012)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1369"
        data-name="Caminho 1369"
        d="M132.983,187.574a1.747,1.747,0,0,0-1.746,1.746V190.8a1.746,1.746,0,1,0,3.491,0V189.32a1.747,1.747,0,0,0-1.746-1.746"
        transform="translate(-74.671 -106.725)"
        fill="#ffb500"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="90.895"
    height="84.059"
    viewBox="0 0 90.895 84.059"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_497" data-name="Retângulo 497" width="90.895" height="84.059" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_560" data-name="Grupo 560" transform="translate(0 0)">
      <g id="Grupo_559" data-name="Grupo 559" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          id="Caminho_1271"
          data-name="Caminho 1271"
          d="M29.885,31.052,40.3,41.472c1.3,1.3,3.285,2.7,3.377,4.695.175,3.778-4.936,7.643-8.061,4.58-1.853-1.816-3.67-3.67-5.505-5.505a1.328,1.328,0,0,0-1.878,1.878c1.817,1.817,3.621,3.649,5.452,5.452,3.566,3.506,9.254,1.7,11.579-2.345a7.1,7.1,0,0,0,.218-7.075,10.708,10.708,0,0,0-1.75-2q-3.31-3.31-6.619-6.62-2.678-2.676-5.355-5.355a1.328,1.328,0,0,0-1.878,1.878"
          transform="translate(21.432 22.154)"
          fill="#960b38"
        />
        <path
          id="Caminho_1263"
          data-name="Caminho 1263"
          d="M7.92,38.459a4.733,4.733,0,0,1-.561-5.934,15.59,15.59,0,0,1,2.094-2.2c1.807-1.809,3.576-3.663,5.428-5.426a4.676,4.676,0,0,1,7.551,5.164,8.736,8.736,0,0,1-2.163,2.656L16.842,36.14c-.772.773-1.533,1.557-2.316,2.318a4.723,4.723,0,0,1-6.606,0c-1.23-1.186-3.109.688-1.878,1.878a7.42,7.42,0,0,0,8.107,1.525,11.767,11.767,0,0,0,3.518-2.789q1.946-1.948,3.9-3.9a18.8,18.8,0,0,0,2.755-3.021A7.33,7.33,0,0,0,16.3,21.176a9.175,9.175,0,0,0-4.233,2.78q-1.9,1.9-3.8,3.8a22.313,22.313,0,0,0-3.251,3.516,7.42,7.42,0,0,0,1.035,9.059c1.189,1.232,3.063-.646,1.878-1.878"
          transform="translate(3.012 16.131)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1264"
          data-name="Caminho 1264"
          d="M12.7,45.691a4.761,4.761,0,0,1,.179-6.785c1.087-1.1,2.186-2.186,3.277-3.277Q20.471,31.312,24.787,27c1.97-1.971,4.844-2.444,7-.395l-.389-.938c0,.811.9,1.428,1.251,2.122a4.723,4.723,0,0,1,.5,2.407,4.788,4.788,0,0,1-1.442,3.1c-1.005,1.025-2.032,2.032-3.047,3.046q-4.449,4.452-8.9,8.9a6.469,6.469,0,0,1-1.766,1.379,4.731,4.731,0,0,1-5.291-.927c-1.239-1.177-3.118.7-1.878,1.878a7.442,7.442,0,0,0,10.37-.011c1.037-.995,2.035-2.035,3.053-3.053,3.1-3.1,6.231-6.181,9.308-9.308a7.446,7.446,0,0,0,.11-10.469l.389.938c0-.949-1.126-1.646-1.851-2.072a7.471,7.471,0,0,0-3-.968,7.368,7.368,0,0,0-5.559,1.789c-1.115.957-2.109,2.083-3.148,3.12-3.177,3.177-6.376,6.332-9.53,9.531a7.453,7.453,0,0,0-.143,10.5c1.18,1.241,3.054-.639,1.878-1.878"
          transform="translate(6.717 17.383)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1265"
          data-name="Caminho 1265"
          d="M16.573,48.569h0l.938.389a4.728,4.728,0,0,1,.005-6.611c.736-.764,1.5-1.5,2.251-2.251,2.216-2.216,4.415-4.447,6.647-6.647a4.734,4.734,0,0,1,6.682-.076l-.388-.94c0,.727.676,1.172,1.032,1.741a4.649,4.649,0,0,1-.3,5.431,31.967,31.967,0,0,1-2.283,2.311c-2.311,2.313-4.608,4.64-6.935,6.937a4.74,4.74,0,0,1-6.711.1c-1.232-1.184-3.113.69-1.876,1.876a7.417,7.417,0,0,0,9.124.993,21.27,21.27,0,0,0,3.175-2.932c2.4-2.4,4.925-4.718,7.215-7.222A7.428,7.428,0,0,0,34.974,31.5l.389.938c0-.883-.964-1.5-1.625-1.931a6.982,6.982,0,0,0-2.417-.989,7.5,7.5,0,0,0-5.054.74,16.538,16.538,0,0,0-3.49,3.079L18,38.11a38.239,38.239,0,0,0-2.863,2.931,7.314,7.314,0,0,0-1.591,5.438,7.542,7.542,0,0,0,.848,2.7c.416.773,1.189,2.042,2.18,2.042a1.328,1.328,0,0,0,0-2.655"
          transform="translate(10.387 22.599)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1266"
          data-name="Caminho 1266"
          d="M22.3,52.231a4.729,4.729,0,0,1-.952-5.247,8.9,8.9,0,0,1,2-2.4l3.012-3.012a15.5,15.5,0,0,1,2.094-1.982,4.727,4.727,0,0,1,5.934.561q-.2-.467-.389-.938c0,.646.492.979.837,1.446a4.706,4.706,0,0,1,.69,4.244,6.852,6.852,0,0,1-1.994,2.711q-1.534,1.534-3.07,3.07a19.621,19.621,0,0,1-2.06,2,4.731,4.731,0,0,1-6.1-.448c-1.228-1.187-3.108.687-1.878,1.878a7.428,7.428,0,0,0,7.334,1.821,9.57,9.57,0,0,0,3.883-2.678l3.373-3.373a11.919,11.919,0,0,0,2.7-3.323,7.431,7.431,0,0,0-1.449-8.286c.129.313.26.625.389.938,0-.8-.773-1.322-1.352-1.743a7.208,7.208,0,0,0-1.789-.924,7.384,7.384,0,0,0-4.021-.241,8.592,8.592,0,0,0-4.267,2.649l-3.465,3.465a12.63,12.63,0,0,0-2.7,3.23,7.447,7.447,0,0,0,1.366,8.464c1.191,1.23,3.067-.648,1.878-1.878"
          transform="translate(14.085 27.808)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1267"
          data-name="Caminho 1267"
          d="M33.716,39.452l5.935,5.935,3.771,3.771a13.422,13.422,0,0,0,2.483,2.217c5.463,3.231,12.3-2.885,11.165-8.65a7.169,7.169,0,0,0-2.283-3.67L51.422,35.69q-3.488-3.485-6.972-6.971a1.327,1.327,0,0,0-1.876,1.876c3.661,3.661,7.406,7.257,10.991,10.993,3.407,3.548-2.426,10.023-6.427,7.433a12.459,12.459,0,0,1-1.837-1.74l-3.493-3.492q-3.106-3.108-6.213-6.215a1.328,1.328,0,0,0-1.878,1.878"
          transform="translate(25.649 21.803)"
          fill="#960b38"
        />
        <path
          id="Caminho_1268"
          data-name="Caminho 1268"
          d="M46.263,8.971C42.424,5.132,38.15,2.122,32.81.786A26.386,26.386,0,0,0,.6,32.068c1.318,6.031,4.757,10.6,9.025,14.869A1.328,1.328,0,0,0,11.5,45.061C8.263,41.826,5.42,38.517,3.918,34.1A23.728,23.728,0,0,1,30.341,2.97c5.631.977,10.073,3.906,14.044,7.879a1.328,1.328,0,0,0,1.878-1.878"
          transform="translate(0 -0.001)"
          fill="#960b38"
        />
        <path
          id="Caminho_1270"
          data-name="Caminho 1270"
          d="M33.716,39.452l5.935,5.935,3.771,3.771a13.422,13.422,0,0,0,2.483,2.217c5.463,3.231,12.3-2.885,11.165-8.65a7.169,7.169,0,0,0-2.283-3.67L51.422,35.69q-3.488-3.485-6.972-6.971a1.327,1.327,0,0,0-1.876,1.876c3.661,3.661,7.406,7.257,10.991,10.993,3.407,3.548-2.426,10.023-6.427,7.433a12.459,12.459,0,0,1-1.837-1.74l-3.493-3.492q-3.106-3.108-6.213-6.215a1.328,1.328,0,0,0-1.878,1.878"
          transform="translate(25.649 21.803)"
          fill="#960b38"
        />
        <path
          id="Caminho_1272"
          data-name="Caminho 1272"
          d="M69.367,46.222c4.148-4.148,7.3-8.875,8.432-14.727A26.369,26.369,0,0,0,42.431,1.75c-7.85,3.072-13.568,10.386-19.374,16.215-1.908,1.917-4.337,3.791-5.9,6.017a4.788,4.788,0,0,0,1.428,6.56,10.04,10.04,0,0,0,9.3.97c3.516-1.458,6.438-4.4,9.68-6.376a14.014,14.014,0,0,1,6.907-1.954,20.155,20.155,0,0,0,5.288-.588,12.638,12.638,0,0,0,7.666-6.238c.795-1.511-1.5-2.854-2.292-1.34A10.084,10.084,0,0,1,48.45,20.2c-3.115.761-6.258.179-9.356,1.292-3.277,1.177-6.148,3.578-9,5.527a14.922,14.922,0,0,1-3.853,2.269c-2.081.632-9.554-.589-6.626-4.107,1.455-1.745,3.272-3.285,4.874-4.893q3.966-3.982,7.933-7.965a41.909,41.909,0,0,1,6.387-5.741A23.976,23.976,0,0,1,70.013,11a23.878,23.878,0,0,1,5.523,17.762c-.612,6.222-3.713,11.253-8.047,15.585a1.328,1.328,0,0,0,1.878,1.878"
          transform="translate(12.609 0.005)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1269"
          data-name="Caminho 1269"
          d="M33.631,32.428l9.579,9.579,5.052,5.052A6.814,6.814,0,0,0,55.288,49.1c3.906-1.159,6.988-5.5,5.81-9.608a9.23,9.23,0,0,0-2.816-3.881Q52.546,29.874,46.81,24.139l-9.5-9.5a1.328,1.328,0,0,0-1.878,1.878L53.993,35.075c1.124,1.124,2.258,2.237,3.371,3.371,1.977,2.016,1.5,4.9-.529,6.783-1.437,1.333-3.822,2.132-5.6.956a7.354,7.354,0,0,1-1.1-1q-2.264-2.262-4.525-4.525Q40.561,35.605,35.509,30.55a1.328,1.328,0,0,0-1.878,1.878"
          transform="translate(25.584 10.962)"
          fill="#960b38"
        />
      </g>
    </g>
  </svg>
</template>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="48.759"
    height="65.368"
    viewBox="0 0 48.759 65.368"
  >
    <g id="Grupo_549" data-name="Grupo 549" transform="translate(0 0)">
      <g id="Grupo_553" data-name="Grupo 553" transform="translate(0 0)">
        <path
          id="Caminho_1154"
          data-name="Caminho 1154"
          d="M31.018,35.363,24.6,34.431,21.74,28.642a.642.642,0,0,0-.614-.387.656.656,0,0,0-.748.387l-2.857,5.788-6.421.932a.8.8,0,0,0-.347,1.319l4.588,4.472q-.535,3.109-1.067,6.221a.8.8,0,0,0,1.16.89l5.625-2.958,5.627,2.958a.8.8,0,0,0,1.16-.89q-.535-3.111-1.067-6.221,2.292-2.237,4.588-4.472a.8.8,0,0,0-.349-1.319M26.012,39.7c-.267.26-.734.588-.839.969a3.337,3.337,0,0,0,.139,1.231l.387,2.258q.172.994.341,1.99-.911-.481-1.826-.96l-1.97-1.036a3.093,3.093,0,0,0-1.185-.521,2.939,2.939,0,0,0-1.127.491L17.9,45.185l-1.826.96.341-1.99q.189-1.1.376-2.194a3.517,3.517,0,0,0,.15-1.3c-.1-.364-.537-.675-.792-.923l-1.641-1.6q-.756-.738-1.51-1.474l1.982-.288,2.392-.347a2.5,2.5,0,0,0,1.148-.27,2.8,2.8,0,0,0,.6-1.018q.513-1.042,1.027-2.082.456-.923.91-1.844l.868,1.757q.535,1.085,1.071,2.169a2.786,2.786,0,0,0,.6,1.018,2.51,2.51,0,0,0,1.148.27l2.3.334,2.078.3-1.511,1.474L26.012,39.7"
          transform="translate(3.32 8.902)"
          fill="#960b38"
        />
        <path
          id="Caminho_1155"
          data-name="Caminho 1155"
          d="M34.921,32.452V25.015a.8.8,0,0,0-.788-.789H32.683L19.756,3.407,17.885.392a.975.975,0,0,0-.07-.075.712.712,0,0,0-.13-.137.565.565,0,0,0-.066-.049A.669.669,0,0,0,17.39.039c-.013,0-.025-.007-.037-.008A.77.77,0,0,0,17.2,0H.792A.8.8,0,0,0,.111,1.189L10.468,17.871c1.31,2.108,2.537,4.331,3.929,6.4a.791.791,0,0,0-.563.742v7.431a17.6,17.6,0,0,0-3.459,3.195A18.167,18.167,0,0,0,27.763,65.068a18.143,18.143,0,0,0,7.157-32.616M29.689,22.394q.566.913,1.135,1.828H24.475L11.548,3.4,10.415,1.579h6.349q6.463,10.407,12.925,20.815M3.346,3.407,2.21,1.579H8.558L21.487,22.4l1.131,1.822H16.269L3.346,3.407M15.412,25.8h17.93V31.42a17.944,17.944,0,0,0-17.93.028Zm14.5,37.059a16.6,16.6,0,1,1-2.132-31.894,16.6,16.6,0,0,1,2.132,31.894"
          transform="translate(0 0)"
          fill="#960b38"
        />
        <path
          id="Caminho_1156"
          data-name="Caminho 1156"
          d="M17.578,26.407c.965-.305.552-1.829-.42-1.522A15.432,15.432,0,0,0,8.8,31.305c-.548.859.817,1.651,1.363.8a13.736,13.736,0,0,1,7.415-5.695"
          transform="translate(2.734 7.831)"
          fill="#960b38"
        />
        <path
          id="Caminho_1157"
          data-name="Caminho 1157"
          d="M41.7,0H25.914c-.575,0-.979-.034-1.363.476-.463.616-.829,1.336-1.235,1.99l-3.3,5.319c-.537.865.829,1.658,1.363.8l4.348-7h6.35L25.2,12.654l-1.081,1.74c-.537.865.827,1.658,1.363.8L33.093,2.928q.42-.675.838-1.349h6.347L29.824,18.421,28.22,21c-.537.865.829,1.658,1.364.8q5.595-9.016,11.193-18.03l1.6-2.58A.8.8,0,0,0,41.7,0"
          transform="translate(6.27 0)"
          fill="#960b38"
        />
      </g>
    </g>
  </svg>
</template>

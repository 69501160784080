<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="116"
    height="117"
    viewBox="0 0 116 117"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Retângulo_493"
          data-name="Retângulo 493"
          width="116"
          height="117"
          transform="translate(0.006 -0.073)"
          fill="#960b38"
        />
      </clipPath>
    </defs>
    <g id="Grupo_552" data-name="Grupo 552" transform="translate(-0.006 0.073)">
      <g id="Grupo_552-2" data-name="Grupo 552" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          id="Caminho_1234"
          data-name="Caminho 1234"
          d="M30.532,67.768c12.19-2.194,20.905-13.155,21.073-25.382A26.167,26.167,0,0,0,34.878,18.216,25.835,25.835,0,0,0,3.581,55.6,26.115,26.115,0,0,0,30.532,67.768M8.469,56.591A22.394,22.394,0,0,1,29.937,20.382a22.395,22.395,0,0,1,3.425,43.07A22.618,22.618,0,0,1,8.469,56.591"
          transform="translate(0 21.504)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1235"
          data-name="Caminho 1235"
          d="M30.493,27.012c1.61-1.53-.829-3.965-2.437-2.437Q22.243,30.1,16.43,35.619l-.611.581L9.234,31.156a1.731,1.731,0,0,0-2.357.618,1.767,1.767,0,0,0,.618,2.357L15.08,39.94a1.728,1.728,0,0,0,2.086-.269q5.813-5.523,11.628-11.044.851-.806,1.7-1.615"
          transform="translate(8.614 31.245)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1236"
          data-name="Caminho 1236"
          d="M102.316,31.635c-.007-.106-.046-.179-.06-.278a1.535,1.535,0,0,0-.446-1.472l-.662-.662c-.317-.333-.662-.664-1.043-1.045Q91.751,19.822,83.4,11.471q-3.935-3.938-7.874-7.877c-.9-.9-2.063-2.568-3.326-3.225a1.471,1.471,0,0,0-.211-.1C71.924.246,71.859.207,71.8.186,70.647-.193,69,.124,67.819.124H12.194a1.749,1.749,0,0,0-1.723,1.723v31a1.724,1.724,0,0,0,3.446,0V3.57h55.7V31.1a1.749,1.749,0,0,0,1.723,1.723H90.684a1.724,1.724,0,0,0,0-3.446H73.061V6.007l22.67,22.67,1.943,1.943c.2.2.714.889,1.142,1.289.016.342.055.714.055.822v80.757H13.917V94.937a1.724,1.724,0,0,0-3.446,0V115.21a1.748,1.748,0,0,0,1.723,1.723h88.4a1.746,1.746,0,0,0,1.723-1.723V42.888c0-3.719.255-7.541,0-11.253"
          transform="translate(13.581 -0.001)"
          fill="#960b38"
        />
        <path
          id="Caminho_1237"
          data-name="Caminho 1237"
          d="M28.289,12.488H18.849a1.723,1.723,0,1,0,0,3.446h9.441a1.723,1.723,0,0,0,0-3.446"
          transform="translate(22.211 16.197)"
          fill="#960b38"
        />
        <path
          id="Caminho_1238"
          data-name="Caminho 1238"
          d="M21.705,43.167h-2.89a1.724,1.724,0,0,0,0,3.446H42.138a1.724,1.724,0,0,0,0-3.446Z"
          transform="translate(22.246 55.989)"
          fill="#960b38"
        />
        <path
          id="Caminho_1239"
          data-name="Caminho 1239"
          d="M67,20.508H23.431a1.724,1.724,0,0,0,0,3.446H67a1.724,1.724,0,0,0,0-3.446"
          transform="translate(28.232 26.599)"
          fill="#960b38"
        />
        <path
          id="Caminho_1240"
          data-name="Caminho 1240"
          d="M64.083,27.064H25.677a1.724,1.724,0,0,0,0,3.446H64.083a1.724,1.724,0,0,0,0-3.446"
          transform="translate(31.145 35.102)"
          fill="#960b38"
        />
        <path
          id="Caminho_1241"
          data-name="Caminho 1241"
          d="M67,37.066a1.724,1.724,0,0,0,0-3.446H23.431a1.724,1.724,0,0,0,0,3.446H67Z"
          transform="translate(28.232 43.606)"
          fill="#960b38"
        />
      </g>
    </g>
  </svg>
</template>

<template>
  <svg
    id="Grupo_454"
    data-name="Grupo 454"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    viewBox="0 0 111.567 117.306"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_461" data-name="Retângulo 461" width="111.567" height="117.306" fill="#fb9335" />
      </clipPath>
    </defs>
    <g id="Grupo_453" data-name="Grupo 453">
      <path
        id="Caminho_1021"
        data-name="Caminho 1021"
        d="M133.931,137.728a14.063,14.063,0,0,0-.046,28.126h.048a14.063,14.063,0,0,0,.048-28.126Zm11.084,14.1a11.094,11.094,0,0,1-11.082,11.044H133.9a11.082,11.082,0,0,1,.037-22.164h.039a11.082,11.082,0,0,1,11.044,11.12"
        transform="translate(-78.16 -89.804)"
        fill="#00b4ff"
      />
      <path
        id="Caminho_1022"
        data-name="Caminho 1022"
        d="M124.465,238.046c-4.019-1.325-9.239-1.6-16.9-1.627s-12.9.212-16.929,1.513a15.26,15.26,0,0,0-10.952,15.156l-.106,12.858a5.407,5.407,0,0,0,5.4,5.452l44.988.062h.008a5.406,5.406,0,0,0,5.406-5.43l-.055-12.738a15.275,15.275,0,0,0-10.859-15.245m7.225,29.719a2.408,2.408,0,0,1-1.717.713h0l-9.27-.013.11-16.833a1.491,1.491,0,1,0-2.981-.02l-.11,16.849-20.587-.028L97.1,251.524a1.49,1.49,0,0,0-1.491-1.488h0a1.49,1.49,0,0,0-1.488,1.493l.029,16.9-9.169-.013a2.426,2.426,0,0,1-2.422-2.446l.107-12.887c0-.02,0-.04,0-.059a12.193,12.193,0,0,1,8.887-12.256c3.656-1.179,8.633-1.4,16-1.369s12.334.276,15.976,1.477a12.208,12.208,0,0,1,8.812,12.333c0,.021,0,.042,0,.063l.055,12.768a2.41,2.41,0,0,1-.708,1.723"
        transform="translate(-51.888 -154.153)"
        fill="#00b4ff"
      />
      <path
        id="Caminho_1023"
        data-name="Caminho 1023"
        d="M231.7,150.486h-.043a12.321,12.321,0,0,0-.041,24.642h.043a12.321,12.321,0,0,0,.041-24.642m6.54,18.948a9.34,9.34,0,1,1-6.582-15.967h.033a9.34,9.34,0,0,1,6.55,15.967"
        transform="translate(-143.013 -98.123)"
        fill="#00b4ff"
      />
      <path
        id="Caminho_1024"
        data-name="Caminho 1024"
        d="M42.8,175.127h.043a12.321,12.321,0,0,0-.041-24.642h-.043a12.321,12.321,0,0,0,.041,24.642m-6.626-18.9a9.277,9.277,0,0,1,6.595-2.758H42.8a9.34,9.34,0,0,1,.031,18.68H42.8a9.34,9.34,0,0,1-6.626-15.922"
        transform="translate(-19.875 -98.122)"
        fill="#1f214a"
      />
      <path
        id="Caminho_1025"
        data-name="Caminho 1025"
        d="M267.5,256.98a13.4,13.4,0,0,0-9.527-13.375c-3.628-1.2-6.166-1.395-12.457-1.417-2.092-.007-3.137.007-4.863.07a1.49,1.49,0,1,0,.107,2.979c1.7-.061,2.676-.075,4.746-.068,6.024.021,8.269.19,11.534,1.267a10.36,10.36,0,0,1,7.48,10.464c0,.021,0,.043,0,.064l.045,9a2.71,2.71,0,0,1-2.709,2.721h0l-4.821-.008.092-11.7a1.49,1.49,0,0,0-1.479-1.5h-.012a1.491,1.491,0,0,0-1.491,1.479l-.092,11.72-8.746-.014h0a1.491,1.491,0,0,0,0,2.981l16.548.027h.009a5.691,5.691,0,0,0,5.69-5.716Z"
        transform="translate(-155.98 -157.915)"
        fill="#00b4ff"
      />
      <path
        id="Caminho_1026"
        data-name="Caminho 1026"
        d="M24.427,242.257c-.681-.024-.818-.04-.883-.047a13.4,13.4,0,0,0-1.516-.023c-6.29.022-8.829.221-12.456,1.417A13.4,13.4,0,0,0,.044,256.98L0,265.953a5.691,5.691,0,0,0,5.691,5.715H5.7l16.548-.027a1.49,1.49,0,0,0,0-2.981h0l-8.746.014-.092-11.72a1.491,1.491,0,0,0-1.49-1.479H11.9a1.491,1.491,0,0,0-1.479,1.5l.092,11.7-4.821.008h0a2.71,2.71,0,0,1-2.709-2.72l.045-9c0-.021,0-.043,0-.064a10.359,10.359,0,0,1,7.48-10.464c3.265-1.077,5.509-1.246,11.533-1.267.583,0,1.072,0,1.176,0,.165.018.374.037,1.106.064a1.491,1.491,0,1,0,.107-2.979"
        transform="translate(0 -157.915)"
        fill="#1f214a"
      />
      <path
        id="Caminho_1027"
        data-name="Caminho 1027"
        d="M194.973,32.188c.806-.058,1.631-.088,2.451-.088,10.68,0,19.7,5.175,19.7,11.3,0,3.211-2.467,6.3-6.769,8.477a1.491,1.491,0,0,0-.756.9l-2.2,7.363-6.879-5.209a1.5,1.5,0,0,0-.993-.3c-.695.043-1.4.065-2.109.065a30.732,30.732,0,0,1-10.544-1.786,1.491,1.491,0,1,0-1.034,2.8,33.732,33.732,0,0,0,11.578,1.971c.579,0,1.161-.014,1.738-.041l8.157,6.177a1.491,1.491,0,0,0,2.328-.762l2.632-8.82c4.985-2.7,7.828-6.62,7.828-10.836,0-3.959-2.46-7.625-6.928-10.321a30.913,30.913,0,0,0-15.756-3.961c-.892,0-1.79.032-2.667.1a1.491,1.491,0,1,0,.216,2.973"
        transform="translate(-120.544 -18.987)"
        fill="#00b4ff"
      />
      <path
        id="Caminho_1028"
        data-name="Caminho 1028"
        d="M40.012,31.892,43,43.751a1.491,1.491,0,0,0,2.3.854l10.623-7.5a48.655,48.655,0,0,0,5.449.3c7.886,0,15.323-1.867,20.939-5.257,5.852-3.532,9.075-8.308,9.075-13.449S88.169,8.789,82.317,5.257C76.7,1.867,69.265,0,61.378,0S46.056,1.867,40.439,5.257c-5.852,3.532-9.074,8.308-9.074,13.449,0,5.018,3.065,9.682,8.647,13.186M41.98,7.809c5.158-3.113,12.047-4.828,19.4-4.828s14.24,1.715,19.4,4.828c4.923,2.971,7.634,6.842,7.634,10.9S85.7,26.632,80.777,29.6c-5.158,3.114-12.047,4.828-19.4,4.828a45.5,45.5,0,0,1-5.609-.344,1.494,1.494,0,0,0-1.091.255l-9.318,6.574L42.754,30.574a1.491,1.491,0,0,0-.681-.915c-4.983-2.979-7.727-6.868-7.727-10.953,0-4.056,2.711-7.926,7.634-10.9"
        transform="translate(-20.451 0)"
        fill="#1f214a"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <svg
    id="Grupo_702"
    data-name="Grupo 702"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="122.69"
    height="128.027"
    viewBox="0 0 122.69 128.027"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_588" data-name="Retângulo 588" width="122.69" height="128.027" fill="#ffb500" />
      </clipPath>
    </defs>
    <g id="Grupo_701" data-name="Grupo 701" clip-path="url(#clip-path)">
      <path
        id="Caminho_1573"
        data-name="Caminho 1573"
        d="M51.605,151.6a33.591,33.591,0,0,0-14.141-8.723c-4.338-1.22-8.1-.911-9.81.8s-2.022,5.475-.8,9.811a33.586,33.586,0,0,0,8.723,14.141,33.585,33.585,0,0,0,14.14,8.723,18.317,18.317,0,0,0,4.895.739,6.9,6.9,0,0,0,4.916-1.542c1.715-1.715,2.022-5.474.8-9.81a33.6,33.6,0,0,0-8.723-14.14m5.925,21.956c-.647.648-3.062,1.2-7.052.082a30.751,30.751,0,0,1-12.91-8c-9.147-9.148-9.467-18.414-7.92-19.962a4.421,4.421,0,0,1,2.9-.725,15.518,15.518,0,0,1,4.148.643,30.747,30.747,0,0,1,12.91,8,30.749,30.749,0,0,1,8,12.909c1.122,3.991.566,6.4-.082,7.052"
        transform="translate(-11.667 -63.506)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1574"
        data-name="Caminho 1574"
        d="M116.993,22.167a1.41,1.41,0,0,0,0-1.995l-9.142-9.142a1.41,1.41,0,0,0-1-.413h0a1.408,1.408,0,0,0-1,.414l-3.587,3.586L88.049.4a1.452,1.452,0,0,0-1.995,0l-7.41,7.41a1.41,1.41,0,0,0,0,1.995c2.452,2.452,3.382,7.223.906,9.7s-7.247,1.545-9.7-.906a1.41,1.41,0,0,0-1.995,0l-7.41,7.41a1.41,1.41,0,0,0,0,1.995l14.22,14.22L44.205,72.683c-13.8-10.2-29.833-11.407-38.473-2.767C-4.342,79.99-.79,99.934,13.652,114.375c8.889,8.889,19.861,13.653,29.336,13.653,5.917,0,11.251-1.859,15.124-5.733,8.641-8.641,7.432-24.673-2.771-38.477ZM63.438,27l5.489-5.489c3.608,2.692,9.311,3.289,12.618-.018s2.709-9.01.018-12.618L87.052,3.39l13.223,13.223L76.661,40.227Zm-7.32,93.3c-8.975,8.973-27.13,5.422-40.471-7.92s-16.895-31.5-7.92-40.471c3.332-3.332,7.967-4.96,13.187-4.96,7.048,0,15.16,2.969,22.563,8.719a1.407,1.407,0,0,0,1.92-.184l.024-.028L77.664,43.223,90.384,30.5,103.27,17.614s.008-.011.012-.015l3.573-3.574L114,21.17,52.566,82.6c-.02.017-.039.034-.059.052a1.411,1.411,0,0,0-.15,1.9c10.01,12.883,11.591,27.918,3.76,35.749"
        transform="translate(0 0)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1575"
        data-name="Caminho 1575"
        d="M189.611,128.7H154.345a1.411,1.411,0,0,0,0,2.821h35.266a1.411,1.411,0,1,0,0-2.821"
        transform="translate(-68.331 -57.503)"
        fill="#960b38"
      />
      <path
        id="Caminho_1576"
        data-name="Caminho 1576"
        d="M180.116,160.573H166.245a1.411,1.411,0,1,0,0,2.821h13.871a1.411,1.411,0,0,0,0-2.821"
        transform="translate(-73.648 -71.745)"
        fill="#960b38"
      />
      <path
        id="Caminho_1577"
        data-name="Caminho 1577"
        d="M10.483,80.477H45.749a1.411,1.411,0,1,0,0-2.821H10.483a1.411,1.411,0,1,0,0,2.821"
        transform="translate(-4.053 -34.697)"
        fill="#960b38"
      />
      <path
        id="Caminho_1578"
        data-name="Caminho 1578"
        d="M37.258,48.6H51.129a1.411,1.411,0,1,0,0-2.821H37.258a1.411,1.411,0,1,0,0,2.821"
        transform="translate(-16.017 -20.455)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

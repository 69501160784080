<template>
  <svg
    id="Grupo_487"
    data-name="Grupo 487"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="74.422"
    height="85"
    viewBox="0 0 74.422 85"
  >
    <g id="Grupo_486" data-name="Grupo 486">
      <path
        id="Caminho_1090"
        data-name="Caminho 1090"
        d="M93.791,88.7H83.414l1,1V79.326a1.013,1.013,0,0,0-1-1H72.3a1.014,1.014,0,0,0-1,1V89.7l1-1H61.922a1.014,1.014,0,0,0-1,1v11.116a1.014,1.014,0,0,0,1,1H72.3l-1-1v10.376a1.014,1.014,0,0,0,1,1H83.414a1.014,1.014,0,0,0,1-1V100.818l-1,1H93.791a1.014,1.014,0,0,0,1-1V89.7a1,1,0,0,0-2,0v11.116l1-1H83.414a1.014,1.014,0,0,0-1,1v10.376l1-1H72.3l1,1V100.818a1.014,1.014,0,0,0-1-1H61.922l1,1V89.7l-1,1H72.3a1.014,1.014,0,0,0,1-1V79.326l-1,1H83.414l-1-1V89.7a1.014,1.014,0,0,0,1,1H93.791a1,1,0,0,0,0-2"
        transform="translate(-40.629 -52.235)"
        fill="#960b38"
      />
      <path
        id="Caminho_1091"
        data-name="Caminho 1091"
        d="M37.228,83a23.206,23.206,0,0,1-7.09-1.9,54.905,54.905,0,0,1-6.25-2.8,47.21,47.21,0,0,1-10.34-7.262c-6.407-6-10.842-14.05-11.429-22.9-.2-3.068-.07-6.181-.07-9.252v-25.1l-1,1a44.4,44.4,0,0,0,8.181-1.207A97.531,97.531,0,0,0,23.15,7.768Q26.094,6.354,29,4.872c2.261-1.147,4.45-2.527,7.021-2.819,4.553-.518,8.589,2.426,12.491,4.355a125.322,125.322,0,0,0,15.651,6.837,42.58,42.58,0,0,0,9.24,1.544l-1-1V37.137c0,2.941.019,5.883,0,8.825a34.11,34.11,0,0,1-9.394,22.961,45.489,45.489,0,0,1-11.041,8.611A53.944,53.944,0,0,1,45.357,80.7c-2.543,1.009-5.371,2.21-8.129,2.3-1.283.044-1.289,2.043,0,2,4.38-.15,9.017-2.345,12.908-4.245A50.716,50.716,0,0,0,60.821,73.79C67.484,68.1,72.489,60.324,73.932,51.6a69.361,69.361,0,0,0,.475-11.356V13.79a1.026,1.026,0,0,0-1-1,38.811,38.811,0,0,1-7.984-1.238,107.012,107.012,0,0,1-14.938-6.4C46.678,3.291,42.733.482,38.429.054S30.636,1.821,26.942,3.679a137.022,137.022,0,0,1-16.793,7.506,37.573,37.573,0,0,1-9.1,1.606,1.023,1.023,0,0,0-1,1V38.6c0,3.338-.164,6.74.11,10.072A36.812,36.812,0,0,0,11.412,71.759a48.74,48.74,0,0,0,11.467,8.266,58.994,58.994,0,0,0,6.728,3A24.548,24.548,0,0,0,37.228,85c1.286.044,1.284-1.954,0-2"
        transform="translate(0 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1092"
        data-name="Caminho 1092"
        d="M19.655,75.507v-6.3a1,1,0,0,0-2,0v6.3a1,1,0,0,0,2,0"
        transform="translate(-11.775 -45.509)"
        fill="#960b38"
      />
      <path
        id="Caminho_1093"
        data-name="Caminho 1093"
        d="M20.046,122.04c-.95-4.872-.535-10.066-.535-15.009a1,1,0,0,0-2,0c0,5.142-.382,10.471.607,15.54.246,1.261,2.173.727,1.927-.531"
        transform="translate(-11.63 -70.734)"
        fill="#960b38"
      />
      <path
        id="Caminho_1094"
        data-name="Caminho 1094"
        d="M37.6,28.91a133.475,133.475,0,0,0,12.654-5.448q2.911-1.4,5.787-2.871c1.53-.78,3.313-2.066,5.051-2.2,1.776-.141,3.281.883,4.8,1.663q2.254,1.157,4.524,2.28c3.509,1.733,7.054,3.4,10.667,4.9a40.975,40.975,0,0,0,10.759,3.339l-1-1v24.65c0,1.071.016,2.144,0,3.215A27.417,27.417,0,0,1,86.969,70.8C82.31,78.69,74.307,84.1,65.818,87.176a20.3,20.3,0,0,1-3.781,1.219,8.7,8.7,0,0,1-3.814-.847,49.953,49.953,0,0,1-7.312-3.131A40.691,40.691,0,0,1,39.54,75.64c-.86-.96-2.27.457-1.413,1.413A41.776,41.776,0,0,0,48.51,85.376,50.94,50.94,0,0,0,54.557,88.3c2.021.826,4.522,2.037,6.727,2.152,2.142.111,4.667-1.166,6.627-1.945a51.938,51.938,0,0,0,6.1-2.875A41.334,41.334,0,0,0,84.53,77.415a31.138,31.138,0,0,0,8.306-21.074q.009-6.8,0-13.6V29.573a1.027,1.027,0,0,0-1-1c-3.31-.389-6.572-1.765-9.629-3.019C78.425,24,74.725,22.243,71.061,20.428q-2.228-1.1-4.439-2.244A13.487,13.487,0,0,0,62.3,16.42c-1.919-.25-3.494.5-5.146,1.355q-2.52,1.3-5.06,2.567a142.317,142.317,0,0,1-15.029,6.641c-1.2.432-.683,2.364.531,1.927"
        transform="translate(-24.263 -10.918)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    id="Grupo_554"
    data-name="Grupo 554"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="113"
    height="106.443"
    viewBox="0 0 113 106.443"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_494" data-name="Retângulo 494" width="113" height="106.443" fill="#ffb500" />
      </clipPath>
    </defs>
    <g id="Grupo_553" data-name="Grupo 553" transform="translate(0 0)" clip-path="url(#clip-path)">
      <path
        id="Caminho_1242"
        data-name="Caminho 1242"
        d="M24.22,20.09a7.99,7.99,0,1,1-6.573-7.871A8.068,8.068,0,0,1,24.22,20.09a1.513,1.513,0,0,0,3.024,0,11.174,11.174,0,0,0-7.206-10.33A11.016,11.016,0,0,0,6.76,25.719,11.153,11.153,0,0,0,18.324,30.9a11.127,11.127,0,0,0,8.919-10.81,1.513,1.513,0,0,0-3.024,0"
        transform="translate(5.32 9.223)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1243"
        data-name="Caminho 1243"
        d="M12.964,66.136V39.466a1.513,1.513,0,0,0-3.024,0v26.67a1.513,1.513,0,0,0,3.024,0"
        transform="translate(10.096 38.603)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1244"
        data-name="Caminho 1244"
        d="M8.277,35.324Q8.86,46.7,9.444,58.087c.411,8.018.361,16.216,1.242,24.2.544,4.938,7.134,3.6,10.611,3.6,3.461,0,10.548,1.433,11.113-3.542.853-7.5.742-15.239,1.107-22.775q.653-13.4,1.3-26.8L33.307,34.28c3.731,0,7.488.256,11.1-.859a19.053,19.053,0,0,0,7.271-3.979c1.562-1.433,3.6-4,2.693-6.263-1.028-2.58-4.4-2.114-6.66-2.2L35.46,20.535c-6.825-.25-13.818-1.038-20.582.179-3.463.625-8.5.7-11.4,2.78C.03,25.957.022,29.66.022,33.435c0,4.225-.05,8.456,0,12.683A9.992,9.992,0,0,0,9.788,56.362c1.945.079,1.943-2.945,0-3.024C.871,52.978,3.046,41.168,3.046,35.2c0-2.772-.623-6.553,1.526-8.7,2.056-2.056,7.043-2.121,9.822-2.63,5.4-.988,10.51-.693,15.952-.494l19.556.716c1.27.046,1.965-.21,1.127,1.494-1.179,2.391-4.416,3.888-6.791,4.719-3.55,1.242-7.236.957-10.931.957A1.542,1.542,0,0,0,31.8,32.768l-2.3,47.341a6.891,6.891,0,0,1-.107,2.181c-.579,1.093-3.213.57-4.215.57H17.488c-1,0-3.251.478-3.783-.621a8.118,8.118,0,0,1-.125-2.459l-.26-5.061-.762-14.882L11.3,35.324c-.1-1.937-3.124-1.947-3.024,0"
        transform="translate(0 20.367)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1245"
        data-name="Caminho 1245"
        d="M24.477,50.4l21.2-19.109H43.538q3.659,3.919,7.321,7.835a1.533,1.533,0,0,0,2.139,0L74.676,16.938c1.361-1.391-.776-3.532-2.139-2.137L50.859,36.984H53l-7.323-7.833a1.538,1.538,0,0,0-2.137,0l-21.2,19.107c-1.447,1.306.7,3.439,2.139,2.139"
        transform="translate(22.2 14.573)"
        fill="#960b38"
      />
      <path
        id="Caminho_1246"
        data-name="Caminho 1246"
        d="M55.612,25.416l-.278-8.756q-.021-.635-.04-1.27a1.535,1.535,0,0,0-1.512-1.512q-4.378.142-8.756.28l-1.27.04a1.512,1.512,0,0,0,0,3.024q4.378-.139,8.756-.28.635-.018,1.27-.04L52.27,15.39l.278,8.756c.014.423.026.847.04,1.27a1.512,1.512,0,0,0,3.024,0"
        transform="translate(42.907 14.095)"
        fill="#960b38"
      />
      <path
        id="Caminho_1247"
        data-name="Caminho 1247"
        d="M90.024,36.83H22.645a1.513,1.513,0,0,0,0,3.024H90.024a1.513,1.513,0,0,0,0-3.024"
        transform="translate(21.517 37.408)"
        fill="#960b38"
      />
      <path
        id="Caminho_1248"
        data-name="Caminho 1248"
        d="M52.957,3.749V73.425a1.513,1.513,0,0,0,3.024,0V3.749a1.513,1.513,0,0,0-3.024,0"
        transform="translate(53.788 2.324)"
        fill="#960b38"
      />
      <path
        id="Caminho_1249"
        data-name="Caminho 1249"
        d="M9.8,5.287h93.276a1.513,1.513,0,0,0,0-3.024H9.8a1.513,1.513,0,0,0,0,3.024"
        transform="translate(8.467 2.298)"
        fill="#960b38"
      />
      <path
        id="Caminho_1250"
        data-name="Caminho 1250"
        d="M12.964,10.411V3.775a1.512,1.512,0,1,0-3.024,0v6.636a1.512,1.512,0,1,0,3.024,0"
        transform="translate(10.096 2.298)"
        fill="#960b38"
      />
      <path
        id="Caminho_1251"
        data-name="Caminho 1251"
        d="M31.448,1.513v4.56a1.512,1.512,0,0,0,3.024,0V1.513a1.512,1.512,0,0,0-3.024,0"
        transform="translate(31.942 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1252"
        data-name="Caminho 1252"
        d="M53.429,39.793v4.541l1.512-1.512H25.709l1.512,1.512V39.793a1.513,1.513,0,0,0-3.024,0v4.541a1.533,1.533,0,0,0,1.512,1.512H54.94a1.533,1.533,0,0,0,1.512-1.512V39.793a1.513,1.513,0,0,0-3.024,0"
        transform="translate(24.577 38.934)"
        fill="#960b38"
      />
      <path
        id="Caminho_1253"
        data-name="Caminho 1253"
        d="M29.914,41.658,25.79,60.452q-.293,1.336-.587,2.677c-.417,1.9,2.5,2.707,2.915.8l4.124-18.794.587-2.677c.417-1.9-2.5-2.707-2.915-.8"
        transform="translate(25.558 41.209)"
        fill="#960b38"
      />
      <path
        id="Caminho_1254"
        data-name="Caminho 1254"
        d="M35.451,42.461l4.124,18.794.587,2.677c.417,1.9,3.332,1.1,2.917-.8L38.954,44.335q-.293-1.339-.589-2.679c-.415-1.9-3.332-1.095-2.915.8"
        transform="translate(35.966 41.209)"
        fill="#960b38"
      />
      <path
        id="Caminho_1255"
        data-name="Caminho 1255"
        d="M27.6,49.866h21.58a1.513,1.513,0,0,0,0-3.024H27.6a1.513,1.513,0,0,0,0,3.024"
        transform="translate(26.551 47.577)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="85"
    height="85"
    viewBox="0 0 85 85"
  >
    <g id="Grupo_462" data-name="Grupo 462" transform="translate(0 0.024)">
      <g id="Grupo_461" data-name="Grupo 461">
        <path
          id="Caminho_1044"
          data-name="Caminho 1044"
          d="M84.482,210.23a6.461,6.461,0,0,0-8.434-3.478L57.955,214.6a6.442,6.442,0,0,0-6.135-8.411H31.043a13.133,13.133,0,0,0-6.6,1.785l-13.669,7.945-.73-1.167a1.1,1.1,0,0,0-1.427-.4L.611,218.342a1.1,1.1,0,0,0,.983,1.975l7.114-3.542,11.358,18.172c.011.021.017.042.029.063a1.075,1.075,0,0,0,.094.135l1.339,2.143a1.091,1.091,0,0,0,.929.511,1.128,1.128,0,0,0,.311-.044,1.113,1.113,0,0,0,.8-1.069,1.085,1.085,0,0,0-.24-.684l-.738-1.181,5.31-3.127a4.2,4.2,0,0,1,2.142-.581H51.77a14.359,14.359,0,0,0,5.638-1.139l.047-.021,23.572-11.319a6.458,6.458,0,0,0,3.455-8.4m-4.328,6.378-.056.025L56.523,227.954a12.165,12.165,0,0,1-4.753.954H30.041a6.411,6.411,0,0,0-3.263.887l-5.359,3.155L11.948,217.8l13.608-7.909a10.924,10.924,0,0,1,5.487-1.486H51.82a4.237,4.237,0,1,1,0,8.475l-16,.017a1.1,1.1,0,0,0,0,2.206h0l16-.017a6.412,6.412,0,0,0,2.1-.354,1.105,1.105,0,0,0,.238-.071l22.744-9.872a4.236,4.236,0,1,1,3.248,7.824"
          transform="translate(0 -152.823)"
          fill="#960b38"
        />
        <path
          id="Caminho_1045"
          data-name="Caminho 1045"
          d="M110.319,45.768l11.041-3.307c1.771,2.607,5.778,5.14,11.19,5.14s9.405-2.524,11.181-5.126l9.9,3.155a1.1,1.1,0,1,0,.67-2.1l-10.408-3.318c-1.264-1.4-1.772-3.929-1.506-7.537,3.923-3.756,4.59-9.2,5.193-14.139l0-.042c.42-3.49.6-7.532-1.237-11.042A13.911,13.911,0,0,0,138.291.942,18.217,18.217,0,0,0,132.549,0a17.968,17.968,0,0,0-5.743.942,13.913,13.913,0,0,0-8.051,6.508c-1.861,3.554-1.669,7.578-1.236,11.042.587,4.807,1.234,10.1,4.869,13.846.007.064.014.132.022.207.168,1.556.6,5.61-1.145,7.64l-11.579,3.469a1.1,1.1,0,1,0,.633,2.113m17.542-12.35c-6.646-2.855-7.378-8.847-8.153-15.195-.391-3.129-.578-6.733,1-9.747a11.723,11.723,0,0,1,6.8-5.443,15.756,15.756,0,0,1,5.038-.827,15.986,15.986,0,0,1,5.038.827,11.724,11.724,0,0,1,6.8,5.441c1.557,2.974,1.379,6.6,1,9.752l0,.04c-.618,5.06-1.211,9.893-4.82,13.083l0,0a12.234,12.234,0,0,1-3.325,2.067,1.1,1.1,0,1,0,.869,2.028,15.66,15.66,0,0,0,1.989-1.021c-.009,2.015.273,4.814,1.8,6.832-1.458,2.142-4.937,4.139-9.344,4.139s-7.871-1.987-9.333-4.124c1.486-2.026,1.647-4.9,1.55-7a15.423,15.423,0,0,0,2.225,1.175,1.1,1.1,0,0,0,.87-2.027"
          transform="translate(-80.711)"
          fill="#960b38"
        />
      </g>
    </g>
  </svg>
</template>

<template>
  <label for="check" :width="width" :height="height">
    <input id="check" type="checkbox" />
    <span style="margin-top: 0px"></span>
    <span></span>
    <span></span>
  </label>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '50px',
    },
    height: {
      type: String,
      default: '28px',
    },
  },
}
</script>

<style lang="scss" scoped>
label {
  display: flex;
  flex-direction: column;
  width: 40px;
  height: auto;
  cursor: pointer;
}

label span {
  background: rgb(255, 193, 41);
  border-radius: 10px;
  height: 3px;
  margin-top: 5px;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1);
}

span:nth-of-type(1) {
  width: 100%;
}

span:nth-of-type(2) {
  width: 75%;
  align-self: center;
}

span:nth-of-type(3) {
  width: 100%;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox']:hover ~ span:nth-of-type(1) {
  width: 75%;
}

input[type='checkbox']:hover ~ span:nth-of-type(2) {
  width: 100%;
}

input[type='checkbox']:hover ~ span:nth-of-type(3) {
  width: 75%;
  align-self: end;
}
</style>

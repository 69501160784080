<template>
  <svg
    id="Grupo_635"
    data-name="Grupo 635"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="132.654"
    height="132.654"
    viewBox="0 0 132.654 132.654"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_551" data-name="Retângulo 551" width="132.654" height="132.654" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_634" data-name="Grupo 634" clip-path="url(#clip-path)">
      <path
        id="Caminho_1442"
        data-name="Caminho 1442"
        d="M18.041,96.061v-10.8c0-.015,0-.028,0-.042s0-.028,0-.042v-37.7c0-.015,0-.028,0-.042s0-.028,0-.042V28.225A10.194,10.194,0,0,1,28.223,18.044H47.417l.019,0A1.444,1.444,0,0,0,48.88,16.6V1.446a1.445,1.445,0,0,0-2.889,0V15.155H28.223a13.086,13.086,0,0,0-13.071,13.07V45.991H1.445a1.445,1.445,0,0,0,0,2.889H15.152V83.774H1.445a1.445,1.445,0,1,0,0,2.889H15.152v9.4a1.445,1.445,0,0,0,2.889,0"
        transform="translate(0 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1443"
        data-name="Caminho 1443"
        d="M144.019,83.774H130.31V48.88h13.709a1.445,1.445,0,1,0,0-2.889H130.31V28.225a13.086,13.086,0,0,0-13.07-13.07H99.473V1.446a1.445,1.445,0,0,0-2.89,0V15.155H74.442a1.445,1.445,0,0,0,0,2.889H98.009l.018,0,.019,0H117.24a10.193,10.193,0,0,1,10.181,10.181V47.4c0,.011,0,.021,0,.032s0,.022,0,.033V85.185c0,.011,0,.022,0,.033s0,.022,0,.032v19.182a10.193,10.193,0,0,1-10.181,10.181H98.074c-.016,0-.031,0-.047,0s-.031,0-.046,0H60.292c-.016,0-.031,0-.046,0s-.031,0-.047,0H41.032a10.243,10.243,0,0,1-8.794-5.068A1.444,1.444,0,1,0,29.745,111a13.141,13.141,0,0,0,11.287,6.5H58.8v13.707a1.445,1.445,0,0,0,2.889,0V117.5H96.583v13.707a1.445,1.445,0,0,0,2.89,0V117.5H117.24a13.085,13.085,0,0,0,13.07-13.07V86.663h13.709a1.445,1.445,0,1,0,0-2.889"
        transform="translate(-12.809 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1444"
        data-name="Caminho 1444"
        d="M101.638,84.345a17.177,17.177,0,0,1,6.685,1.339,1.444,1.444,0,1,0,1.118-2.663,20.177,20.177,0,1,0,9.911,8.935,1.445,1.445,0,0,0-2.534,1.388,17.3,17.3,0,1,1-15.18-9"
        transform="translate(-35.312 -35.313)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1445"
        data-name="Caminho 1445"
        d="M59.023,134.549a1.444,1.444,0,0,0-1.445,1.445V152.97a5.4,5.4,0,0,0,5.4,5.4H79.963a1.445,1.445,0,0,0,0-2.889H62.974a2.51,2.51,0,0,1-2.507-2.507V135.994a1.444,1.444,0,0,0-1.445-1.445"
        transform="translate(-24.961 -58.33)"
        fill="#ffb500"
      />
    </g>
  </svg>
</template>

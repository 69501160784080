<template>
  <svg
    id="Grupo_633"
    data-name="Grupo 633"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="119.64"
    height="128.027"
    viewBox="0 0 119.64 128.027"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_550" data-name="Retângulo 550" width="119.64" height="128.027" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_632" data-name="Grupo 632" clip-path="url(#clip-path)">
      <path
        id="Caminho_1432"
        data-name="Caminho 1432"
        d="M51.682,150.97a1.276,1.276,0,0,0-1.277,1.277v3.115a1.277,1.277,0,0,0,2.553,0v-3.115a1.276,1.276,0,0,0-1.277-1.277"
        transform="translate(-25.172 -75.393)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1433"
        data-name="Caminho 1433"
        d="M51.682,180.154a1.276,1.276,0,0,0-1.277,1.277v12.314a1.277,1.277,0,1,0,2.553,0V181.431a1.276,1.276,0,0,0-1.277-1.277"
        transform="translate(-25.172 -89.967)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1434"
        data-name="Caminho 1434"
        d="M91.532,93.64v15.719a1.277,1.277,0,1,0,2.553,0V93.64a1.277,1.277,0,0,0-2.553,0"
        transform="translate(-45.71 -46.125)"
        fill="#960b38"
      />
      <path
        id="Caminho_1435"
        data-name="Caminho 1435"
        d="M108.529,200.407H85.638a2.066,2.066,0,0,1-2.064-2.064v-28.3a1.276,1.276,0,1,0-2.553,0v28.3a4.622,4.622,0,0,0,4.617,4.617h22.892a1.277,1.277,0,1,0,0-2.553"
        transform="translate(-40.461 -84.283)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1436"
        data-name="Caminho 1436"
        d="M162.985,213.289a10.626,10.626,0,1,0,10.626,10.626,10.639,10.639,0,0,0-10.626-10.626m0,18.7a8.073,8.073,0,1,1,8.073-8.073,8.082,8.082,0,0,1-8.073,8.073"
        transform="translate(-76.086 -106.515)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1437"
        data-name="Caminho 1437"
        d="M108.17,185.061a1.277,1.277,0,0,0,0,2.553h34.584a1.277,1.277,0,0,0,0-2.553Z"
        transform="translate(-53.381 -92.418)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1438"
        data-name="Caminho 1438"
        d="M207.161,166.384a10.626,10.626,0,1,0,10.626,10.626,10.639,10.639,0,0,0-10.626-10.626m0,18.7a8.073,8.073,0,1,1,8.073-8.073,8.082,8.082,0,0,1-8.073,8.073"
        transform="translate(-98.148 -83.091)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1439"
        data-name="Caminho 1439"
        d="M108.17,146.6a1.277,1.277,0,0,0,0,2.554h18.123a6.2,6.2,0,0,0,4.414-1.828L142.428,135.6a1.276,1.276,0,1,0-1.8-1.805L128.9,145.519a3.665,3.665,0,0,1-2.609,1.08Z"
        transform="translate(-53.381 -66.63)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1440"
        data-name="Caminho 1440"
        d="M192.892,108.4a10.626,10.626,0,1,0-10.627-10.625A10.638,10.638,0,0,0,192.892,108.4m0-18.7a8.074,8.074,0,1,1-8.074,8.074,8.082,8.082,0,0,1,8.074-8.074"
        transform="translate(-91.021 -43.523)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1441"
        data-name="Caminho 1441"
        d="M63.325,28.373a1.276,1.276,0,0,0-1.277,1.277v23.5a1.277,1.277,0,1,0,2.553,0V30.926H73.31a2.138,2.138,0,0,0,2.037-1.489,2.254,2.254,0,0,0-.749-2.52L40.818,1.058a5.019,5.019,0,0,0-6.165,0L.872,26.917a2.255,2.255,0,0,0-.749,2.521A2.137,2.137,0,0,0,2.16,30.926h8.709V81.754a1.277,1.277,0,1,0,2.553,0V29.65a1.276,1.276,0,0,0-1.277-1.277H3.17L36.2,3.085a2.491,2.491,0,0,1,3.061,0L72.3,28.373Z"
        transform="translate(0 0)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

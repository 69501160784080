<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 55.000000 54.000000"
    preserveAspectRatio="xMidYMid meet"
    class="financialIcon"
  >
    <g class="financialIcon__color" transform="translate(0.000000,54.000000) scale(0.100000,-0.100000)" stroke="none">
      <path
        :fill="color"
        d="M189 526 c-106 -38 -170 -115 -184 -222 -7 -49 9 -138 23 -132 4 2
27 24 51 50 l44 48 -33 0 c-30 0 -31 1 -25 32 18 85 88 155 173 173 18 4 31 9
29 11 -12 12 -100 -22 -135 -52 -43 -36 -66 -78 -77 -136 -6 -33 -5 -37 16
-40 23 -3 23 -3 -8 -38 -17 -19 -34 -32 -37 -29 -16 16 -17 100 -2 154 53 180
283 241 424 112 12 -11 22 -16 22 -10 0 17 -74 63 -126 79 -58 17 -107 17
-155 0z"
      />
      <path
        :fill="color"
        d="M271 400 c-1 -8 -11 -20 -23 -25 -13 -6 -24 -20 -26 -33 -4 -25 23
-62 44 -62 7 0 25 -11 41 -24 24 -21 26 -26 15 -50 -14 -31 -47 -41 -87 -26
-28 9 -29 9 -11 -5 11 -8 26 -15 33 -15 7 0 13 -8 14 -17 0 -13 2 -14 6 -4 3
7 19 26 36 41 43 38 33 72 -33 105 -53 27 -64 51 -34 76 12 10 22 10 47 1 31
-11 32 -11 11 6 -11 10 -23 24 -27 32 -5 13 -6 13 -6 0z"
      />
      <path
        :fill="color"
        d="M472 327 c-23 -23 -42 -46 -42 -50 0 -4 11 -7 25 -7 20 0 25 -5 25
-25 0 -67 -71 -149 -148 -171 -61 -18 -65 -31 -5 -15 88 24 163 111 163 189 0
26 -4 32 -22 32 -20 0 -20 2 12 35 18 19 37 32 42 29 16 -10 8 -137 -11 -179
-70 -150 -257 -198 -388 -98 -25 19 -43 29 -41 21 2 -7 28 -27 57 -45 177
-107 401 16 401 219 0 66 -8 108 -19 108 -4 0 -26 -19 -49 -43z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="112.22"
    height="113.943"
    viewBox="0 0 112.22 113.943"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_482" data-name="Retângulo 482" width="112.22" height="113.943" fill="#1f214a" />
      </clipPath>
    </defs>
    <g id="Grupo_534" data-name="Grupo 534" transform="translate(0 0)">
      <g id="Grupo_507" data-name="Grupo 507" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          id="Caminho_1102"
          data-name="Caminho 1102"
          d="M67.181,19A37.033,37.033,0,0,1,85.4,73.72c-11.653,18.139-37.315,22.5-54.2,9.018A37.653,37.653,0,0,1,17.477,48.9c.194-1.729-2.515-1.716-2.709,0-2.712,24.1,16.943,45.649,41.318,44.5,24.974-1.172,43.109-25.94,36.5-50.135A40.406,40.406,0,0,0,67.9,16.384c-1.626-.632-2.33,1.987-.72,2.612"
          transform="translate(18.26 20.485)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1103"
          data-name="Caminho 1103"
          d="M23.135,42.9c-1.19,11.009-.242,22.666,4.087,32.948,2.7,6.419,7.5,13.8,14.985,14.931,6.525.982,11.928-3.651,15.3-8.772,5.667-8.611,7.625-19.584,7.78-29.731.176-11.391-1.949-24.012-8.817-33.4-1.018-1.393-3.371-.043-2.339,1.368,5.349,7.309,7.6,16.629,8.257,25.54.723,9.779-.33,20.088-4.206,29.167-2.425,5.685-6.961,13.073-13.951,13.251S32.568,81.218,30.047,75.605c-4.538-10.1-5.38-21.808-4.2-32.7.187-1.732-2.524-1.718-2.709,0"
          transform="translate(28.506 23.016)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1104"
          data-name="Caminho 1104"
          d="M30.678,39.177c12.152.666,25.787-.711,36.389-7.175a1.362,1.362,0,0,0,.485-1.854A41.633,41.633,0,0,0,49.215,16.384c-1.626-.632-2.33,1.987-.72,2.612a38.3,38.3,0,0,1,16.719,12.52q.244-.925.485-1.854c-10.212,6.225-23.328,7.446-35.021,6.805-1.743-.095-1.736,2.615,0,2.709"
          transform="translate(36.946 20.485)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1105"
          data-name="Caminho 1105"
          d="M79.712,47.043a37.076,37.076,0,0,1-59.333,0c-.163.619-.323,1.235-.485,1.854,8.9-5.36,19.916-6.95,30.153-6.95S71.3,43.537,80.2,48.9a1.356,1.356,0,0,0,1.368-2.339c-9.3-5.6-20.817-7.32-31.519-7.32s-22.219,1.718-31.521,7.32a1.364,1.364,0,0,0-.485,1.854A40.31,40.31,0,0,0,50.047,64.589a40.309,40.309,0,0,0,32-16.177c1.057-1.389-1.3-2.739-2.339-1.368"
          transform="translate(22.438 49.354)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1106"
          data-name="Caminho 1106"
          d="M15.825,34.971h76.8a1.355,1.355,0,0,0,0-2.709h-76.8a1.356,1.356,0,0,0,0,2.709"
          transform="translate(18.259 40.579)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1107"
          data-name="Caminho 1107"
          d="M31.5,24.928V82.879a1.356,1.356,0,0,0,2.709,0V24.928a1.356,1.356,0,0,0-2.709,0"
          transform="translate(39.623 29.709)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1108"
          data-name="Caminho 1108"
          d="M84.811.452Q70.19,17.816,55.572,35.182q-2.086,2.479-4.172,4.956c.309-.368.7-.224-.316-.081-1.07.147-2.143.436-3.2.653q-13.049,2.662-26.1,5.322Q11.372,48.157.96,50.28l1.316,2.265Q16.9,35.18,31.517,17.814c1.391-1.653,2.775-3.308,4.172-4.956-.312.368-.707.224.314.081,1.07-.147,2.143-.436,3.2-.653L65.3,6.963,86.13,2.716c1.707-.348.984-2.96-.72-2.612Q62.979,4.68,40.549,9.253c-1.863.382-4.292.465-6.044,1.235-1.287.562-2.219,2.224-3.1,3.265q-8.589,10.2-17.178,20.4Q7.3,42.393.362,50.627A1.367,1.367,0,0,0,1.68,52.892q22.427-4.575,44.858-9.149c1.863-.382,4.292-.465,6.046-1.235,1.285-.562,2.217-2.224,3.1-3.265l17.178-20.4Q79.794,10.6,86.728,2.369c1.115-1.328-.792-3.254-1.917-1.917"
          transform="translate(0 -0.001)"
          fill="#960b38"
        />
        <path
          id="Caminho_1109"
          data-name="Caminho 1109"
          d="M57.532,10.006q3.014,5.077,6.033,10.151a24.092,24.092,0,0,1,2.226,3.953c1.454,4.082-4.565,9.128-7.211,11.7A59.665,59.665,0,0,1,37.146,49.181,45.836,45.836,0,0,1,24.99,52.125c-3.048.19-4.168-1.488-5.491-3.716q-3.465-5.832-6.932-11.666a1.356,1.356,0,0,0-2.339,1.368q3.563,5.995,7.123,11.989c1.093,1.84,2.156,3.506,4.279,4.315,3.755,1.431,8.95-.4,12.61-1.454A62.742,62.742,0,0,0,58,40.019,52.327,52.327,0,0,0,67.4,29.755a7.324,7.324,0,0,0,.2-8.1c-2.484-4.387-5.155-8.679-7.731-13.014a1.356,1.356,0,0,0-2.339,1.368"
          transform="translate(12.614 10.048)"
          fill="#960b38"
        />
        <path
          id="Caminho_1110"
          data-name="Caminho 1110"
          d="M11.912,34.512c.059.1.115.2.167.3.023.045.158.393.047.095a5.266,5.266,0,0,1,.2.641c.025.108.043.217.063.325-.061-.341,0,.011,0,.077a6.581,6.581,0,0,1-.009.761c-.018.336.063-.174,0,.016a2.65,2.65,0,0,0-.054.266,5.954,5.954,0,0,1-.187.621c-.115.334.135-.233-.036.077-.052.095-.1.194-.151.289s-.113.187-.174.278c-.041.061-.081.122-.126.181.1-.129.1-.133.007-.016-.14.151-.273.3-.427.443-.07.063-.14.124-.214.183.242-.194-.059.036-.095.059a6.38,6.38,0,0,1-.605.341c-.325.167.248-.068-.1.038-.142.043-.282.095-.427.131-.108.027-.217.047-.325.072-.35.081.282-.007-.077.011-.181.009-.361.023-.544.018-.111,0-.217-.014-.327-.02-.354-.018.278.072-.068-.009a6.893,6.893,0,0,1-.734-.2c-.056-.02-.373-.169-.079-.025-.1-.05-.2-.095-.291-.147a5.082,5.082,0,0,1-.553-.352c.041.032.2.174.014.007-.084-.075-.167-.147-.246-.224s-.158-.16-.233-.244c-.05-.056-.1-.115-.147-.174.1.124.1.124.007,0a6.613,6.613,0,0,1-.354-.6c-.023-.045-.158-.391-.05-.093-.038-.106-.077-.21-.111-.318-.054-.178-.088-.359-.131-.54-.084-.35.007.282-.011-.077-.007-.145-.02-.289-.02-.436,0-.108,0-.217.011-.327,0-.065.065-.415,0-.07a5.463,5.463,0,0,1,.147-.632c.029-.106.068-.205.1-.309.113-.334-.135.235.036-.075a6.474,6.474,0,0,1,.325-.569c.027-.043.251-.325.047-.079.072-.086.142-.169.219-.251a4.994,4.994,0,0,1,.492-.461c-.239.2.061-.034.1-.059.1-.063.2-.124.3-.181s.205-.106.307-.158c.278-.144-.174.043.1-.038.251-.077.5-.145.752-.2.35-.084-.282,0,.075-.011.147-.009.291-.02.438-.02.108,0,.217,0,.325.011q.108.007.217.02c-.181-.025-.194-.025-.038,0,.212.05.422.093.63.16.1.034.205.07.3.111-.318-.126.111.065.169.1.095.052.19.106.282.165.052.034.422.316.172.108a4.885,4.885,0,0,1,.562.54c.032.034.253.33.075.079.09.126.174.253.255.386a1.355,1.355,0,1,0,2.339-1.368,6.254,6.254,0,0,0-6.525-2.89A6.147,6.147,0,0,0,2.9,35.214a6.221,6.221,0,0,0,3.075,6.451,6.154,6.154,0,0,0,8.273-8.521,1.355,1.355,0,0,0-2.339,1.368"
          transform="translate(3.554 37.917)"
          fill="#960b38"
        />
        <path
          id="Caminho_1111"
          data-name="Caminho 1111"
          d="M31.187,13.14,12.808,26.646c-2.341,1.718-4.983,3.305-7.146,5.249-.874.786-.716,1.6-.716,2.685V53.106a1.355,1.355,0,0,0,2.709,0V33.1q-.335.583-.671,1.17l25.57-18.79c1.391-1.021.041-3.373-1.368-2.339"
          transform="translate(6.207 16.204)"
          fill="#960b38"
        />
      </g>
    </g>
  </svg>
</template>

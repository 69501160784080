<template>
  <svg
    id="Grupo_584"
    data-name="Grupo 584"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="109.038"
    viewBox="0 0 116 109.038"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_515" data-name="Retângulo 515" width="116" height="109.038" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_583" data-name="Grupo 583" clip-path="url(#clip-path)">
      <path
        id="Caminho_1356"
        data-name="Caminho 1356"
        d="M172.182,56.166,148.667,4.334a7.386,7.386,0,0,0-13.452,0L111.7,56.166a8.99,8.99,0,0,0,.6,8.505,7.239,7.239,0,0,0,6.127,3.475h10.716a11,11,0,0,0,10.621-8.187c.881-3.328,1.7-6.93,2.358-9.981l2.36,9.766a10.951,10.951,0,0,0,10.674,8.4h10.3a7.238,7.238,0,0,0,6.128-3.475,8.989,8.989,0,0,0,.6-8.505m-3.908,6.372a3.377,3.377,0,0,1-2.817,1.67h-10.3a7.024,7.024,0,0,1-6.846-5.388l-4.453-18.426a1.964,1.964,0,0,0-1.914-1.507h-.057A1.98,1.98,0,0,0,140,40.508c-.076.418-1.9,10.317-4.049,18.443a7.059,7.059,0,0,1-6.813,5.256H118.425a3.376,3.376,0,0,1-2.817-1.67,5.014,5.014,0,0,1-.321-4.745L138.8,5.961a3.447,3.447,0,0,1,6.278,0L168.6,57.792a5.017,5.017,0,0,1-.322,4.745"
        transform="translate(-56.977)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1357"
        data-name="Caminho 1357"
        d="M101.512,83.455a1.971,1.971,0,0,0-1.969,1.969v20.362a6.112,6.112,0,0,1-6.109,6.1H68.248a6.112,6.112,0,0,1-6.109-6.1V42.41A10.055,10.055,0,0,0,52.1,32.362H41.83A10.055,10.055,0,0,0,31.791,42.41V76.284a1.969,1.969,0,1,0,3.938,0V42.41a6.112,6.112,0,0,1,6.1-6.109H52.1a6.112,6.112,0,0,1,6.1,6.109v63.376a10.055,10.055,0,0,0,10.048,10.04H93.433a10.055,10.055,0,0,0,10.048-10.04V85.424a1.971,1.971,0,0,0-1.969-1.969"
        transform="translate(-16.333 -16.626)"
        fill="#960b38"
      />
      <path
        id="Caminho_1358"
        data-name="Caminho 1358"
        d="M19.818,135.7H15.035A15.052,15.052,0,0,0,0,150.731v12.985a15.052,15.052,0,0,0,15.035,15.035h4.783a15.053,15.053,0,0,0,15.036-15.035V150.731A15.053,15.053,0,0,0,19.818,135.7m11.1,28.02a11.109,11.109,0,0,1-11.1,11.1H15.035a11.109,11.109,0,0,1-11.1-11.1V150.731a11.109,11.109,0,0,1,11.1-11.1h4.783a11.109,11.109,0,0,1,11.1,11.1Z"
        transform="translate(0 -69.714)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1359"
        data-name="Caminho 1359"
        d="M33.758,151.416a1.971,1.971,0,0,0-1.969,1.969v4.306a1.969,1.969,0,0,0,3.939,0v-4.306a1.971,1.971,0,0,0-1.969-1.969"
        transform="translate(-16.332 -77.79)"
        fill="#ffb500"
      />
    </g>
  </svg>
</template>

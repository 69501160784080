<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 64.000000 52.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,52.000000) scale(0.100000,-0.100000)" :fill="color" stroke="none">
      <path
        d="M396 498 c-22 -20 -24 -28 -18 -58 5 -22 11 -30 16 -22 4 7 4 21 1
33 -4 11 -1 26 5 34 14 17 50 20 50 5 0 -20 31 -22 50 -5 31 28 65 8 65 -36 0
-27 4 -35 21 -37 34 -5 38 -49 6 -65 -14 -6 -27 -18 -30 -25 -2 -8 7 -6 30 6
51 29 49 102 -3 102 -15 0 -17 5 -13 28 5 23 1 32 -21 46 -26 17 -65 14 -65
-5 0 -14 -20 -11 -36 6 -20 20 -28 19 -58 -7z"
      />
      <path
        d="M86 469 c-24 -19 -27 -26 -21 -60 6 -35 4 -39 -13 -39 -11 0 -28 -7
-37 -16 -21 -22 -16 -84 8 -95 57 -28 55 -26 42 -61 -8 -21 39 -68 68 -68 12
0 31 10 44 22 l23 21 23 -21 c29 -27 41 -27 78 -1 25 18 29 26 27 62 -3 34 0
42 17 45 30 6 45 22 45 49 0 37 -18 63 -44 63 -28 0 -36 16 -29 55 11 55 -78
89 -110 43 l-15 -23 -24 23 c-30 28 -48 28 -82 1z m66 -9 c7 -11 23 -20 35
-20 13 0 28 9 35 20 15 24 31 25 64 4 20 -14 23 -22 19 -48 -7 -38 2 -53 35
-57 22 -4 25 -9 25 -44 0 -34 -3 -40 -23 -43 -29 -4 -46 -36 -32 -61 14 -27
14 -27 -21 -51 l-30 -22 -23 21 c-29 27 -53 27 -76 -1 -18 -22 -20 -22 -47 -6
-21 13 -28 25 -29 50 -1 55 -3 58 -29 58 -18 0 -28 7 -36 26 -14 37 -3 64 26
64 31 0 50 32 35 59 -15 29 -13 35 18 53 35 22 39 22 54 -2z"
      />
      <path
        d="M146 369 c-59 -47 -15 -149 59 -135 63 13 86 86 40 131 -30 30 -64
32 -99 4z m104 -31 c13 -25 13 -31 0 -55 -21 -38 -81 -45 -104 -11 -20 28 -20
48 0 76 23 34 83 27 104 -10z"
      />
      <path
        d="M440 420 c-40 -40 -16 -100 39 -100 33 0 61 27 61 58 0 28 -34 62
-60 62 -11 0 -29 -9 -40 -20z m82 -25 c9 -21 8 -28 -7 -45 -10 -11 -26 -20
-35 -20 -9 0 -25 9 -35 20 -27 30 -7 70 35 70 23 0 33 -6 42 -25z"
      />
      <path
        d="M412 254 c-18 -12 -22 -22 -17 -44 5 -24 2 -28 -20 -34 -22 -6 -26
-11 -23 -39 2 -22 10 -35 26 -42 14 -6 22 -18 22 -35 0 -46 37 -64 81 -37 17
9 23 9 26 0 14 -39 91 7 78 46 -5 16 -1 23 20 31 22 8 26 15 23 42 -2 23 -10
36 -25 43 -14 6 -23 20 -25 39 -2 23 -10 33 -31 41 -24 8 -31 6 -43 -9 -13
-18 -15 -18 -32 -3 -23 21 -32 21 -60 1z m78 -24 c7 0 18 7 25 16 18 26 56 1
53 -34 -2 -20 3 -29 25 -40 35 -17 36 -49 1 -62 -22 -9 -26 -15 -21 -39 5 -23
1 -31 -19 -42 -23 -12 -27 -11 -35 4 -12 21 -27 22 -50 1 -26 -23 -61 -2 -53
31 5 20 1 27 -19 34 -14 5 -28 15 -32 20 -11 18 5 51 25 51 22 0 31 19 19 42
-7 12 -3 21 13 32 19 14 24 14 38 1 9 -8 22 -15 30 -15z"
      />
      <path
        d="M455 175 c-14 -13 -25 -31 -25 -40 0 -21 36 -55 60 -55 24 0 60 34
60 55 0 18 -44 65 -60 65 -6 0 -22 -11 -35 -25z m63 -7 c34 -34 -6 -90 -48
-68 -35 19 -20 80 20 80 9 0 21 -5 28 -12z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

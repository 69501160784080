<template>
  <svg
    id="Grupo_464"
    data-name="Grupo 464"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="91.427"
    height="91.427"
    viewBox="0 0 91.427 91.427"
  >
    <g id="Grupo_463" data-name="Grupo 463">
      <path
        id="Caminho_1046"
        data-name="Caminho 1046"
        d="M1.152,56.927a1.151,1.151,0,0,0,.316-.044l7.069-2.02a8.871,8.871,0,0,1,5.266.114l2.49.83a11.28,11.28,0,0,0,7.092,0l2.262-.754a8.969,8.969,0,0,1,5.636,0l2.261.754a11.282,11.282,0,0,0,7.092,0l2.261-.753a8.966,8.966,0,0,1,5.636,0l2.261.754a11.283,11.283,0,0,0,7.092,0l2.261-.753a8.965,8.965,0,0,1,5.636,0l2.261.753a11.283,11.283,0,0,0,7.092,0l2.49-.83a8.874,8.874,0,0,1,5.266-.114l7.069,2.02a1.151,1.151,0,0,0,1.459-1.25L88.553,32.7V29.9A29.935,29.935,0,0,0,58.651,0H45.714a1.152,1.152,0,0,0,0,2.3H58.651a27.629,27.629,0,0,1,27.6,27.6v2.875a1.15,1.15,0,0,0,.009.143l2.659,21.271-5.394-1.541a11.172,11.172,0,0,0-6.627.144l-2.49.83a8.968,8.968,0,0,1-5.636,0l-2.261-.753a11.281,11.281,0,0,0-7.092,0l-2.261.753a8.968,8.968,0,0,1-5.636,0l-2.261-.754a11.285,11.285,0,0,0-7.092,0l-2.261.753a8.968,8.968,0,0,1-5.636,0l-2.261-.754a11.285,11.285,0,0,0-7.092,0l-2.262.754a8.962,8.962,0,0,1-5.636,0l-2.49-.83A11.167,11.167,0,0,0,7.9,52.648L2.627,54.156l3.09-16.478a4.6,4.6,0,0,1,4.519-3.751H25.589c9.326,0,18.973,3.871,18.973,10.348a1.151,1.151,0,0,0,1.294,1.143c9.959-1.245,11.99-8.612,12.4-11.491H78.776a1.152,1.152,0,0,0,0-2.3H59.517L71.073,16.216a1.151,1.151,0,1,0-1.842-1.382L57.611,30.326,51.119,10.849a1.152,1.152,0,1,0-2.185.729l7.122,21.366a11.272,11.272,0,0,1-1.3,4.5,10.841,10.841,0,0,1-7.994,5.5c-.619-4.087-4.076-7.122-8.694-9.016h7.641a1.152,1.152,0,1,0,0-2.3H10.236a6.9,6.9,0,0,0-6.783,5.63L.02,55.563a1.151,1.151,0,0,0,1.132,1.364"
        transform="translate(0 0.001)"
        fill="#960b38"
      />
      <path
        id="Caminho_1047"
        data-name="Caminho 1047"
        d="M89.649,239.41a77.758,77.758,0,0,1-14.379,7.329l-7.13-9.524h2.012c10.725,0,15.526-6.354,15.526-12.651a1.151,1.151,0,1,0-2.3,0c0,4.774-3.463,10.348-13.223,10.348H21.277c-9.76,0-13.223-5.575-13.223-10.348a1.152,1.152,0,0,0-2.3,0c0,6.3,4.8,12.651,15.526,12.651H23.3l-7.083,9.547A77.738,77.738,0,0,1,1.779,239.41a1.152,1.152,0,0,0-1.254,1.932,80.109,80.109,0,0,0,15.586,7.844,1.142,1.142,0,0,0,.191.073,85.667,85.667,0,0,0,58.887-.024,1.153,1.153,0,0,0,.2-.074A80.1,80.1,0,0,0,90.9,241.342a1.152,1.152,0,0,0-1.254-1.932M18.47,247.592l7.7-10.377H65.262l7.753,10.356a83.485,83.485,0,0,1-54.546.021"
        transform="translate(0 -163.038)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" :width="width" :height="height" :fill="color">
    <path
      d="M 16.5 5 C 10.16639 5 5 10.16639 5 16.5 L 5 31.5 C 5 37.832757 10.166209 43 16.5 43 L 31.5 43 C 37.832938 43 43 37.832938 43 31.5 L 43 16.5 C 43 10.166209 37.832757 5 31.5 5 L 16.5 5 z M 16.5 8 L 31.5 8 C 36.211243 8 40 11.787791 40 16.5 L 40 31.5 C 40 36.211062 36.211062 40 31.5 40 L 16.5 40 C 11.787791 40 8 36.211243 8 31.5 L 8 16.5 C 8 11.78761 11.78761 8 16.5 8 z M 34 12 C 32.895 12 32 12.895 32 14 C 32 15.105 32.895 16 34 16 C 35.105 16 36 15.105 36 14 C 36 12.895 35.105 12 34 12 z M 24 14 C 18.495178 14 14 18.495178 14 24 C 14 29.504822 18.495178 34 24 34 C 29.504822 34 34 29.504822 34 24 C 34 18.495178 29.504822 14 24 14 z M 24 17 C 27.883178 17 31 20.116822 31 24 C 31 27.883178 27.883178 31 24 31 C 20.116822 31 17 27.883178 17 24 C 17 20.116822 20.116822 17 24 17 z"
    />
  </svg>
  <!-- <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 :width="width" :height="height" viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
:fill="color" stroke="none">
<path d="M1 4533 c1 -538 2 -580 15 -499 72 462 403 860 844 1014 81 28 205
57 280 65 19 2 -229 5 -552 5 l-588 2 1 -587z"/>
<path d="M4008 5109 c148 -19 325 -79 457 -154 344 -196 578 -535 640 -925 12
-77 13 -27 14 503 l1 587 -587 -1 c-355 -1 -563 -5 -525 -10z"/>
<path d="M1105 4675 c-332 -73 -590 -333 -661 -665 -20 -97 -20 -2803 0 -2900
59 -279 257 -515 520 -620 155 -62 81 -60 1592 -60 949 0 1399 3 1439 11 101
19 224 69 315 130 159 106 277 261 341 447 l34 97 3 1405 c2 972 -1 1424 -8
1468 -27 159 -126 346 -242 459 -116 112 -272 196 -428 229 -94 20 -2814 19
-2905 -1z m2918 -420 c123 -32 201 -111 233 -236 28 -108 -6 -221 -91 -303
-65 -63 -138 -89 -235 -84 -86 5 -143 30 -203 87 -90 84 -121 226 -77 343 35
94 127 173 227 197 56 13 83 13 146 -4z m-1213 -439 c513 -110 895 -492 1006
-1006 26 -119 26 -381 0 -500 -56 -262 -167 -463 -360 -656 -187 -186 -387
-294 -646 -350 -119 -26 -381 -26 -500 0 -512 110 -896 494 -1006 1006 -26
119 -26 381 0 500 119 552 556 953 1116 1024 70 9 317 -3 390 -18z"/>
<path d="M2385 3396 c-309 -73 -540 -282 -637 -576 -26 -81 -31 -114 -35 -225
-4 -93 -1 -150 10 -200 66 -310 275 -544 577 -646 83 -28 112 -33 225 -36 258
-9 457 68 636 246 167 168 249 364 249 601 0 121 -16 203 -59 315 -43 112
-101 196 -201 295 -100 99 -191 154 -330 201 -86 29 -109 33 -235 35 -96 3
-159 -1 -200 -10z"/>
<path d="M1 588 l-1 -588 588 1 c529 1 579 2 502 14 -553 88 -989 522 -1074
1070 -13 83 -14 43 -15 -497z"/>
<path d="M5103 1088 c-75 -539 -522 -987 -1068 -1072 -83 -13 -43 -14 498 -15
l587 -1 0 585 c0 322 -1 585 -3 585 -1 0 -8 -37 -14 -82z"/>
</g>
</svg> -->
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 27.25 27.25">
    <path
      id="Caminho_897"
      data-name="Caminho 897"
      d="M5.876,0V-17.525H.434V0ZM3.152-19.916A3.178,3.178,0,0,0,6.3-23.1a3.153,3.153,0,0,0-3.152-3.152A3.153,3.153,0,0,0,0-23.1,3.178,3.178,0,0,0,3.152-19.916ZM26.244,0h.006V-9.627c0-4.711-1.013-8.338-6.52-8.338a5.718,5.718,0,0,0-5.149,2.83H14.5v-2.391H9.285V0h5.436V-8.678c0-2.285.434-4.494,3.263-4.494,2.788,0,2.829,2.607,2.829,4.641V0Z"
      transform="translate(0.5 26.75)"
      :fill="color"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

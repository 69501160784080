<template>
  <svg
    id="Layer_1"
    version="1.1"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 23 23"
    style="enable-background: new 0 0 23 23"
    xml:space="preserve"
  >
    <g class="st0">
      <path
        class="st1"
        d="M22.3,11.4c0,6.2-5,11.1-11.1,11.1C5,22.5,0,17.5,0,11.4S5,0.2,11.1,0.2C17.3,0.2,22.3,5.2,22.3,11.4zM15.5,14.8L15.5,14.8c0-0.4-0.2-0.7-0.4-1l-0.6-0.6c-0.3-0.3-0.6-0.4-1-0.4h-3c-0.4-0.2-1-1.4-1.4-1.4S8,11.3,7.6,11.1l-0.5-0.2c-0.2-0.1-0.3-0.3-0.3-0.5c0-0.2,0.2-0.4,0.4-0.5l1.4-0.5c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.5,0.2l0.4,0.4c0.1,0.1,0.1,0.1,0.2,0.1h0.3c0.3,0,0.4-0.3,0.3-0.5L9.8,8c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3l0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.1H11c0.1,0,0.2,0,0.3-0.1l0.4-0.4c0.1-0.1,0.1-0.4,0-0.5l-0.2-0.2c-0.1-0.1-0.1-0.4,0-0.5l0.5-0.5l0.2-0.2c0.3-0.3,0.3-0.7,0-1l-1.3-1.3c-0.4,0-0.7,0.1-1.1,0.1V3c0,0.5-0.6,0.9-1,0.6L7.6,3.1C5.5,4,3.9,5.7,2.9,7.7C3.4,8.4,4.1,9.4,4.5,10c0.2,0.3,0.5,0.7,0.8,0.9l0,0c0.4,0.4,0.9,0.7,1.4,1c0.6,0.3,1.5,0.8,2.2,1.2c0.5,0.3,0.7,0.7,0.7,1.3v1.4c0,0.4,0.2,0.7,0.4,1c0.7,0.7,1.1,1.7,1,2.3v1.2c0.7,0,1.3-0.1,1.9-0.2l0.8-2.1c0.1-0.2,0.1-0.5,0.2-0.8c0-0.2,0.1-0.3,0.3-0.5c0.1-0.1,0.3-0.3,0.5-0.5C15.2,15.9,15.5,15.4,15.5,14.8z M20,12.5c0-0.4,0.1-0.8,0.1-1.1c0-1.4-0.4-2.8-1-4l-0.6,0.3c-0.2,0.1-0.3,0.2-0.4,0.4l-0.9,1.3c-0.1,0.2-0.2,0.4-0.2,0.6s0.1,0.4,0.2,0.6l0.8,1.2c0.1,0.2,0.4,0.4,0.6,0.4L20,12.5z"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="96.777"
    height="115.914"
    viewBox="0 0 96.777 115.914"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_498" data-name="Retângulo 498" width="96.777" height="115.913" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_562" data-name="Grupo 562" transform="translate(0 0)">
      <g id="Grupo_561" data-name="Grupo 561" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          id="Caminho_1273"
          data-name="Caminho 1273"
          d="M30.266,51.546a1.551,1.551,0,0,0-1.514,1.514q-.26,3.849-.523,7.7c-.024.369-.05.739-.075,1.108a1.52,1.52,0,0,0,1.514,1.514,1.551,1.551,0,0,0,1.514-1.514q.26-3.849.523-7.7c.024-.369.05-.739.075-1.108a1.523,1.523,0,0,0-1.514-1.514"
          transform="translate(28.689 52.529)"
          fill="#960b38"
        />
        <path
          id="Caminho_1274"
          data-name="Caminho 1274"
          d="M21.008,53.06a1.514,1.514,0,0,0-3.029,0q.257,3.849.521,7.7c.026.369.05.739.077,1.108a1.514,1.514,0,0,0,3.029,0q-.26-3.849-.525-7.7c-.022-.369-.048-.739-.073-1.108"
          transform="translate(18.32 52.529)"
          fill="#960b38"
        />
        <path
          id="Caminho_1275"
          data-name="Caminho 1275"
          d="M52.94,48.4c-2.122-1.117-5-1.193-7.351-1.623a70.187,70.187,0,0,0-8.414-1.284,41.905,41.905,0,0,0-10.126.894c-2.282.418-4.563.838-6.849,1.149a8.454,8.454,0,0,0-7.039,7.214c-.569,4.339-.091,8.973-.091,13.344a1.515,1.515,0,0,0,3.029,0V56.89c0-2.659.872-5.175,3.636-6.148,1.837-.646,4.268-.733,6.193-1.086a63.031,63.031,0,0,1,7.41-1.139,38.308,38.308,0,0,1,9.32.787c1.956.357,3.925.822,5.9,1.08,3.3.43,5.73,2.041,5.857,5.641.139,4.012,0,8.05,0,12.064a1.515,1.515,0,0,0,3.029,0c0-4.016.111-8.048,0-12.064a8.669,8.669,0,0,0-4.5-7.63"
          transform="translate(13.129 46.308)"
          fill="#960b38"
        />
        <path
          id="Caminho_1276"
          data-name="Caminho 1276"
          d="M33.592,33.808A11.326,11.326,0,1,0,41,44.434a11.5,11.5,0,0,0-7.408-10.626M32.542,52.23a8.31,8.31,0,1,1,5.429-7.8,8.41,8.41,0,0,1-5.429,7.8"
          transform="translate(18.714 33.742)"
          fill="#960b38"
        />
        <path
          id="Caminho_1277"
          data-name="Caminho 1277"
          d="M7.261,45.436a1.514,1.514,0,0,0-3.029,0l.442,6.514c.02.313.042.626.063.939a1.514,1.514,0,0,0,3.029,0l-.442-6.516c-.02-.311-.042-.624-.063-.937"
          transform="translate(4.31 44.759)"
          fill="#960b38"
        />
        <path
          id="Caminho_1278"
          data-name="Caminho 1278"
          d="M31.015,40.444c-2.243-.41-4.482-.838-6.728-1.232a34.807,34.807,0,0,0-12.547.361c-1.415.258-2.833.571-4.256.779a9.219,9.219,0,0,0-4.45,1.468C.254,43.862,0,46.822,0,49.958v8.155a1.515,1.515,0,0,0,3.029,0V51.939c0-2.189-.491-5.165,1.02-6.97,1.106-1.322,2.53-1.4,4.1-1.67,1.244-.212,2.485-.454,3.727-.682a32.323,32.323,0,0,1,11.571-.491c2.259.394,4.511.826,6.766,1.238,1.906.349,2.722-2.568.806-2.92"
          transform="translate(0 39.535)"
          fill="#960b38"
        />
        <path
          id="Caminho_1279"
          data-name="Caminho 1279"
          d="M16.236,47.77a9.813,9.813,0,1,0-9.4-15.931,9.809,9.809,0,0,0,9.4,15.931M8.529,34.65a6.79,6.79,0,1,1,.515,7.731,6.837,6.837,0,0,1-.515-7.731"
          transform="translate(4.646 28.867)"
          fill="#960b38"
        />
        <path
          id="Caminho_1280"
          data-name="Caminho 1280"
          d="M43.966,43.922a1.551,1.551,0,0,0-1.514,1.514l-.442,6.514c-.02.313-.042.626-.063.939A1.523,1.523,0,0,0,43.462,54.4a1.553,1.553,0,0,0,1.514-1.514l.442-6.516.063-.937a1.523,1.523,0,0,0-1.514-1.514"
          transform="translate(42.745 44.76)"
          fill="#960b38"
        />
        <path
          id="Caminho_1281"
          data-name="Caminho 1281"
          d="M62.227,42.843c-2.211-2.471-5.69-2.5-8.743-3.055-4.048-.741-7.99-1.351-12.127-.8-2.762.369-5.5.965-8.24,1.466-1.914.351-1.1,3.271.806,2.922,2.158-.4,4.313-.8,6.471-1.185a29.515,29.515,0,0,1,10.693.222l4.2.769a11.924,11.924,0,0,1,3.493.753c2.657,1.405,2.326,4.26,2.326,6.786v7.4a1.515,1.515,0,0,0,3.029,0v-7c0-2.958.23-5.894-1.908-8.282"
          transform="translate(32.636 39.518)"
          fill="#960b38"
        />
        <path
          id="Caminho_1282"
          data-name="Caminho 1282"
          d="M40.093,47.341a9.812,9.812,0,1,0-6.439-9.213,9.961,9.961,0,0,0,6.439,9.213M41.1,31.76a6.8,6.8,0,1,1-4.422,6.368A6.88,6.88,0,0,1,41.1,31.76"
          transform="translate(34.296 28.878)"
          fill="#960b38"
        />
        <path
          id="Caminho_1283"
          data-name="Caminho 1283"
          d="M21.619,22.665l3.745,2.193a1.561,1.561,0,0,0,2.072-.543q2.953-3.861,5.906-7.721l.836-1.094a1.524,1.524,0,0,0-.543-2.072,1.55,1.55,0,0,0-2.072.543l-5.815,7.6q-1.3-.76-2.6-1.522a1.515,1.515,0,0,0-1.528,2.615"
          transform="translate(21.287 13.473)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1284"
          data-name="Caminho 1284"
          d="M24.727,8.05a1.54,1.54,0,0,0,1.514-1.514V1.516a1.514,1.514,0,0,0-3.029,0V6.535A1.532,1.532,0,0,0,24.727,8.05"
          transform="translate(23.656 0.001)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1285"
          data-name="Caminho 1285"
          d="M9.817,17.942h5.019a1.514,1.514,0,1,0,0-3.029H9.817a1.514,1.514,0,1,0,0,3.029"
          transform="translate(8.46 15.199)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1286"
          data-name="Caminho 1286"
          d="M16.215,10.062l.442.442a1.564,1.564,0,0,0,1.072.444A1.515,1.515,0,0,0,18.8,8.362Q17.246,6.808,15.69,5.254c-.145-.147-.295-.295-.442-.442a1.559,1.559,0,0,0-1.07-.444,1.515,1.515,0,0,0-1.07,2.586l3.107,3.107"
          transform="translate(12.916 4.452)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1287"
          data-name="Caminho 1287"
          d="M37.154,17.942h5.019a1.514,1.514,0,0,0,0-3.029H37.154a1.514,1.514,0,1,0,0,3.029"
          transform="translate(36.318 15.199)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1288"
          data-name="Caminho 1288"
          d="M34.584,10.5l3.107-3.107.442-.442a1.564,1.564,0,0,0,.444-1.072,1.514,1.514,0,0,0-2.584-1.07Q34.437,6.36,32.884,7.914l-.442.442a1.518,1.518,0,0,0,0,2.142,1.543,1.543,0,0,0,2.142,0"
          transform="translate(32.611 4.458)"
          fill="#ffb500"
        />
        <path
          id="Caminho_1289"
          data-name="Caminho 1289"
          d="M18.416,37.173c1.619,2.1,4.188,3.576,4.9,6.239.458,1.7.091,3.515.246,5.252.376,4.161,3.818,6.806,7.84,6.867,3.18.048,6.81.493,9.488-1.567a7.681,7.681,0,0,0,2.92-5.993c.075-2.082-.269-4.135.8-6.051.834-1.488,2.356-2.437,3.469-3.705a19.411,19.411,0,0,0,2.595-3.729,20.016,20.016,0,0,0,2.249-9.033A19.479,19.479,0,0,0,35.52,6.293,19.466,19.466,0,0,0,14.707,22.181a19.327,19.327,0,0,0,3.709,14.992M34.546,52.5c-3.014,0-6.891.43-7.842-3.364a13.479,13.479,0,0,1-.327-3.162H40.824c-.018.47-.032.945-.04,1.432C40.711,51.4,38.027,52.5,34.546,52.5M30.827,9.471c9.932-1.8,18.9,6.077,19.07,15.981a16.67,16.67,0,0,1-1.835,7.5c-1.183,2.251-2.883,3.723-4.608,5.51a8.739,8.739,0,0,0-2.3,4.48H26.191a7.519,7.519,0,0,0-.882-2.59c-1.036-1.817-2.8-2.96-4.121-4.541A16.184,16.184,0,0,1,17.909,29.3,16.428,16.428,0,0,1,30.827,9.471"
          transform="translate(14.711 6.321)"
          fill="#ffb500"
        />
      </g>
    </g>
  </svg>
</template>

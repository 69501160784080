<template>
  <svg
    id="Grupo_419"
    data-name="Grupo 419"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="141.907"
    height="141.907"
    viewBox="0 0 136.381 129.499"
  >
    <path
      id="Caminho_922"
      data-name="Caminho 922"
      d="M3.6,3.6H132.783V37.3H3.6ZM0,40.9H136.381V0H0Z"
      transform="translate(0 0)"
      fill="#fb9335"
    />
    <g id="Grupo_418" data-name="Grupo 418">
      <g id="Grupo_417" data-name="Grupo 417">
        <path
          id="Caminho_923"
          data-name="Caminho 923"
          d="M0,112.577H66.391v21a8.6,8.6,0,0,0-6.581,6.581H27.151a8.571,8.571,0,1,0,0,3.6H59.811a8.569,8.569,0,0,0,16.757,0h32.661a8.573,8.573,0,1,0,0-3.6H76.567a8.593,8.593,0,0,0-6.579-6.581v-21h66.393v-40.9H0Zm18.774,34.354a4.975,4.975,0,1,1,4.975-4.975,4.981,4.981,0,0,1-4.975,4.975m98.834-9.95a4.975,4.975,0,1,1-4.975,4.975,4.981,4.981,0,0,1,4.975-4.975m-44.443,4.976a4.975,4.975,0,1,1-4.975-4.976,4.98,4.98,0,0,1,4.975,4.976M3.6,75.278H132.784v33.7H3.6Z"
          transform="translate(0 -21.03)"
          fill="#960b38"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_924"
          data-name="Caminho 924"
          d="M30.545,30.62a2.877,2.877,0,1,0-2.877-2.877,2.877,2.877,0,0,0,2.877,2.877"
          transform="translate(-8.117 -7.295)"
          fill="#fb9335"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_925"
          data-name="Caminho 925"
          d="M53.837,30.62a2.877,2.877,0,1,0-2.877-2.877,2.877,2.877,0,0,0,2.877,2.877"
          transform="translate(-14.951 -7.295)"
          fill="#fb9335"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_926"
          data-name="Caminho 926"
          d="M77.128,30.62a2.877,2.877,0,1,0-2.877-2.877,2.877,2.877,0,0,0,2.877,2.877"
          transform="translate(-21.784 -7.295)"
          fill="#fb9335"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_927"
          data-name="Caminho 927"
          d="M30.545,96.546a2.877,2.877,0,1,0,2.877,2.877,2.877,2.877,0,0,0-2.877-2.877"
          transform="translate(-8.117 -28.325)"
          fill="#960b38"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_928"
          data-name="Caminho 928"
          d="M53.837,96.546a2.877,2.877,0,1,0,2.877,2.877,2.877,2.877,0,0,0-2.877-2.877"
          transform="translate(-14.951 -28.325)"
          fill="#960b38"
          fill-rule="evenodd"
        />
        <path
          id="Caminho_929"
          data-name="Caminho 929"
          d="M77.128,96.546a2.877,2.877,0,1,0,2.877,2.877,2.877,2.877,0,0,0-2.877-2.877"
          transform="translate(-21.784 -28.325)"
          fill="#960b38"
          fill-rule="evenodd"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <component :is="name" :color="color" :width="width" :height="height" />
</template>

<script>
import iconFace from './icon_face'
import iconInsta from './icon_insta'
import iconLinkedin from './icon_linkedin'
import iconMessenger from './icon_messenger'
import iconWhats from './icon_whats'
import iconSharing from './icon_sharing'
import iconLanguage from './icon_language'
import iconMenu from './icon_menu'
import iconMenuClose from './icon_menu_close'
import iconLogo from './icon_logo'
import iconAboutFirst from './Icon_about_first'
import iconAboutSecond from './Icon_about_second'
import iconAboutThird from './Icon_about_third'
import Industry from './Industry.vue'
import Financial from './Financial.vue'
import Services from './Services.vue'
import Health from './Health.vue'
import Retail from './Retail.vue'
import CultureCollaborators from './CultureCollaborators.vue'
import CultureCountries from './CultureCountries.vue'
import CultureFormed from './CultureFormed.vue'
import CultureTrainings from './CultureTrainings.vue'
import Agility from './Agility.vue'
import Business from './Business.vue'
import Corporate from './Corporate.vue'
import QA from './QA.vue'
import Development from './Development.vue'
import OnboardingIntegra from './OnboardingIntegra.vue'
import OnboardingLead from './OnboardingLead.vue'
import OnboardingWelcome from './OnboardingWelcome.vue'
import Benefits from './Benefits.vue'
import ChildcareAssistance from './ChildcareAssistance.vue'
import FlexibleSchedule from './FlexibleSchedule.vue'
import Gympass from './Gympass.vue'
import HomeOfficeAssistance from './HomeOfficeAssistance.vue'
import Massage from './Massage.vue'
import Medicines from './Medicines.vue'
import PsychologicalReception from './PsychologicalReception.vue'
import TrainingProgramData1 from './TrainingProgramData1.vue'
import TrainingProgramData2 from './TrainingProgramData2.vue'
import TrainingProgramData3 from './TrainingProgramData3.vue'
import TrainingProgramData4 from './TrainingProgramData4.vue'
import DocumentReuseVue from './DocumentReuse.vue'
import DecisionControl from './DecisionControl.vue'
import ReducingRisksCosts from './ReducingRisksCosts.vue'
import OperationalEfficiency from './OperationalEfficiency.vue'
import LegalGuarantees from './LegalGuarantees.vue'
import InnovativeSolutions from './InnovativeSolutions.vue'
import TechnologyHunting from './TechnologyHunting.vue'
import CorporateAndAdministrativeHunting from './CorporateAndAdministrativeHunting.vue'
import MarketingHunting from './MarketingHunting.vue'
import AgilityHunting from './AgilityHunting.vue'
import InfrastructureAndSupportHunting from './InfrastructureAndSupportHunting.vue'
import icon1 from './icon1.vue'
import icon2 from './icon2.vue'
import icon3 from './icon3.vue'
import icon4 from './icon4.vue'
import icon5 from './icon5.vue'
import icon6 from './icon6.vue'
import icon7 from './icon7.vue'
import Gear from './Gear.vue'
import Key from './Key.vue'
import House from './House.vue'
import Chip from './Chip.vue'
import Document from './Document.vue'
import Eye from './Eye.vue'
import Mental from './Mental.vue'
import System from './System.vue'
import DocumentPath from './DocumentPath.vue'
import MagGlass from './MagGlass.vue'
import Horse from './Horse.vue'

export default {
  components: {
    TechnologyHunting,
    CorporateAndAdministrativeHunting,
    MarketingHunting,
    AgilityHunting,
    InfrastructureAndSupportHunting,
    InnovativeSolutions,
    ReducingRisksCosts,
    OperationalEfficiency,
    LegalGuarantees,
    DecisionControl,
    DocumentReuseVue,
    Benefits,
    ChildcareAssistance,
    FlexibleSchedule,
    Gympass,
    HomeOfficeAssistance,
    Massage,
    Medicines,
    PsychologicalReception,
    OnboardingIntegra,
    OnboardingLead,
    OnboardingWelcome,
    Agility,
    Business,
    Corporate,
    Development,
    CultureCollaborators,
    CultureCountries,
    CultureFormed,
    CultureTrainings,
    Industry,
    Financial,
    Services,
    Health,
    Retail,
    iconInsta,
    iconFace,
    iconLinkedin,
    iconMessenger,
    iconWhats,
    iconSharing,
    iconLanguage,
    iconMenu,
    iconMenuClose,
    iconLogo,
    iconAboutFirst,
    iconAboutSecond,
    iconAboutThird,
    QA,
    TrainingProgramData1,
    TrainingProgramData2,
    TrainingProgramData3,
    TrainingProgramData4,
    icon1,
    icon2,
    icon3,
    icon4,
    icon5,
    icon6,
    icon7,
    Gear,
    Key,
    House,
    Chip,
    Document,
    Eye,
    Mental,
    System,
    DocumentPath,
    MagGlass,
    Horse,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '80px',
    },
    height: {
      type: String,
      default: '80px',
    },
    color: {
      type: String,
      default: '#A90045',
    },
  },
}
</script>

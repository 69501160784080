<template>
  <svg
    id="Grupo_423"
    data-name="Grupo 423"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="131.907"
    height="131.907"
    viewBox="0 0 128.742 135.494"
  >
    <g id="Grupo_420" data-name="Grupo 420">
      <path
        id="Caminho_930"
        data-name="Caminho 930"
        d="M39.356,49.505H33.511L24.867,61.048,16.224,49.505H10.377c-3.549,0-6.255.981-8.04,2.916C.536,54.373-.23,57.181.059,60.758l3.234,43.4h7.767l3.39,47.219H35.28l3.391-47.219h7.769l3.234-43.384c.294-3.579-.471-6.386-2.274-8.343-1.787-1.939-4.493-2.923-8.043-2.923m6.871,11-3,40.2H35.452l-3.39,47.218h-14.4L14.276,100.7H6.5l-3-40.208c-.209-2.585.253-4.512,1.371-5.724s2.954-1.8,5.5-1.8h4.117L24.867,66.815,35.241,52.961h4.115c2.544,0,4.4.609,5.5,1.809,1.121,1.217,1.583,3.141,1.371,5.731"
        transform="translate(0 -15.882)"
        fill="#fb9335"
      />
      <path
        id="Caminho_931"
        data-name="Caminho 931"
        d="M29.474,30.228A15.114,15.114,0,1,0,14.359,15.115,15.131,15.131,0,0,0,29.474,30.228m0-26.771A11.657,11.657,0,1,1,17.816,15.115,11.672,11.672,0,0,1,29.474,3.457"
        transform="translate(-4.607)"
        fill="#fb9335"
      />
      <path
        id="Caminho_932"
        data-name="Caminho 932"
        d="M151.173,52.424c-1.786-1.939-4.492-2.922-8.042-2.922h-5.845l-8.644,11.543-8.628-11.522-.839-.02c-7.553-.175-12.1,2.949-15.22,10.476L90.692,92.832a9.968,9.968,0,0,0-6.807,8.6H77.2v24.876h31.444V101.433h-6.682a11.057,11.057,0,0,0-1.449-4.343l12.822-13.879,4.888,68.125,20.831.037,3.39-47.221h7.769l3.234-43.384c.293-3.579-.472-6.387-2.275-8.344m-45.984,70.428H80.659V104.89h24.53Zm-17.822-21.42c.529-3.117,2.82-5.468,5.558-5.468s5.029,2.351,5.558,5.468ZM150,60.5l-3,40.2h-7.778l-3.389,47.215-14.4-.026L116.212,75,98.244,94.453a8.559,8.559,0,0,0-3.757-1.784l12.668-31.381c2.424-5.852,5.58-8.247,11.111-8.333l10.377,13.857,10.374-13.854h4.115c2.544,0,4.394.609,5.5,1.809,1.121,1.217,1.583,3.141,1.371,5.731"
        transform="translate(-24.767 -15.879)"
        fill="#960b38"
      />
      <path
        id="Caminho_933"
        data-name="Caminho 933"
        d="M145.8,30.228a15.114,15.114,0,1,0-15.115-15.113A15.13,15.13,0,0,0,145.8,30.228m0-26.771a11.657,11.657,0,1,1-11.658,11.658A11.671,11.671,0,0,1,145.8,3.457"
        transform="translate(-41.925)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

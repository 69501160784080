<template>
  <svg
    id="Grupo_430"
    data-name="Grupo 430"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="141.907"
    height="141.907"
    viewBox="0 0 129.767 142.047"
  >
    <g id="Grupo_429" data-name="Grupo 429">
      <path
        id="Caminho_939"
        data-name="Caminho 939"
        d="M64.884,142.047l-.658-.272c-23.853-9.864-40.532-24.952-50.988-46.128C4.81,78.579.488,57.275.026,30.519L0,29.029l1.471-.239C27.648,24.533,48.588,15.215,63.709,1.1L64.884,0l1.176,1.1C81.179,15.215,102.119,24.533,128.3,28.79l1.47.239-.025,1.49c-.462,26.756-4.784,48.06-13.211,65.127-10.457,21.176-27.135,36.264-50.988,46.128ZM3.5,31.943c1.289,56.232,20.268,89.111,61.385,106.376C106,121.053,124.98,88.174,126.269,31.943,100.754,27.579,80.117,18.417,64.884,4.692,49.65,18.417,29.013,27.578,3.5,31.943"
        fill="#960b38"
      />
      <rect
        id="Retângulo_432"
        data-name="Retângulo 432"
        width="3.443"
        height="63.459"
        transform="translate(63.162 75.917)"
        fill="#fb9335"
      />
      <rect
        id="Retângulo_433"
        data-name="Retângulo 433"
        width="3.443"
        height="24.086"
        transform="translate(63.162 45.375)"
        fill="#fb9335"
      />
      <path
        id="Caminho_940"
        data-name="Caminho 940"
        d="M91.568,59.158a9.081,9.081,0,1,1,9.081-9.081,9.091,9.091,0,0,1-9.081,9.081m0-14.718a5.637,5.637,0,1,0,5.638,5.637,5.644,5.644,0,0,0-5.638-5.637"
        transform="translate(-26.684 -13.262)"
        fill="#fb9335"
      />
      <path
        id="Caminho_941"
        data-name="Caminho 941"
        d="M68.53,161.733H65.087V120.9L52.734,107.357V90.4h3.443v15.623L68.53,119.568Z"
        transform="translate(-17.059 -29.244)"
        fill="#fb9335"
      />
      <path
        id="Caminho_942"
        data-name="Caminho 942"
        d="M50.937,82.485a9.08,9.08,0,1,1,9.081-9.08,9.091,9.091,0,0,1-9.081,9.08m0-14.717A5.637,5.637,0,1,0,56.574,73.4a5.644,5.644,0,0,0-5.637-5.637"
        transform="translate(-13.54 -20.808)"
        fill="#fb9335"
      />
      <path
        id="Caminho_943"
        data-name="Caminho 943"
        d="M119.181,161.735h-3.443V119.568l12.352-13.546V90.4h3.443v16.958L119.181,120.9Z"
        transform="translate(-37.44 -29.244)"
        fill="#fb9335"
      />
      <path
        id="Caminho_944"
        data-name="Caminho 944"
        d="M132.2,82.485a9.08,9.08,0,1,1,9.08-9.08,9.091,9.091,0,0,1-9.08,9.08m0-14.717a5.637,5.637,0,1,0,5.637,5.637,5.644,5.644,0,0,0-5.637-5.637"
        transform="translate(-39.828 -20.808)"
        fill="#fb9335"
      />
      <path
        id="Caminho_945"
        data-name="Caminho 945"
        d="M98.637,102.642a5.226,5.226,0,1,1-5.226-5.226,5.225,5.225,0,0,1,5.226,5.226"
        transform="translate(-28.527 -31.513)"
        fill="#fb9335"
        fill-rule="evenodd"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

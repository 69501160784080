<template>
  <svg
    id="Grupo_466"
    data-name="Grupo 466"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="95.897"
    height="100"
    viewBox="0 0 95.897 100"
  >
    <g id="Grupo_465" data-name="Grupo 465">
      <path
        id="Caminho_1048"
        data-name="Caminho 1048"
        d="M27.3,108.109a1.3,1.3,0,0,0,1.675-.764c1.342-3.589,3.44-8.639,5.47-13.522,2.039-4.9,4.146-9.976,5.5-13.61,2.188-5.854,7.128-8.676,12.892-7.363,3.646.83,5.977,5.567,6.6,9.618a1.293,1.293,0,0,0,.062.243c1.884,5.229,5.262,13.736,5.3,13.821a1.3,1.3,0,0,0,.577.657c.252.14,6.2,3.451,8.567,4.6a3.8,3.8,0,0,1,1.906,2.39,4.915,4.915,0,0,1-.36,3.791,4.73,4.73,0,0,1-6.315,1.587c-.919-.447-1.86-.886-2.77-1.309-1.793-.836-3.648-1.7-5.379-2.656-5.295-2.927-7.732-12.775-8.9-17.507-.271-1.094-.467-1.885-.644-2.4a1.3,1.3,0,0,0-2.459.855c.139.4.342,1.219.576,2.168a57.1,57.1,0,0,0,4.2,12.309l-3.024,8.447a1.3,1.3,0,1,0,2.451.878l2.311-6.454a13.163,13.163,0,0,0,4.233,3.981c1.809,1,3.705,1.883,5.539,2.737.9.419,1.83.853,2.729,1.29a8.226,8.226,0,0,0,3.6.857,6.957,6.957,0,0,0,6.1-3.47,7.491,7.491,0,0,0,.6-5.86,6.367,6.367,0,0,0-3.258-3.976c-1.956-.953-6.63-3.534-8.035-4.313-.7-1.781-3.421-8.675-5.053-13.194-.807-5.023-3.757-10.536-8.575-11.634-7.024-1.6-13.269,1.929-15.909,8.99-1.342,3.59-3.44,8.639-5.47,13.522-2.038,4.9-4.146,9.976-5.5,13.61a1.3,1.3,0,0,0,.764,1.675"
        transform="translate(-18.368 -48.566)"
        fill="#960b38"
      />
      <path
        id="Caminho_1049"
        data-name="Caminho 1049"
        d="M123.478,21.924a10.962,10.962,0,1,0-10.962-10.962,10.974,10.974,0,0,0,10.962,10.962m0-19.32a8.358,8.358,0,1,1-8.358,8.358A8.368,8.368,0,0,1,123.478,2.6"
        transform="translate(-78.141)"
        fill="#960b38"
      />
      <path
        id="Caminho_1050"
        data-name="Caminho 1050"
        d="M249,211a10.962,10.962,0,1,0-10.962-10.962A10.974,10.974,0,0,0,249,211m0-19.32a8.358,8.358,0,1,1-8.358,8.358A8.368,8.368,0,0,1,249,191.676"
        transform="translate(-165.316 -131.307)"
        fill="#960b38"
      />
      <path
        id="Caminho_1051"
        data-name="Caminho 1051"
        d="M94.873,233.536a6.531,6.531,0,0,0-5.939-3.206l-11.363.115c-.985-.748-5.2-3.85-6.347-4.694-.915-2.05-5.82-12.286-12.573-12.286H26.57v-1.877a1.3,1.3,0,0,0-1.3-1.3H1.3a1.3,1.3,0,0,0,0,2.6H23.967v31.849a1.3,1.3,0,0,0,2.6,0v-8.208H58.651a13.686,13.686,0,0,0,2.831-.3c3.551,2.743,7.59,5.809,9.653,7.189a9.89,9.89,0,0,0,5.685,1.4c.608,0,1.208-.023,1.789-.044.471-.018.939-.035,1.4-.04l8.956-.093a6.722,6.722,0,0,0,5.968-3.412,7.924,7.924,0,0,0-.056-7.692m-2.213,6.413a4.059,4.059,0,0,1-3.726,2.087l-8.956.094c-.48,0-.97.023-1.465.041-2.222.083-4.321.161-5.929-.916-1.969-1.318-6.026-4.395-9.73-7.256a1.3,1.3,0,0,0-.492-.381c-2.443-1.89-4.681-3.646-5.993-4.683a1.3,1.3,0,0,0-1.615,2.043c.043.034,1.593,1.258,3.761,2.949H26.57V216.069H58.651c4.663,0,9.03,7.96,10.318,11.027a1.3,1.3,0,0,0,.43.546c.055.04,5.494,4.04,6.6,4.878a2.587,2.587,0,0,0,1.614.534l11.35-.119h.063a3.926,3.926,0,0,1,3.608,1.928,5.313,5.313,0,0,1,.029,5.088"
        transform="translate(0 -146.04)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    id="Grupo_425"
    data-name="Grupo 425"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="133.555"
    height="138.293"
    viewBox="0 0 133.555 138.293"
  >
    <g id="Grupo_424" data-name="Grupo 424">
      <path
        id="Caminho_934"
        data-name="Caminho 934"
        d="M80.407,37.993A42.608,42.608,0,0,0,49.622,110.06l.182.189c5.382,5.614,9.175,9.577,10.076,15.078v13.4l9.97,13.263h21.12l9.968-13.263v-13.4c.9-5.5,4.695-9.465,10.079-15.079l.18-.189A42.608,42.608,0,0,0,80.407,37.993M89.344,148.74H71.473l-8.34-11.094V128.2H97.681v9.447Zm19.5-40.931-.181.188c-5.674,5.918-9.974,10.407-10.972,16.946H82.034v-7.673l19.475-21.358V80.876a8.141,8.141,0,1,0-3.254,0V94.65L82.034,112.44V92.278a4.882,4.882,0,1,0-3.253,0V112.44L62.559,94.65V80.875a8.138,8.138,0,1,0-3.254,0V95.911L78.78,117.269v7.673H63.123c-1-6.539-5.3-11.028-10.97-16.945l-.183-.189a39.357,39.357,0,1,1,56.875,0M99.883,77.785a4.885,4.885,0,1,1,4.885-4.885,4.892,4.892,0,0,1-4.885,4.885m-38.952,0A4.885,4.885,0,1,1,65.816,72.9a4.891,4.891,0,0,1-4.885,4.885"
        transform="translate(-13.63 -13.701)"
        fill="#fb9335"
        fill-rule="evenodd"
      />
      <rect
        id="Retângulo_423"
        data-name="Retângulo 423"
        width="3.254"
        height="17.291"
        transform="translate(65.15)"
        fill="#960b38"
      />
      <rect
        id="Retângulo_424"
        data-name="Retângulo 424"
        width="3.254"
        height="17.283"
        transform="translate(31.979 9.761) rotate(-30.001)"
        fill="#960b38"
      />
      <rect
        id="Retângulo_425"
        data-name="Retângulo 425"
        width="3.254"
        height="17.283"
        transform="translate(8.132 34.798) rotate(-60)"
        fill="#960b38"
      />
      <rect
        id="Retângulo_426"
        data-name="Retângulo 426"
        width="17.289"
        height="3.254"
        transform="translate(116.265 65.152)"
        fill="#960b38"
      />
      <rect
        id="Retângulo_427"
        data-name="Retângulo 427"
        width="17.289"
        height="3.254"
        transform="translate(0 65.152)"
        fill="#960b38"
      />
      <rect
        id="Retângulo_428"
        data-name="Retângulo 428"
        width="17.283"
        height="3.254"
        transform="translate(108.828 40.621) rotate(-30)"
        fill="#960b38"
      />
      <rect
        id="Retângulo_429"
        data-name="Retângulo 429"
        width="17.283"
        height="3.254"
        transform="translate(90.115 23.101) rotate(-60)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

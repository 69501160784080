<template>
  <svg
    id="Grupo_478"
    data-name="Grupo 478"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="64.528"
    height="43.442"
    viewBox="0 0 64.528 43.442"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_471" data-name="Retângulo 471" width="64.528" height="43.442" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_478-2" data-name="Grupo 478" clip-path="url(#clip-path)">
      <path
        id="Caminho_1067"
        data-name="Caminho 1067"
        d="M71.41,122.1a3.629,3.629,0,0,0,.119-.358c.584-2.189-1.12-4.587-3.235-4.481a3.678,3.678,0,0,0-1.015-4.093,3.057,3.057,0,0,0-4.048.132,5.211,5.211,0,0,0-.932,1.32,3.086,3.086,0,0,0-4.073-1.73c-1.548.683-2.291,2.764-3.065,4.149a6.94,6.94,0,0,0-1,2.3,3.644,3.644,0,0,0,1.487,3.537,2.835,2.835,0,0,0,2.69.278,3.512,3.512,0,0,0,1.352,2.512.828.828,0,0,0,.141.108.817.817,0,0,0,.151.083,3.125,3.125,0,0,0,2.527.236,3.622,3.622,0,0,0,1.368,2.475.83.83,0,0,0,.137.1,3.146,3.146,0,0,0,2.676.333,3.6,3.6,0,0,0,1.507,2.565c1.834,1.123,3.665.2,4.63-1.525.73-1.306,1.918-2.833,1.945-4.387A3.417,3.417,0,0,0,71.41,122.1M56.4,121.575c-1.732-1.2-.229-3.27.485-4.55.649-1.162,1.7-3.877,3.468-2.588,1.156.842.86,2.022.327,3.1l-.646,1.156c-.406.727-.838,1.446-1.223,2.185-.585.819-1.343,1.354-2.412.7m4.183,2.9c-1.171-.811-.866-2.021-.32-3.12.044-.072.088-.145.13-.22a27.726,27.726,0,0,0,1.936-3.465l1.358-2.432c.423-.757,1.037-1.493,2.021-1.241,1.337.342,1.577,2,1.038,3.068-.291.577-.606,1.144-.926,1.709-.177.265-.335.547-.494.832l-1.542,2.761c-.267.478-.589.972-.844,1.484-.615.772-1.38,1.221-2.356.624m4.182,2.9c-1.029-.712-.915-1.775-.479-2.813.31-.5.576-1.043.862-1.555.636-1.138,1.3-2.267,1.939-3.408.613-.752,1.366-1.175,2.275-.514,1.58,1.15.439,3.062-.409,4.456a22.243,22.243,0,0,0-1.419,2.5c-.114.2-.225.4-.329.606-.619.838-1.4,1.366-2.441.731m7.437.693c-.681,1.22-1.507,3.274-3.254,2.2-1.226-.85-.814-2.128-.247-3.227.1-.181.2-.363.3-.542l.929-1.663c.6-.954,1.409-1.762,2.545-.936,1.579,1.147.424,2.922-.269,4.163"
        transform="translate(-42.812 -88.97)"
        fill="#960b38"
      />
      <path
        id="Caminho_1068"
        data-name="Caminho 1068"
        d="M131.188,48.2c-4.855-3.406-9.748-6.76-14.621-10.14l-5.322-3.691a.764.764,0,0,0-.911.118,11.149,11.149,0,0,1-4.381,2.4,4.526,4.526,0,0,1-1.872.126c-.377-.065-1.316-.3-1.433-.705-.113-.386.625-1.164.841-1.424a23.053,23.053,0,0,1,2.092-2.137q2.039-1.9,4.11-3.764a4.183,4.183,0,0,1,2.853-1.437,30.213,30.213,0,0,1,5.005.044c1.134.178,1.84,1.04,2.7,1.712a8.234,8.234,0,0,0,2.261,1.328,6.846,6.846,0,0,0,5.348-.494c.878-.406.114-1.7-.759-1.3a5.4,5.4,0,0,1-6.122-.868c-1.312-1.083-2.337-1.914-4.121-1.926-1.016-.006-2.032,0-3.047,0a8.442,8.442,0,0,0-2.833.223c-1.589.567-2.885,2.15-4.088,3.264a43.765,43.765,0,0,0-4.645,4.519c-.664.829-1.561,2.123-.812,3.174a4.263,4.263,0,0,0,3.587,1.33,10.972,10.972,0,0,0,5.892-2.6l10.979,7.614,6.986,4.845c.564.391,1.149.763,1.7,1.176,1.234.933,1.344,3.46-.535,3.76-1.16.185-2.781-1.42-3.633-2.011l-1.052-.729c-.857-.65-1.776-1.231-2.658-1.842-.8-.553-1.55.75-.759,1.3l.559.388h0l.658.456,1.15.8a2.4,2.4,0,0,1,1.046,1.242,2.054,2.054,0,0,1-1.262,2.643c-.935.211-1.978-.718-2.752-1.3a23.331,23.331,0,0,0-2.2-1.533c-.8-.553-1.55.75-.759,1.3.643.446,1.393,1.066,2.194,1.629a2.065,2.065,0,0,1-.642,3.3c-1.023.367-2.033-.629-2.821-1.174l-.45-.311c-.35-.261-.722-.5-1.081-.749-.8-.553-1.55.75-.759,1.3.437.3.906.664,1.4,1.017a2.154,2.154,0,0,1-.447,3.32c-1.218.606-2.812-.991-3.752-1.644-.8-.553-1.549.75-.759,1.3.7.487,1.388,1.011,2.111,1.465a3.1,3.1,0,0,0,3.989-.469,3.666,3.666,0,0,0,.934-2.754,2.829,2.829,0,0,0,3.29-.6,3.648,3.648,0,0,0,1.035-2.853,2.888,2.888,0,0,0,3.189-.506,3.588,3.588,0,0,0,1.14-2.724,8.173,8.173,0,0,0,1.747,1.044c2.171.87,4.288-1.013,4.327-3.2a3.819,3.819,0,0,0-1.823-3.254"
        transform="translate(-80.015 -20.591)"
        fill="#960b38"
      />
      <path
        id="Caminho_1069"
        data-name="Caminho 1069"
        d="M78.17,27.378a6.7,6.7,0,0,0,5,.2,7.939,7.939,0,0,0,2.194-1.355c.816-.68,1.683-2.146,2.839-1.381.809.536,1.563-.766.759-1.3-1.909-1.263-3.157.341-4.532,1.5a5.346,5.346,0,0,1-5.864.882c-.9-.354-1.292,1.1-.4,1.45"
        transform="translate(-61.443 -18.258)"
        fill="#960b38"
      />
      <path
        id="Caminho_1070"
        data-name="Caminho 1070"
        d="M245.247,104.408a5.432,5.432,0,0,0-3.421,4.26c-.153.947,1.3,1.354,1.45.4a3.981,3.981,0,0,1,2.371-3.209c.9-.337.509-1.791-.4-1.45"
        transform="translate(-191.304 -82.559)"
        fill="#960b38"
      />
      <path
        id="Caminho_1071"
        data-name="Caminho 1071"
        d="M48.806,103.785A3.824,3.824,0,0,0,46,101.064c-.945-.188-1.35,1.261-.4,1.45a2.319,2.319,0,0,1,1.759,1.671.753.753,0,0,0,1.45-.4"
        transform="translate(-35.644 -79.941)"
        fill="#960b38"
      />
      <path
        id="Caminho_1072"
        data-name="Caminho 1072"
        d="M246.879,21.051,239.218,4.3,238.13,1.917a.762.762,0,0,0-1.181-.152l-6.081,4.824a.6.6,0,0,0-.139.851c.008.02.012.04.021.06l7.239,15.489,1.022,2.187a.764.764,0,0,0,1.029.27l6.569-3.366a.769.769,0,0,0,.27-1.029M233.071,8.929l-.743-1.591L237.2,3.475l7.3,15.956.758,1.657-5.241,2.685L233.071,8.929"
        transform="translate(-182.44 -1.232)"
        fill="#960b38"
      />
      <path
        id="Caminho_1073"
        data-name="Caminho 1073"
        d="M16.218,5.943c.009-.02.014-.04.021-.06a.6.6,0,0,0-.139-.851L10.019.208A.762.762,0,0,0,8.838.36L1.177,17.115.089,19.494a.766.766,0,0,0,.27,1.029l6.569,3.366a.761.761,0,0,0,1.029-.27L15.2,8.131l1.022-2.188M7.68,20.673l-.722,1.545L1.717,19.532,9.049,3.5l.723-1.58L14.64,5.781,7.68,20.673"
        transform="translate(0 0)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

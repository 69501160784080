<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 26 26">
    <path
      id="Caminho_901"
      data-name="Caminho 901"
      d="M21.094-6.25H19.531a.782.782,0,0,0-.781.781V0H3.125V-15.625h7.031a.782.782,0,0,0,.781-.781v-1.562a.782.782,0,0,0-.781-.781H2.344A2.345,2.345,0,0,0,0-16.406V.781A2.345,2.345,0,0,0,2.344,3.125H19.531A2.345,2.345,0,0,0,21.875.781v-6.25A.782.782,0,0,0,21.094-6.25Zm2.734-15.625h-6.25a1.174,1.174,0,0,0-.83,2l1.745,1.745-11.9,11.9a1.168,1.168,0,0,0-.345.83,1.169,1.169,0,0,0,.345.83L7.7-3.467a1.168,1.168,0,0,0,.83.345,1.169,1.169,0,0,0,.83-.345l11.9-11.9L23-13.623a1.174,1.174,0,0,0,2-.83V-20.7A1.172,1.172,0,0,0,23.828-21.875Z"
      transform="translate(0.5 22.375)"
      :fill="color"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <svg
    id="Grupo_428"
    data-name="Grupo 428"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="141.907"
    height="141.906"
    viewBox="0 0 141.907 141.906"
  >
    <g id="Grupo_427" data-name="Grupo 427">
      <path
        id="Caminho_935"
        data-name="Caminho 935"
        d="M107.591,100.3a51,51,0,0,1-6.777-.455l.492-3.654a47.166,47.166,0,0,0,6.285.422A46.463,46.463,0,1,0,61.127,50.151a47.3,47.3,0,0,0,.422,6.285l-3.654.493a51.034,51.034,0,0,1-.455-6.777,50.15,50.15,0,1,1,50.15,50.149"
        transform="translate(-15.833)"
        fill="#fb9335"
      />
      <path
        id="Caminho_936"
        data-name="Caminho 936"
        d="M151.207,77.488,124.129,61.855V38.726h3.687v21L153.05,74.295Z"
        transform="translate(-34.215 -10.674)"
        fill="#fb9335"
      />
      <path
        id="Caminho_937"
        data-name="Caminho 937"
        d="M52.479,148.216a20.034,20.034,0,1,1,20.033-20.034,20.056,20.056,0,0,1-20.033,20.034m0-36.38a16.347,16.347,0,1,0,16.346,16.346,16.364,16.364,0,0,0-16.346-16.346"
        transform="translate(-8.943 -29.81)"
        fill="#960b38"
      />
      <path
        id="Caminho_938"
        data-name="Caminho 938"
        d="M53.235,162.774h-19.4l-.168-1.658a7.6,7.6,0,0,0-4.873-6.2,7.765,7.765,0,0,0-7.9.934L19.6,156.9,5.88,143.173l1.049-1.29a7.768,7.768,0,0,0,.933-7.9,7.6,7.6,0,0,0-6.2-4.874L0,128.938v-19.4l1.657-.169a7.6,7.6,0,0,0,6.2-4.872,7.771,7.771,0,0,0-.934-7.9L5.88,95.306,19.6,81.585l1.29,1.049a7.762,7.762,0,0,0,7.9.933,7.6,7.6,0,0,0,4.874-6.2l.168-1.658h19.4l.168,1.658a7.6,7.6,0,0,0,4.874,6.2,7.763,7.763,0,0,0,7.9-.933l1.291-1.049L81.19,95.306,80.142,96.6a7.77,7.77,0,0,0-.933,7.9,7.6,7.6,0,0,0,6.2,4.872l1.657.169v19.4l-1.657.168a7.6,7.6,0,0,0-6.2,4.874,7.768,7.768,0,0,0,.932,7.9l1.049,1.29L67.468,156.9l-1.291-1.05a7.764,7.764,0,0,0-7.9-.934,7.6,7.6,0,0,0-4.873,6.2Zm-16.2-3.687H50.038a11.9,11.9,0,0,1,17.08-7.055l9.21-9.21a11.9,11.9,0,0,1,7.056-17.08V112.737a11.9,11.9,0,0,1-7.056-17.08l-9.21-9.209a11.9,11.9,0,0,1-17.08-7.056H37.032a11.542,11.542,0,0,1-6.825,7.58,11.615,11.615,0,0,1-10.255-.523l-9.209,9.208a11.613,11.613,0,0,1,.523,10.258,11.547,11.547,0,0,1-7.579,6.822v13.006a11.545,11.545,0,0,1,7.58,6.823,11.617,11.617,0,0,1-.524,10.256l9.209,9.21a11.61,11.61,0,0,1,10.256-.524,11.543,11.543,0,0,1,6.824,7.579"
        transform="translate(0 -20.868)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 30.063 30.06">
    <path
      id="Caminho_899"
      data-name="Caminho 899"
      d="M15.032-25.781c-8.2,0-14.563,6-14.563,14.1A13.776,13.776,0,0,0,5.043-1.259c.489.44.388.695.472,3.412A1.168,1.168,0,0,0,7.148,3.182c3.1-1.365,3.14-1.473,3.666-1.33C19.8,4.324,29.531-1.424,29.531-11.685,29.531-19.785,23.238-25.781,15.032-25.781Zm8.745,10.847L19.5-8.162a2.189,2.189,0,0,1-1.85,1.019,2.184,2.184,0,0,1-1.308-.437l-3.4-2.547a.875.875,0,0,0-.527-.175.879.879,0,0,0-.528.175L7.291-6.645a.691.691,0,0,1-1-.918l4.277-6.772a2.188,2.188,0,0,1,1.85-1.019,2.183,2.183,0,0,1,1.309.437l3.4,2.546a.874.874,0,0,0,.527.175.879.879,0,0,0,.528-.175l4.594-3.479A.689.689,0,0,1,23.777-14.934Z"
      transform="translate(0.031 26.281)"
      :fill="color"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

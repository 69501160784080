<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="95.068"
    height="113.943"
    viewBox="0 0 95.068 113.943"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_483" data-name="Retângulo 483" width="95.068" height="113.943" fill="#1f214a" />
      </clipPath>
    </defs>
    <g id="Grupo_536" data-name="Grupo 536" transform="translate(0 0)">
      <g id="Grupo_509" data-name="Grupo 509" transform="translate(0 0)" clip-path="url(#clip-path)">
        <path
          id="Caminho_1112"
          data-name="Caminho 1112"
          d="M23.11,9.059V1.36a1.359,1.359,0,0,0-2.717,0v7.7a1.359,1.359,0,0,0,2.717,0"
          transform="translate(25.783 -0.001)"
          fill="#960b38"
        />
        <path
          id="Caminho_1113"
          data-name="Caminho 1113"
          d="M9.059,20.393H1.36a1.359,1.359,0,0,0,0,2.717h7.7a1.359,1.359,0,0,0,0-2.717"
          transform="translate(0.001 25.781)"
          fill="#960b38"
        />
        <path
          id="Caminho_1114"
          data-name="Caminho 1114"
          d="M13.731,11.811,8.965,7.045l-.677-.677a1.359,1.359,0,0,0-1.922,1.92l4.766,4.766.677.677a1.359,1.359,0,0,0,1.922-1.92"
          transform="translate(7.557 7.554)"
          fill="#960b38"
        />
        <path
          id="Caminho_1115"
          data-name="Caminho 1115"
          d="M38.744,23.11h7.7a1.359,1.359,0,1,0,0-2.717h-7.7a1.359,1.359,0,1,0,0,2.717"
          transform="translate(47.265 25.781)"
          fill="#960b38"
        />
        <path
          id="Caminho_1116"
          data-name="Caminho 1116"
          d="M34.723,13.731,39.49,8.965c.226-.226.453-.451.677-.677a1.359,1.359,0,0,0-1.92-1.922L33.48,11.132c-.226.226-.453.453-.677.679a1.358,1.358,0,0,0,1.92,1.92"
          transform="translate(40.976 7.556)"
          fill="#960b38"
        />
        <path
          id="Caminho_1117"
          data-name="Caminho 1117"
          d="M14.156,32.139c5.036-2.987,8-9.259,9.524-14.722l-2,.811c6.888,5,18.162,9.963,25.629,3.263H45.389a23.343,23.343,0,0,1,4.4,7.556c.55,1.651,3.177.942,2.62-.722a26.86,26.86,0,0,0-5.1-8.756,1.382,1.382,0,0,0-1.922,0c-3.023,2.713-8.074,2.846-11.815,1.734a35.794,35.794,0,0,1-10.52-5.421,1.367,1.367,0,0,0-1.995.813c-1.331,4.769-3.87,10.488-8.276,13.1a1.36,1.36,0,0,0,1.372,2.346"
          transform="translate(15.339 19.818)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1118"
          data-name="Caminho 1118"
          d="M13.646,39.514c-2.636,1.734-2.577,4.823-1.232,7.332A24.823,24.823,0,0,1,13.8,49.094c.337.838.428,1.843.718,2.71a24.477,24.477,0,0,0,2.271,4.943c3.507,5.763,9.653,9.872,16.541,9.725s12.893-4.458,16.215-10.341a24.543,24.543,0,0,0,2.135-5.038A14.812,14.812,0,0,1,52.37,48.8c.423-.874,1.057-1.664,1.5-2.541.971-1.927.795-5.611-2.337-5.224L52.888,42.4c1.2-11.233.3-29.107-13.382-32.132-5.321-1.175-11.247-.233-16.316,1.626-2.7.992-4.148,2.545-5.785,4.8a48.36,48.36,0,0,0-3.919,5.441c-3.073,5.763-1.524,12.838-.362,18.9.328,1.714,2.948.989,2.62-.722-1.141-5.966-2.667-12.768.922-18.182a35.319,35.319,0,0,1,4.694-6.141c2.07-1.92,5.733-2.543,8.444-3.032,7.3-1.32,14.817.174,18.064,7.556,2.955,6.709,3.073,14.718,2.305,21.891a1.358,1.358,0,0,0,1.359,1.359c.537-.066-1.2,3.177-1.356,3.442a8.382,8.382,0,0,0-.91,2.448,21.52,21.52,0,0,1-3.2,6.915,16.255,16.255,0,0,1-13.418,7.191A16.4,16.4,0,0,1,19.712,56.27a23.725,23.725,0,0,1-3.186-7.357C16,46.95,12.5,43.513,15.018,41.86c1.454-.958.1-3.31-1.372-2.346"
          transform="translate(14.545 12.323)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1119"
          data-name="Caminho 1119"
          d="M55.229,37.384c5.738,7.083,17.329,2.953,21.923,11.557,1.823,3.412,1.44,8.095,1.644,11.878.136,2.507.1,5.006.1,7.515l1.359-1.356H5.171l1.359,1.356c0-2.509-.032-5.009.1-7.515.206-3.8-.17-8.448,1.644-11.878,4.551-8.616,16.194-4.486,21.923-11.557H28.278l13.554,14a1.371,1.371,0,0,0,1.92,0l13.4-14c1.209-1.263-.711-3.188-1.922-1.92l-13.4,14h1.92l-13.552-14a1.365,1.365,0,0,0-1.922,0c-2.993,3.693-7.945,4.028-12.311,5-4.465,1-8.353,3.512-10.37,7.767-1.671,3.521-1.42,7.76-1.626,11.575-.154,2.846-.159,5.674-.159,8.525A1.379,1.379,0,0,0,5.171,69.7H80.259a1.379,1.379,0,0,0,1.359-1.361c0-2.762,0-5.507-.145-8.265-.211-3.886.061-8.251-1.639-11.835a14.371,14.371,0,0,0-10.155-7.717c-4.418-.989-9.5-1.316-12.526-5.054-1.1-1.361-3.014.573-1.922,1.92"
          transform="translate(4.819 44.249)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1120"
          data-name="Caminho 1120"
          d="M13.5,56.485q.4-4.7.792-9.4.054-.679.113-1.354a1.37,1.37,0,0,0-1.359-1.359,1.387,1.387,0,0,0-1.359,1.359q-.4,4.7-.792,9.4-.054.679-.113,1.354a1.37,1.37,0,0,0,1.359,1.359A1.387,1.387,0,0,0,13.5,56.485"
          transform="translate(13.63 56.099)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1121"
          data-name="Caminho 1121"
          d="M33.229,56.485q-.4-4.7-.793-9.4c-.038-.453-.075-.9-.113-1.354a1.393,1.393,0,0,0-1.359-1.359,1.368,1.368,0,0,0-1.359,1.359q.394,4.7.792,9.4.054.679.113,1.354a1.393,1.393,0,0,0,1.359,1.359,1.368,1.368,0,0,0,1.359-1.359"
          transform="translate(37.426 56.099)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1122"
          data-name="Caminho 1122"
          d="M20.427,42.544v17.91a1.359,1.359,0,0,0,2.717,0V42.544a1.359,1.359,0,0,0-2.717,0"
          transform="translate(25.826 52.129)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1123"
          data-name="Caminho 1123"
          d="M19.182,33.355q-1.644,3.145-3.29,6.288c-.154.3-.31.6-.466.894a1.358,1.358,0,1,0,2.346,1.37l3.288-6.286q.234-.448.469-.894a1.359,1.359,0,1,0-2.346-1.372"
          transform="translate(19.288 41.338)"
          fill="#1f214a"
        />
        <path
          id="Caminho_1124"
          data-name="Caminho 1124"
          d="M24.125,34.735l3.288,6.288.469.894a1.369,1.369,0,0,0,1.859.487,1.388,1.388,0,0,0,.487-1.859q-1.644-3.142-3.288-6.288c-.156-.3-.312-.6-.469-.894a1.37,1.37,0,0,0-1.859-.487,1.391,1.391,0,0,0-.487,1.859"
          transform="translate(30.282 41.33)"
          fill="#1f214a"
        />
      </g>
    </g>
  </svg>
</template>

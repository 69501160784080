<template>
  <svg
    id="Grupo_535"
    data-name="Grupo 535"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="111.18"
    height="113.943"
    viewBox="0 0 111.18 113.943"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_453" data-name="Retângulo 453" width="111.18" height="113.943" fill="#1f214a" />
      </clipPath>
    </defs>
    <g id="Grupo_437" data-name="Grupo 437" clip-path="url(#clip-path)">
      <path
        id="Caminho_975"
        data-name="Caminho 975"
        d="M229.168,34.864h.036a10.6,10.6,0,0,0,.036-21.2H229.2a10.6,10.6,0,0,0-.035,21.2m-5.275-15.948a7.485,7.485,0,0,1,5.31-2.19h.026a7.538,7.538,0,1,1-5.336,2.189"
        transform="translate(-140.431 -8.777)"
        fill="#1f214a"
      />
      <path
        id="Caminho_976"
        data-name="Caminho 976"
        d="M253.188,90.543a49.708,49.708,0,0,0-13.6-1.656c-.54,0-2.657,0-3.255.017a1.532,1.532,0,0,0,.068,3.063c.536-.012,2.575-.017,3.178-.016a46.637,46.637,0,0,1,12.759,1.534,8.744,8.744,0,0,1,6.63,8.547l-.489,17a10.329,10.329,0,0,1-1.7,5.157,1.532,1.532,0,0,0,2.569,1.669,13.41,13.41,0,0,0,2.187-6.715l.489-17a11.789,11.789,0,0,0-8.837-11.6"
        transform="translate(-150.856 -57.101)"
        fill="#1f214a"
      />
      <path
        id="Caminho_977"
        data-name="Caminho 977"
        d="M236.49,128.471a1.529,1.529,0,0,0-1.549,1.515l-.6,54.338v1.215a1.964,1.964,0,0,1-1.962,1.962h-3.717a1.964,1.964,0,0,1-1.962-1.962v-1.261l.123-25.428a1.532,1.532,0,0,0-1.524-1.539h-.008a1.532,1.532,0,0,0-1.532,1.525l-.121,24.994c0,.007,0,.013,0,.02v1.689a1.964,1.964,0,0,1-1.962,1.962h-3.72a1.964,1.964,0,0,1-1.961-1.962l-.063-23.6a1.532,1.532,0,0,0-1.532-1.527h0a1.532,1.532,0,0,0-1.528,1.536l.063,22.313v1.274a5.031,5.031,0,0,0,5.025,5.026h3.72a5,5,0,0,0,3.494-1.421,5,5,0,0,0,3.494,1.421h3.717a5.031,5.031,0,0,0,5.025-5.026v-1.2l.6-54.321a1.532,1.532,0,0,0-1.515-1.549"
        transform="translate(-136.746 -82.529)"
        fill="#1f214a"
      />
      <path
        id="Caminho_978"
        data-name="Caminho 978"
        d="M43.616,34.863h.037a10.643,10.643,0,1,0-.037,0m-.025-18.137h.026A7.537,7.537,0,0,1,43.642,31.8h-.026a7.537,7.537,0,0,1-.025-15.074"
        transform="translate(-21.209 -8.777)"
        fill="#1f214a"
      />
      <path
        id="Caminho_979"
        data-name="Caminho 979"
        d="M25.638,91.967a1.532,1.532,0,0,0,.068-3.063c-.6-.014-2.714-.018-3.255-.017a49.712,49.712,0,0,0-13.6,1.656A11.785,11.785,0,0,0,.01,102.121L.5,119.163a13.386,13.386,0,0,0,2.186,6.692,1.532,1.532,0,0,0,2.569-1.669,10.313,10.313,0,0,1-1.694-5.134L3.072,102.01A8.739,8.739,0,0,1,9.7,93.486,46.637,46.637,0,0,1,22.46,91.951c.6,0,2.642,0,3.178.016"
        transform="translate(0 -57.101)"
        fill="#1f214a"
      />
      <path
        id="Caminho_980"
        data-name="Caminho 980"
        d="M51.354,160.415a1.535,1.535,0,0,0-1.536,1.527l-.063,22.323v1.275a1.964,1.964,0,0,1-1.962,1.962h-3.72a1.964,1.964,0,0,1-1.962-1.962v-1.69c0-.007,0-.013,0-.02l-.121-24.994a1.532,1.532,0,0,0-1.532-1.524H40.45a1.532,1.532,0,0,0-1.524,1.539l.123,25.428v1.261a1.964,1.964,0,0,1-1.961,1.962H33.369a1.964,1.964,0,0,1-1.962-1.962v-1.233l-.6-54.321a1.532,1.532,0,1,0-3.063.034l.6,54.3v1.216a5.031,5.031,0,0,0,5.025,5.025h3.718a5,5,0,0,0,3.493-1.421,5.005,5.005,0,0,0,3.494,1.421h3.72a5.031,5.031,0,0,0,5.025-5.025l.063-23.588a1.532,1.532,0,0,0-1.527-1.536"
        transform="translate(-17.823 -82.529)"
        fill="#1f214a"
      />
      <path
        id="Caminho_981"
        data-name="Caminho 981"
        d="M133.283,24.75h.043A12.375,12.375,0,0,0,133.368,0h-.044a12.375,12.375,0,0,0-.041,24.75M126.764,5.768a9.248,9.248,0,0,1,6.56-2.7h.033a9.311,9.311,0,0,1-.031,18.622h-.033a9.311,9.311,0,0,1-6.529-15.918"
        transform="translate(-77.699)"
        fill="#960b38"
      />
      <path
        id="Caminho_982"
        data-name="Caminho 982"
        d="M133.842,121.621a14.251,14.251,0,0,0,1.091-4.863l.82-18.85c.25-5.741-3.726-10.956-9.668-12.682A54.033,54.033,0,0,0,111.341,83.4a54.37,54.37,0,0,0-14.77,1.748c-5.947,1.692-9.96,6.878-9.758,12.612l.665,18.862a14.186,14.186,0,0,0,.83,4.291,11.264,11.264,0,0,0,9.213,7.312l.093,32.482v.993a5.826,5.826,0,0,0,5.819,5.819h3.284a5.8,5.8,0,0,0,4.287-1.9,5.8,5.8,0,0,0,4.287,1.9h3.281a5.826,5.826,0,0,0,5.819-5.819v-.912l.357-32.414a11.089,11.089,0,0,0,9.093-6.753M121.328,161.7a2.759,2.759,0,0,1-2.756,2.755h-3.281a2.758,2.758,0,0,1-2.755-2.755v-.99l.136-28.145v-.008a1.532,1.532,0,0,0-1.524-1.539h-.008a1.532,1.532,0,0,0-1.532,1.525l-.136,28.163v.994a2.758,2.758,0,0,1-2.755,2.755h-3.284a2.759,2.759,0,0,1-2.756-2.755v-1l-.172-60.168a1.531,1.531,0,0,0-1.532-1.527h0a1.531,1.531,0,0,0-1.528,1.536l.07,24.587a8.194,8.194,0,0,1-6.32-5.251,11.117,11.117,0,0,1-.652-3.365l-.665-18.862c-.152-4.322,2.946-8.252,7.534-9.557a51,51,0,0,1,13.924-1.631,51.021,51.021,0,0,1,13.9,1.706c4.582,1.331,7.651,5.281,7.462,9.607l-.821,18.85a11.175,11.175,0,0,1-.859,3.819,8.029,8.029,0,0,1-6.23,4.843l.272-24.662a1.532,1.532,0,0,0-3.063-.034l-.663,60.18Z"
        transform="translate(-55.764 -53.575)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

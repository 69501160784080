<template>
  <svg
    id="Grupo_440"
    data-name="Grupo 440"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="115"
    height="112.534"
    viewBox="0 0 115 112.534"
  >
    <g id="Grupo_439" data-name="Grupo 439">
      <path
        id="Caminho_983"
        data-name="Caminho 983"
        d="M147.259,158.278h-.041a11.731,11.731,0,0,0-.039,23.462h.041a11.731,11.731,0,0,0,.039-23.462m6.171,17.984a8.724,8.724,0,0,1-6.241,2.56,8.813,8.813,0,0,1,.03-17.627h.031a8.813,8.813,0,0,1,6.18,15.066"
        transform="translate(-89.35 -104.379)"
        fill="#960b38"
      />
      <path
        id="Caminho_984"
        data-name="Caminho 984"
        d="M134.45,241.643c-4.113-1.486-8.8-2.159-15.208-2.181s-11.117.617-15.237,2.075c-6.289,2.225-10.882,9.264-10.694,16.381l-.1,7.859a4.574,4.574,0,0,0,4.568,4.622l42.736.05h.006a4.575,4.575,0,0,0,4.574-4.6l-.047-7.739c.235-7.122-4.317-14.2-10.6-16.468m7.246,25.4a1.645,1.645,0,0,1-1.173.487h0l-8.276-.01.108-11.06a1.458,1.458,0,0,0-1.445-1.473h-.015a1.459,1.459,0,0,0-1.458,1.445l-.108,11.085-20.453-.024-.028-11.148a1.459,1.459,0,0,0-1.459-1.455h0a1.459,1.459,0,0,0-1.455,1.462l.028,11.137-8.172-.01a1.657,1.657,0,0,1-1.655-1.671l.1-7.884c0-.02,0-.04,0-.06-.167-5.916,3.595-11.754,8.751-13.579,3.723-1.317,8.031-1.908,13.936-1.908h.317c6.055.021,10.443.641,14.226,2.008,5.152,1.862,8.882,7.732,8.674,13.653,0,.02,0,.04,0,.06l.047,7.768a1.645,1.645,0,0,1-.483,1.178"
        transform="translate(-61.47 -157.916)"
        fill="#960b38"
      />
      <path
        id="Caminho_985"
        data-name="Caminho 985"
        d="M99.4,20.639l10.7,8.444L106.4,42.2a2.995,2.995,0,0,0,4.552,3.307l11.338-7.568,11.338,7.568a3,3,0,0,0,4.552-3.307l-3.694-13.122,10.7-8.444a3,3,0,0,0-1.739-5.351l-13.621-.541L125.106,1.96a3,3,0,0,0-5.626,0l-4.725,12.787-13.621.541A3,3,0,0,0,99.4,20.639m1.78-2.38c.017-.054.036-.055.074-.056l14.594-.58a1.458,1.458,0,0,0,1.31-.952l5.062-13.7c.013-.034.02-.053.077-.053s.064.019.076.053l5.062,13.7a1.458,1.458,0,0,0,1.31.952l14.594.58c.038,0,.057,0,.074.056s0,.067-.027.089L131.917,27.4a1.458,1.458,0,0,0-.5,1.54L135.375,43c.01.036.015.055-.031.088s-.061.023-.093,0L123.1,34.977a1.46,1.46,0,0,0-1.62,0l-12.148,8.109c-.03.02-.047.031-.093,0s-.041-.051-.031-.088l3.958-14.059a1.458,1.458,0,0,0-.5-1.54L101.2,18.349c-.029-.022-.045-.035-.027-.089"
        transform="translate(-64.793)"
        fill="#960b38"
      />
      <path
        id="Caminho_986"
        data-name="Caminho 986"
        d="M264.022,102.954,255,97.993l1.12-10.239a2.648,2.648,0,0,0-4.445-2.218l-7.508,7.052-9.392-4.229a2.648,2.648,0,0,0-3.482,3.542l4.387,9.32-6.925,7.626a2.648,2.648,0,0,0,2.292,4.407l10.219-1.292,5.113,8.942a2.62,2.62,0,0,0,2.286,1.335,2.705,2.705,0,0,0,.448-.037,2.625,2.625,0,0,0,2.165-2.115l1.929-10.118,10.084-2.1a2.648,2.648,0,0,0,.736-4.913m-12.368,4.354a1.459,1.459,0,0,0-1.136,1.155l-1.974,10.357-5.234-9.153a1.458,1.458,0,0,0-1.449-.723L231.4,110.267l7.088-7.806a1.458,1.458,0,0,0,.24-1.6l-4.49-9.54,9.614,4.329a1.458,1.458,0,0,0,1.6-.267l7.686-7.219-1.146,10.482a1.459,1.459,0,0,0,.748,1.437l9.24,5.079Z"
        transform="translate(-150.396 -55.931)"
        fill="#1f214a"
      />
      <path
        id="Caminho_987"
        data-name="Caminho 987"
        d="M37.035,111.848a2.625,2.625,0,0,0-.389-3l-6.924-7.626,4.387-9.32a2.648,2.648,0,0,0-3.483-3.542l-9.392,4.229-7.508-7.052a2.648,2.648,0,0,0-4.445,2.218L10.4,97.993l-9.027,4.962a2.648,2.648,0,0,0,.735,4.912l10.084,2.1,1.929,10.118a2.625,2.625,0,0,0,2.165,2.116,2.708,2.708,0,0,0,.448.037,2.621,2.621,0,0,0,2.287-1.335l5.112-8.942,10.219,1.292a2.626,2.626,0,0,0,2.681-1.4m-13.5-2.9a1.459,1.459,0,0,0-1.449.723l-5.234,9.153-1.974-10.358a1.459,1.459,0,0,0-1.136-1.155L3.419,105.159l9.24-5.079a1.458,1.458,0,0,0,.748-1.437L12.261,88.162l7.685,7.219a1.458,1.458,0,0,0,1.6.267l9.614-4.329-4.49,9.54a1.458,1.458,0,0,0,.24,1.6L34,110.266Z"
        transform="translate(0 -55.931)"
        fill="#1f214a"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    id="Grupo_596"
    data-name="Grupo 596"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="104.495"
    height="89.502"
    viewBox="0 0 104.495 89.502"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_521" data-name="Retângulo 521" width="104.495" height="89.502" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_595" data-name="Grupo 595" clip-path="url(#clip-path)">
      <path
        id="Caminho_1385"
        data-name="Caminho 1385"
        d="M104.224,80.552l-2.982-10.54A7.066,7.066,0,0,0,94.476,64.9H11.033a3.159,3.159,0,0,1-3.156-3.156V6.78a3.163,3.163,0,0,1,3.156-3.164H93.454A3.167,3.167,0,0,1,96.617,6.78V59.525a1.808,1.808,0,1,0,3.616,0V6.78A6.787,6.787,0,0,0,93.454,0H11.033A6.784,6.784,0,0,0,4.261,6.78V61.742a6.78,6.78,0,0,0,6.772,6.772H94.476A3.434,3.434,0,0,1,97.763,71l2.981,10.537a3.418,3.418,0,0,1-3.286,4.349H7.037a3.418,3.418,0,0,1-3.287-4.349l2.38-8.406a1.808,1.808,0,0,0-3.48-.985L.271,80.551A7.035,7.035,0,0,0,7.037,89.5H97.458a7.035,7.035,0,0,0,6.766-8.95"
        transform="translate(0 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1386"
        data-name="Caminho 1386"
        d="M96.79,28.817A10.529,10.529,0,0,0,86.274,39.334v3.828h-1.1a4.707,4.707,0,0,0-4.7,4.7V59.119A12.5,12.5,0,0,0,92.956,71.6h7.677a12.5,12.5,0,0,0,12.481-12.481V47.863a4.707,4.707,0,0,0-4.7-4.7h-1.1V39.334A10.533,10.533,0,0,0,96.79,28.817m8.717,17.964c.013,0,.025,0,.039,0h2.867a1.087,1.087,0,0,1,1.085,1.085V59.119a8.875,8.875,0,0,1-8.865,8.865H92.956a8.875,8.875,0,0,1-8.865-8.865V47.863a1.087,1.087,0,0,1,1.086-1.085h2.866c.013,0,.025,0,.039,0s.025,0,.038,0h17.348c.013,0,.025,0,.039,0m-1.808-3.62H89.889V39.334a6.9,6.9,0,0,1,13.809,0Z"
        transform="translate(-44.547 -15.952)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1387"
        data-name="Caminho 1387"
        d="M114.785,81.282a1.81,1.81,0,0,0,1.809-1.808V77.337a1.808,1.808,0,0,0-3.617,0v2.137a1.81,1.81,0,0,0,1.808,1.808"
        transform="translate(-62.538 -41.809)"
        fill="#ffb500"
      />
    </g>
  </svg>
</template>

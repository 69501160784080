<template>
  <svg
    id="Grupo_629"
    data-name="Grupo 629"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="126.273"
    height="132.451"
    viewBox="0 0 126.273 132.451"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_548" data-name="Retângulo 548" width="126.273" height="132.451" fill="#ffb500" />
      </clipPath>
    </defs>
    <g id="Grupo_628" data-name="Grupo 628" clip-path="url(#clip-path)">
      <path
        id="Caminho_1420"
        data-name="Caminho 1420"
        d="M124.907,131.956a1.327,1.327,0,0,0-1.327,1.327v9.081a1.363,1.363,0,0,1-.4.974,1.377,1.377,0,0,1-.984.4l-12.763-.06h-.006a1.325,1.325,0,0,0-1.225.818l-5.186,12.48a1.325,1.325,0,0,0,.283,1.442l8.96,9.04a1.37,1.37,0,0,1,.4.98,1.356,1.356,0,0,1-.411.968L99.522,181.985a1.412,1.412,0,0,1-.983.4,1.352,1.352,0,0,1-.966-.413l-8.99-9.081a1.327,1.327,0,0,0-1.449-.293l-12.479,5.144a1.326,1.326,0,0,0-.822,1.219l-.072,12.762a1.368,1.368,0,0,1-1.372,1.362H54.818a1.37,1.37,0,0,1-1.372-1.37l-.071-12.754a1.325,1.325,0,0,0-.821-1.219L40.073,172.6a1.327,1.327,0,0,0-1.449.293l-8.994,9.086a1.351,1.351,0,0,1-.962.407,1.436,1.436,0,0,1-.982-.4L14.96,169.4a1.352,1.352,0,0,1-.407-.963,1.372,1.372,0,0,1,.4-.981l8.959-9.04a1.325,1.325,0,0,0,.283-1.442l-5.184-12.48a1.326,1.326,0,0,0-1.225-.818h-.006l-12.767.06H5a1.367,1.367,0,0,1-1.373-1.372v-9.081a1.327,1.327,0,0,0-2.654,0v9.081a4,4,0,0,0,1.187,2.858,4.052,4.052,0,0,0,2.865,1.168l11.874-.056,4.5,10.842-8.337,8.413a4.025,4.025,0,0,0,.03,5.7l12.723,12.58a4.039,4.039,0,0,0,2.87,1.17,3.988,3.988,0,0,0,2.832-1.2l8.363-8.448,10.841,4.468.066,11.863a4.026,4.026,0,0,0,4.026,4.016H72.39a4.024,4.024,0,0,0,4.026-4.008l.067-11.871,10.84-4.468,8.359,8.443a3.991,3.991,0,0,0,2.837,1.2,4.065,4.065,0,0,0,2.869-1.169l12.719-12.577a4.025,4.025,0,0,0,.034-5.706l-8.337-8.413,4.5-10.842,11.87.056h.03a4.023,4.023,0,0,0,4.027-4.026v-9.081a1.327,1.327,0,0,0-1.327-1.327"
        transform="translate(-0.468 -63.289)"
        fill="#960b38"
      />
      <path
        id="Caminho_1421"
        data-name="Caminho 1421"
        d="M65.356,131.956H65.35a1.328,1.328,0,0,0-1.321,1.333,29.669,29.669,0,0,0,29.653,29.527c.107,0,.213,0,.316,0a29.67,29.67,0,0,0,29.666-29.527,1.328,1.328,0,0,0-1.321-1.333h-.006a1.327,1.327,0,0,0-1.327,1.321,27.016,27.016,0,0,1-27.026,26.886H93.84a27.016,27.016,0,0,1-27.157-26.886,1.327,1.327,0,0,0-1.327-1.321"
        transform="translate(-30.71 -63.289)"
        fill="#960b38"
      />
      <path
        id="Caminho_1422"
        data-name="Caminho 1422"
        d="M204.137,58.483a1.328,1.328,0,0,0,1.3,1.092,1.36,1.36,0,0,0,.237-.022,1.327,1.327,0,0,0,1.07-1.541,53.174,53.174,0,0,0-14.792-28.233,1.327,1.327,0,0,0-1.878,1.875,50.531,50.531,0,0,1,14.057,26.829"
        transform="translate(-90.981 -14.095)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1423"
        data-name="Caminho 1423"
        d="M18.449,69.53a1.325,1.325,0,0,0,.865-.321L36.437,54.5a1.327,1.327,0,0,0-1.73-2.013L18.449,66.454,4.909,54.818l6.07,0a1.326,1.326,0,0,0,1.327-1.327A50.838,50.838,0,0,1,91.358,11.2,1.327,1.327,0,1,0,92.834,9,53.491,53.491,0,0,0,9.668,52.166l-8.341,0A1.327,1.327,0,0,0,.462,54.5L17.584,69.21a1.325,1.325,0,0,0,.865.321"
        transform="translate(0 0)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1424"
        data-name="Caminho 1424"
        d="M56.6,56.609a38.255,38.255,0,0,0-7.005,14.117,1.326,1.326,0,0,0,.951,1.617,1.3,1.3,0,0,0,.334.043,1.328,1.328,0,0,0,1.284-.994A35.641,35.641,0,0,1,58.685,58.25,1.327,1.327,0,0,0,56.6,56.609"
        transform="translate(-23.766 -26.908)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1425"
        data-name="Caminho 1425"
        d="M162.78,66.4a1.328,1.328,0,0,0-1.871-.142l-16.258,13.97L131.111,68.6l6.063,0a1.327,1.327,0,0,0,1.327-1.327A38.519,38.519,0,0,0,77.332,36.088a1.327,1.327,0,0,0,1.562,2.146,35.864,35.864,0,0,1,56.93,27.709l-8.294,0a1.327,1.327,0,0,0-.865,2.333l17.122,14.714a1.326,1.326,0,0,0,1.729,0l17.123-14.714a1.327,1.327,0,0,0,.142-1.871"
        transform="translate(-36.828 -13.777)"
        fill="#ffb500"
      />
    </g>
  </svg>
</template>

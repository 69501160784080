<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 31.113 31.113">
    <g id="Grupo_371" data-name="Grupo 371" transform="translate(-1176.444 -25.444)">
      <rect
        id="Retângulo_403"
        data-name="Retângulo 403"
        width="40"
        height="4"
        rx="2"
        transform="translate(1207.556 28.272) rotate(135)"
        fill="#ffb500"
      />
      <rect
        id="Retângulo_402"
        data-name="Retângulo 402"
        width="40"
        height="4"
        rx="2"
        transform="translate(1179.272 25.444) rotate(45)"
        fill="#ffb500"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '31px',
    },
    height: {
      type: String,
      default: '31px',
    },
  },
}
</script>

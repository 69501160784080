<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 56.000000 58.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,58.000000) scale(0.100000,-0.100000)" :fill="color" stroke="none">
      <path
        d="M207 567 c-20 -7 -53 -25 -72 -40 -33 -26 -75 -92 -75 -117 0 -6 -14
-23 -30 -37 -25 -21 -30 -32 -30 -70 0 -24 5 -53 11 -64 13 -26 49 -39 72 -27
24 13 25 143 0 170 -16 18 -15 21 5 64 18 37 25 43 40 35 12 -6 30 -3 62 12
54 25 124 26 169 2 19 -9 45 -15 58 -12 17 3 28 -2 38 -17 23 -36 28 -71 11
-85 -12 -10 -16 -32 -16 -87 0 -64 3 -75 21 -85 19 -9 21 -18 17 -61 -4 -38
-12 -57 -34 -78 -30 -29 -94 -50 -94 -31 0 7 -22 11 -56 11 -52 0 -55 -1 -52
-22 2 -19 10 -24 47 -26 24 -2 49 2 54 7 6 6 20 11 32 11 31 0 82 36 100 69 8
17 15 49 15 72 0 32 5 45 25 61 21 16 25 28 25 74 0 48 -4 59 -29 82 -16 15
-33 40 -37 57 -4 16 -28 50 -53 75 -66 65 -138 83 -224 57z m127 -11 c47 -14
86 -41 86 -58 0 -14 -29 -9 -57 9 -35 23 -127 23 -172 1 -56 -30 -81 -20 -42
16 19 17 91 44 122 45 9 1 37 -5 63 -13z m-255 -190 c7 -9 11 -40 9 -78 -3
-56 -5 -63 -25 -66 -48 -7 -73 98 -33 138 24 24 34 25 49 6z m449 -14 c6 -9
12 -35 12 -57 0 -46 -26 -79 -57 -73 -15 3 -19 15 -21 66 -2 37 2 69 8 77 13
16 39 10 58 -13z m-180 -324 c-6 -17 -80 -17 -85 0 -3 8 9 12 42 12 33 0 45
-4 43 -12z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

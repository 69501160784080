<template>
  <svg
    id="Grupo_460"
    data-name="Grupo 460"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="100"
    height="69.951"
    viewBox="0 0 100 69.951"
  >
    <g id="Grupo_459" data-name="Grupo 459">
      <path
        id="Caminho_1043"
        data-name="Caminho 1043"
        d="M97.445,28.467a.974.974,0,0,0,0,1.947.609.609,0,0,1,.608.608v7.907a.608.608,0,0,1-.608.608H93.674V11.148a1.661,1.661,0,0,0-1.659-1.659H82.6V6.439A6.446,6.446,0,0,0,76.165,0H71.28a6.446,6.446,0,0,0-6.439,6.439V28.467H42.092a.974.974,0,0,0,0,1.947H64.841v9.123H35.159V5.718A5.725,5.725,0,0,0,29.44,0H23.115A5.725,5.725,0,0,0,17.4,5.718V9.489H7.985a1.661,1.661,0,0,0-1.659,1.659V39.537H2.555a.609.609,0,0,1-.608-.608V31.022a.609.609,0,0,1,.608-.608.974.974,0,0,0,0-1.947A2.558,2.558,0,0,0,0,31.022v7.907a2.558,2.558,0,0,0,2.555,2.555H6.326V58.8a1.661,1.661,0,0,0,1.659,1.659H17.4v3.771a5.725,5.725,0,0,0,5.718,5.718H29.44a5.725,5.725,0,0,0,5.718-5.718V41.485H64.841V63.512a6.446,6.446,0,0,0,6.439,6.439h4.884A6.446,6.446,0,0,0,82.6,63.512v-3.05h9.411A1.661,1.661,0,0,0,93.674,58.8V41.485h3.771A2.558,2.558,0,0,0,100,38.929V31.022a2.558,2.558,0,0,0-2.555-2.555M8.273,11.436H17.4V58.515H8.273Zm24.938,52.8A3.775,3.775,0,0,1,29.44,68H23.115a3.775,3.775,0,0,1-3.771-3.771V5.718a3.775,3.775,0,0,1,3.771-3.771H29.44a3.775,3.775,0,0,1,3.771,3.771Zm47.445-.721A4.5,4.5,0,0,1,76.165,68H71.28a4.5,4.5,0,0,1-4.492-4.492V6.439A4.5,4.5,0,0,1,71.28,1.947h4.884a4.5,4.5,0,0,1,4.491,4.492V63.512Zm11.071-5H82.6V11.436h9.123Z"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<template>
  <svg viewBox="0 0 104 69" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" :height="height">
    <g class="layer">
      <title>Layer 1</title>
      <g id="Grupo_469" data-name="Grupo 469">
        <g id="Grupo_468" data-name="Grupo 468">
          <path
            id="Caminho_924"
            d="m35.86,39.75a13.88,13.88 0 0 0 13.25,-17.97a0.38,0.38 0 0 0 -0.02,-0.05a1.69,1.69 0 0 0 -0.19,-0.34a1.73,1.73 0 0 0 -0.15,-0.24a1.69,1.69 0 0 0 -0.24,-0.2a1.74,1.74 0 0 0 -0.3,-0.21c-0.01,-0.01 -0.02,-0.03 -0.04,-0.04a1.46,1.46 0 0 0 -0.26,-0.05a1.64,1.64 0 0 0 -0.35,-0.08a1.72,1.72 0 0 0 -0.35,0.03a1.68,1.68 0 0 0 -0.27,0.03c-0.02,0.01 -0.03,0.02 -0.05,0.03a6.32,6.32 0 0 0 -0.58,0.34a1.79,1.79 0 0 0 -0.18,0.21a1.69,1.69 0 0 0 -0.22,0.32c-0.01,0.02 -0.03,0.03 -0.04,0.05a4.33,4.33 0 1 1 -5.71,-5.72c0.02,-0.01 0.03,-0.03 0.05,-0.04a1.78,1.78 0 0 0 0.27,-0.2a1.69,1.69 0 0 0 0.26,-0.2a1.71,1.71 0 0 0 0.14,-0.24a1.63,1.63 0 0 0 0.2,-0.35a0.37,0.37 0 0 0 0.02,-0.04a1.34,1.34 0 0 0 0.02,-0.24a1.77,1.77 0 0 0 0.04,-0.38a1.76,1.76 0 0 0 -0.08,-0.36a1.58,1.58 0 0 0 -0.05,-0.25c-0.01,-0.02 -0.03,-0.03 -0.04,-0.04a1.83,1.83 0 0 0 -0.22,-0.31a1.81,1.81 0 0 0 -0.18,-0.23a5.55,5.55 0 0 0 -0.59,-0.34c-0.01,0 -0.03,-0.02 -0.04,-0.02a14.06,14.06 0 0 0 -10.71,1.06a1.73,1.73 0 0 0 1.65,3.05a10.45,10.45 0 0 1 4.53,-1.22a7.79,7.79 0 0 0 10.81,10.79a10.4,10.4 0 1 1 -19.92,-4.59a1.73,1.73 0 1 0 -3.18,-1.39a13.88,13.88 0 0 0 12.72,19.43"
            data-name="Caminho 924"
            :fill="color"
            transform="translate(16.142 8.816)"
          />
          <path
            id="Caminho_925"
            d="m103.98,35.02a1.67,1.67 0 0 0 0.02,-0.32a1.62,1.62 0 0 0 -0.02,-0.33a1.58,1.58 0 0 0 -0.1,-0.3a1.68,1.68 0 0 0 -0.16,-0.32c-0.02,-0.03 -0.03,-0.08 -0.05,-0.11c-0.48,-0.61 -8.08,-10.28 -22.55,-16.94l6.03,-6.03a1.73,1.73 0 1 0 -2.45,-2.45l-7.02,7.01a69.13,69.13 0 0 0 -23.95,-4.78l0,-8.71a1.73,1.73 0 0 0 -3.47,0l0,8.71a69.12,69.12 0 0 0 -25.01,5.2l-4.72,-7.86a1.73,1.73 0 0 0 -2.97,1.78l4.51,7.51c-13.94,6.65 -21.27,15.95 -21.74,16.56c-0.03,0.03 -0.03,0.08 -0.05,0.11a1.55,1.55 0 0 0 -0.16,0.31a1.87,1.87 0 0 0 -0.1,0.31a1.82,1.82 0 0 0 -0.02,0.32a1.92,1.92 0 0 0 0.02,0.34a1.76,1.76 0 0 0 0.1,0.29a1.69,1.69 0 0 0 0.16,0.32c0.02,0.04 0.02,0.08 0.05,0.11c0.47,0.61 7.8,9.91 21.74,16.56l-4.51,7.51a1.73,1.73 0 0 0 2.97,1.78l4.72,-7.86a69.12,69.12 0 0 0 25.02,5.2l0,8.7a1.73,1.73 0 1 0 3.46,0l0,-8.7a69.1,69.1 0 0 0 25.01,-5.2l4.72,7.87a1.73,1.73 0 1 0 2.98,-1.79l-4.5,-7.51c13.94,-6.64 21.27,-15.95 21.73,-16.55c0.03,-0.04 0.03,-0.08 0.06,-0.12a1.71,1.71 0 0 0 0.15,-0.31a1.66,1.66 0 0 0 0.1,-0.31m-31.17,-0.32a20.81,20.81 0 1 1 -20.81,-20.82a20.83,20.83 0 0 1 20.81,20.82m-68.83,0a65.01,65.01 0 0 1 33.12,-19.14a24.2,24.2 0 0 0 -0.01,38.26a65.26,65.26 0 0 1 -33.11,-19.13m62.91,19.14a24.2,24.2 0 0 0 0.02,-38.26a65.26,65.26 0 0 1 33.11,19.13a65.01,65.01 0 0 1 -33.13,19.13"
            data-name="Caminho 925"
            :fill="color"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>

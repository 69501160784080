<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 30.063 29.971">
    <path
      id="Caminho_896"
      data-name="Caminho 896"
      d="M29.531-11.25A14.529,14.529,0,0,0,15-25.781,14.529,14.529,0,0,0,.469-11.25,14.537,14.537,0,0,0,12.729,3.105V-7.049H9.038v-4.2h3.691v-3.2c0-3.642,2.168-5.653,5.488-5.653a22.365,22.365,0,0,1,3.253.284v3.574H19.638a2.1,2.1,0,0,0-2.368,2.269v2.728H21.3l-.645,4.2H17.271V3.105A14.537,14.537,0,0,0,29.531-11.25Z"
      transform="translate(0.031 26.281)"
      :fill="color"
      stroke="rgba(0,0,0,0)"
      stroke-width="1"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

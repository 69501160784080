<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="66.263"
    height="50"
    viewBox="0 0 66.263 50"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_469" data-name="Retângulo 469" width="66.263" height="50" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_475" data-name="Grupo 475" clip-path="url(#clip-path)">
      <path
        id="Caminho_1052"
        data-name="Caminho 1052"
        d="M61.276,96.352l6.318-6.435c.7-.713-.4-1.81-1.1-1.1L59.641,95.8a.788.788,0,0,0,0,1.1l6.856,6.983c.7.713,1.8-.383,1.1-1.1l-6.318-6.435"
        transform="translate(-44.062 -65.692)"
        fill="#960b38"
      />
      <path
        id="Caminho_1053"
        data-name="Caminho 1053"
        d="M165.764,88.82c-.7-.713-1.8.384-1.1,1.1l6.318,6.435-6.318,6.435c-.7.713.4,1.81,1.1,1.1l6.857-6.983a.788.788,0,0,0,0-1.1l-6.857-6.984"
        transform="translate(-121.94 -65.691)"
        fill="#960b38"
      />
      <path
        id="Caminho_1054"
        data-name="Caminho 1054"
        d="M116.4,71.879l-8.413,19.9-1.215,2.874c-.384.909.951,1.7,1.339.783l8.413-19.9,1.215-2.875c.384-.909-.951-1.7-1.339-.783"
        transform="translate(-79.121 -52.998)"
        fill="#960b38"
      />
      <path
        id="Caminho_1055"
        data-name="Caminho 1055"
        d="M66.251,31.074V6.715c0-3.666-2.328-6.649-6.189-6.693-2.133-.025-4.267,0-6.4,0H26.228c-6.631,0-13.264-.051-19.9,0A6.189,6.189,0,0,0,.009,6.474C0,9.5.009,12.525.009,15.55V41.742c0,.716-.02,1.434,0,2.15a6.1,6.1,0,0,0,4.75,5.9,27.8,27.8,0,0,0,5.569.134H60.24a6.1,6.1,0,0,0,6-5.653c.045-.677.012-1.365.012-2.043ZM1.914,4.3C3.162,1.347,6.053,1.573,8.655,1.573h47.92c1.824,0,4.282-.348,5.916.609C65.242,3.793,64.7,7.2,64.7,9.891V11.9H1.56V7.238A7.245,7.245,0,0,1,1.914,4.3M64.7,43.728a4.549,4.549,0,0,1-4.461,4.646H7.983c-.614,0-1.232.016-1.846,0A4.547,4.547,0,0,1,1.56,43.907c-.23-6.552,0-13.153,0-19.708V13.449H64.7v8.693c0,7.194.079,14.393,0,21.586"
        transform="translate(0 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1056"
        data-name="Caminho 1056"
        d="M56.812,24.033a1.068,1.068,0,0,0,1.073-.87.642.642,0,0,0,.027-.213.631.631,0,0,0-.025-.2,1.079,1.079,0,0,0-.293-.568,1.083,1.083,0,1,0-.783,1.849"
        transform="translate(-41.337 -16.214)"
        fill="#960b38"
      />
      <path
        id="Caminho_1057"
        data-name="Caminho 1057"
        d="M41.033,24.033a1.068,1.068,0,0,0,1.073-.87.643.643,0,0,0,.027-.213.631.631,0,0,0-.025-.2,1.078,1.078,0,0,0-.293-.568,1.083,1.083,0,1,0-.783,1.849"
        transform="translate(-29.636 -16.214)"
        fill="#960b38"
      />
      <path
        id="Caminho_1058"
        data-name="Caminho 1058"
        d="M25.254,24.033a1.068,1.068,0,0,0,1.073-.87.643.643,0,0,0,.027-.213.631.631,0,0,0-.025-.2,1.076,1.076,0,0,0-.293-.568,1.083,1.083,0,1,0-.783,1.849"
        transform="translate(-17.935 -16.214)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    id="Grupo_479"
    data-name="Grupo 479"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="68.053"
    height="65.368"
    viewBox="0 0 68.053 65.368"
  >
    <g id="Grupo_479-2" data-name="Grupo 479">
      <path
        id="Caminho_1074"
        data-name="Caminho 1074"
        d="M154.552,109.986a10.752,10.752,0,1,0-4.954,11.135,10.878,10.878,0,0,0,4.954-11.135m-4.673,9.041a9.214,9.214,0,1,1,2.742-10.288,9.287,9.287,0,0,1-2.742,10.288"
        transform="translate(-99.008 -75.201)"
        fill="#960b38"
      />
      <path
        id="Caminho_1075"
        data-name="Caminho 1075"
        d="M154.667,117.9a6.632,6.632,0,0,0-1.576-1.833l-3.1-3.1-5.453-5.453a14.9,14.9,0,1,0-5.1,5.093c.05-.03.1-.062.148-.092,3.178,3.2,6.249,6.6,9.608,9.6,1.281,1.146,2.648.656,3.729-.425,1.029-1.029,2.454-2.143,1.753-3.788m-29.777-6.628a13.445,13.445,0,1,1,15.52-1.249,13.558,13.558,0,0,1-15.52,1.249m15.981.35a14.883,14.883,0,0,0,2.783-2.812l1.414,1.414-2.8,2.8-1.4-1.4m11.774,8.155c-.7.7-1.48,1.9-2.556,1.063a23.029,23.029,0,0,1-1.975-1.974l-3.006-3.007-1.747-1.747,2.8-2.8,6.406,6.406a1.274,1.274,0,0,1,.081,2.057"
        transform="translate(-86.801 -62.976)"
        fill="#960b38"
      />
      <path
        id="Caminho_1076"
        data-name="Caminho 1076"
        d="M63.913,63.827H1.541V.744A.771.771,0,0,0,0,.744V64.6a.782.782,0,0,0,.771.771H63.913a.771.771,0,0,0,0-1.541"
        transform="translate(0 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1077"
        data-name="Caminho 1077"
        d="M33.115,155.906h8.774a.711.711,0,0,0,.521-.206l.026-.025.014-.015a.711.711,0,0,0,.209-.525v-20.63a.782.782,0,0,0-.771-.771H33.115a.782.782,0,0,0-.771.771v20.63a.782.782,0,0,0,.771.771m.771-20.63h7.233v19.089H33.885Z"
        transform="translate(-24.037 -99.386)"
        fill="#960b38"
      />
      <path
        id="Caminho_1078"
        data-name="Caminho 1078"
        d="M93.193,83.892h5.962a.771.771,0,0,0,0-1.541H92.423a.782.782,0,0,0-.771.771V116.95a.782.782,0,0,0,.771.771H101.2a.782.782,0,0,0,.771-.771V112.5a.771.771,0,0,0-1.541,0v3.68H93.193V83.892Z"
        transform="translate(-68.112 -61.2)"
        fill="#960b38"
      />
      <path
        id="Caminho_1079"
        data-name="Caminho 1079"
        d="M150.961,205.01v2.541a.782.782,0,0,0,.771.771h8.774a.782.782,0,0,0,.771-.771V205.01a.771.771,0,0,0-1.541,0v1.77H152.5v-1.77a.771.771,0,0,0-1.541,0"
        transform="translate(-112.187 -151.801)"
        fill="#960b38"
      />
      <path
        id="Caminho_1080"
        data-name="Caminho 1080"
        d="M223.326,95.172h4.867v21.444a.771.771,0,0,0,1.541,0V94.4a.782.782,0,0,0-.771-.771h-5.638a.771.771,0,0,0,0,1.541"
        transform="translate(-165.413 -69.582)"
        fill="#960b38"
      />
      <path
        id="Caminho_1081"
        data-name="Caminho 1081"
        d="M202.982,26.322l4.852-10.078a4.25,4.25,0,0,0,2.327,0,4.392,4.392,0,0,0,3.01-5.162,1.156,1.156,0,0,0-.119-.409,4.333,4.333,0,0,0-8.263,2.6,4.3,4.3,0,0,0,1.657,2.309l-4.795,9.96c-.428.889.9,1.672,1.331.778m3.293-13.456c-1.035-3.467,4.205-4.978,5.37-1.583.837,3.489-4.334,5.052-5.37,1.583"
        transform="translate(-149.796 -5.747)"
        fill="#960b38"
      />
      <path
        id="Caminho_1082"
        data-name="Caminho 1082"
        d="M39.866,45.781a4.39,4.39,0,0,0,4.32-4.127,1.164,1.164,0,0,0,0-.426A4.76,4.76,0,0,0,43.4,39l9.386-9.386a4.277,4.277,0,0,0,2.461.79,4.231,4.231,0,0,0,1.5-.275L61.2,37.218c.525.838,1.859.066,1.33-.778l-4.459-7.116a4.656,4.656,0,0,0,1.5-3.052,1.156,1.156,0,0,0,0-.426,4.333,4.333,0,0,0-8.661.213,4.277,4.277,0,0,0,.79,2.461l-9.368,9.368a4.181,4.181,0,0,0-2.463-.787,4.34,4.34,0,1,0,0,8.68M58.047,26.059c-.17,3.582-5.589,3.62-5.6,0s5.428-3.585,5.6,0M42.665,41.441c-.17,3.582-5.589,3.62-5.6,0s5.428-3.585,5.6,0"
        transform="translate(-26.401 -16.141)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 54.000000 61.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,61.000000) scale(0.100000,-0.100000)" :fill="color" stroke="none">
      <path
        d="M161 568 c-46 -22 -102 -43 -123 -46 l-40 -5 4 -151 c3 -145 4 -153
32 -204 32 -56 75 -96 141 -130 71 -35 103 -36 171 -4 80 39 127 82 158 144
24 49 26 61 26 200 l0 148 -27 0 c-16 0 -71 20 -123 45 -52 25 -104 45 -115
44 -11 0 -58 -19 -104 -41z m210 -13 c54 -25 110 -45 124 -45 l25 0 0 -137 c0
-124 -3 -143 -24 -188 -43 -94 -184 -186 -254 -166 -84 24 -158 82 -197 153
-29 51 -30 57 -30 192 l0 139 45 12 c25 6 79 28 120 47 41 20 79 37 84 37 5 1
53 -19 107 -44z"
      />
      <path
        d="M182 535 c-41 -19 -78 -35 -83 -35 -5 0 -9 -5 -9 -10 0 -11 30 0 121
45 l55 27 70 -35 c38 -19 86 -40 107 -46 l37 -13 0 -101 c0 -56 -5 -119 -11
-141 -14 -52 -80 -120 -146 -151 l-54 -26 -57 26 c-31 14 -69 38 -85 52 -15
15 -27 21 -27 15 0 -17 73 -68 125 -88 41 -15 46 -15 92 2 63 24 147 107 162
160 6 21 11 89 11 151 l0 111 -37 13 c-21 6 -68 27 -105 45 -37 19 -73 34 -80
34 -7 0 -46 -16 -86 -35z"
      />
      <path
        d="M40 415 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25 -5 0
-10 -11 -10 -25z"
      />
      <path
        d="M220 385 c0 -33 -2 -35 -35 -35 -35 0 -35 0 -35 -45 0 -45 0 -45 35
-45 34 0 35 -1 35 -40 l0 -40 44 0 c44 0 45 0 48 38 3 35 5 37 41 40 l38 3 -3
42 c-3 41 -4 42 -40 45 -36 3 -38 5 -38 38 0 34 0 34 -45 34 -45 0 -45 0 -45
-35z m80 -9 c0 -33 2 -35 38 -38 34 -3 37 -6 40 -35 3 -32 2 -33 -37 -33 l-41
0 0 -41 c0 -39 -1 -40 -32 -37 -30 3 -33 6 -36 41 -3 35 -5 37 -38 37 -32 0
-34 2 -34 35 0 33 2 35 35 35 32 0 35 3 35 28 0 36 6 42 42 42 25 0 28 -3 28
-34z"
      />
      <path
        d="M40 308 c0 -24 5 -50 10 -58 7 -11 10 2 10 43 0 31 -4 57 -10 57 -5
0 -10 -19 -10 -42z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <svg
    id="Grupo_458"
    data-name="Grupo 458"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="90.218"
    height="88.462"
    viewBox="0 0 90.218 88.462"
  >
    <g id="Grupo_457" data-name="Grupo 457">
      <path
        id="Caminho_1038"
        data-name="Caminho 1038"
        d="M143.22,160.373a1.2,1.2,0,0,0,1.2-1.2v-2.559c5.286-.459,5.723-3.955,5.723-5.1,0-3.274-2.4-5.387-6.77-5.951-4.386-.566-4.327-2.277-4.305-2.921,0-.065,0-.121,0-.168,0-.838.4-2.786,4.148-2.786,1.921,0,3.247.566,3.836,1.637a1.2,1.2,0,1,0,2.1-1.156,5.724,5.724,0,0,0-4.74-2.817v-2.535a1.2,1.2,0,0,0-2.4,0v2.534c-3.92.425-5.349,2.94-5.349,5.123,0,.024,0,.053,0,.086-.1,2.969,2.111,4.831,6.4,5.384,4.68.6,4.677,2.834,4.676,3.567v.03c0,1.809-1.522,2.727-4.523,2.727-2.138,0-3.578-.723-4.279-2.151a1.2,1.2,0,1,0-2.155,1.059,6.3,6.3,0,0,0,5.234,3.42v2.581a1.2,1.2,0,0,0,1.2,1.2"
        transform="translate(-98.163 -95.972)"
        fill="#960b38"
      />
      <path
        id="Caminho_1039"
        data-name="Caminho 1039"
        d="M114.671,108.161a1.2,1.2,0,0,0-.639-1.573,21.2,21.2,0,1,0,11.328,11.342,1.2,1.2,0,1,0-2.213.931A18.836,18.836,0,1,1,113.1,108.8a1.2,1.2,0,0,0,1.573-.639"
        transform="translate(-60.705 -75.363)"
        fill="#960b38"
      />
      <path
        id="Caminho_1040"
        data-name="Caminho 1040"
        d="M89.778,36.223,45.869.271a1.2,1.2,0,0,0-1.521,0L.44,36.223a1.2,1.2,0,0,0-.166,1.692L5.68,44.472a1.2,1.2,0,0,0,1.686.166L45.108,13.764,82.851,44.638a1.2,1.2,0,0,0,1.686-.166l5.406-6.557a1.2,1.2,0,0,0-.166-1.692m-6.332,5.8L45.869,11.284a1.2,1.2,0,0,0-1.52,0L6.771,42.022l-3.879-4.7L45.108,2.752,87.325,37.318Z"
        transform="translate(0 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1041"
        data-name="Caminho 1041"
        d="M221.545,14.545a1.2,1.2,0,0,0,1.2-1.2V9.272h8.026V21.123a1.2,1.2,0,0,0,2.4,0V8.071a1.2,1.2,0,0,0-1.2-1.2H221.545a1.2,1.2,0,0,0-1.2,1.2v5.273a1.2,1.2,0,0,0,1.2,1.2"
        transform="translate(-158.272 -4.935)"
        fill="#960b38"
      />
      <path
        id="Caminho_1042"
        data-name="Caminho 1042"
        d="M97.068,198.634h-.941V169.4a1.2,1.2,0,0,0-2.4,0v30.437a1.2,1.2,0,0,0,1.2,1.2h2.142a1.506,1.506,0,0,1,1.5,1.5v4.337H27.035v-4.337a1.506,1.506,0,0,1,1.5-1.5H86.778a1.2,1.2,0,1,0,0-2.4h-54.9V169.4a1.2,1.2,0,0,0-2.4,0v29.237h-.941a3.909,3.909,0,0,0-3.9,3.9v5.537a1.2,1.2,0,0,0,1.2,1.2H99.772a1.2,1.2,0,0,0,1.2-1.2v-5.537a3.909,3.909,0,0,0-3.9-3.9"
        transform="translate(-17.694 -120.814)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

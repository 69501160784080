<template>
  <svg viewBox="0 0 86.603 97.625" xmlns="http://www.w3.org/2000/svg">
    <g
      id="Grupo_585"
      data-name="Grupo 585"
      style=""
      transform="matrix(0.8946439623832703, 0, 0, 0.8943479657173157, 0.000014000001101521775, 0.00010600000678073762)"
    >
      <path
        id="Caminho_1360"
        data-name="Caminho 1360"
        d="M49.857,95.125a1.653,1.653,0,0,0-.018-.189c-.006-.031-.017-.059-.024-.088a1.7,1.7,0,0,0-.072-.239c0-.009-.009-.017-.013-.026a1.691,1.691,0,0,0-1.5-1.029c-7.222-.238-13.845-11.323-16.7-27.765A91.127,91.127,0,0,1,48.473,64.22c2.559,0,5.132.105,7.647.312a1.694,1.694,0,0,0,.276-3.377c-2.61-.215-5.276-.324-7.923-.324a94.57,94.57,0,0,0-17.46,1.606,104.994,104.994,0,0,1,0-27.934,94.779,94.779,0,0,0,17.459,1.612,94.79,94.79,0,0,0,17.452-1.611,102.877,102.877,0,0,1,.934,13.969v.333a1.694,1.694,0,0,0,3.387,0v-.333a106.075,106.075,0,0,0-1-14.661A76.1,76.1,0,0,0,88.086,26.94a45.162,45.162,0,0,1,3.438,34.943,1.694,1.694,0,1,0,3.242.985,48.536,48.536,0,0,0-4.542-39.023c0-.008-.006-.015-.01-.023s-.01-.013-.015-.02l0-.005A48.045,48.045,0,0,0,48.968.012C48.813,0,48.641,0,48.48,0h-.015c-.16,0-.306,0-.408.01h-.028A48.1,48.1,0,0,0,6.755,23.786c-.008.012-.018.023-.025.035s-.01.023-.015.035a48.491,48.491,0,0,0,.018,49.27v0l.014.025A48.139,48.139,0,0,0,48.17,96.948h.007a1.68,1.68,0,0,0,1.116-.431c.022-.019.047-.035.068-.056.038-.037.069-.08.1-.12s.074-.082.106-.127.052-.091.077-.136a1.547,1.547,0,0,0,.079-.144,1.66,1.66,0,0,0,.061-.182c.013-.043.031-.083.041-.127a1.793,1.793,0,0,0,.026-.2c0-.042.015-.082.016-.125,0-.005,0-.011,0-.016s0-.014,0-.021c0-.048-.01-.093-.013-.14M48.165,3.395h.009c.091,0,.187-.006.284,0h.088c.081,0,.16,0,.252.005,7.178.335,13.764,11.417,16.607,27.762A91.387,91.387,0,0,1,48.472,32.73a91.391,91.391,0,0,1-16.94-1.573C34.377,14.813,40.969,3.731,48.165,3.395m9.417.908A44.662,44.662,0,0,1,86.353,24.027a72.642,72.642,0,0,1-17.63,6.436C66.635,18.552,62.615,9.347,57.582,4.3m-18.227.01C34.32,9.359,30.3,18.56,28.215,30.461a72.632,72.632,0,0,1-17.623-6.434A44.685,44.685,0,0,1,39.354,4.313M8.859,26.94a76.085,76.085,0,0,0,18.832,6.872,108.148,108.148,0,0,0,0,29.31A75.405,75.405,0,0,0,8.855,70a45.122,45.122,0,0,1,0-43.063M28.215,66.49c2.087,11.922,6.1,21.12,11.136,26.157A44.689,44.689,0,0,1,10.6,72.927a71.95,71.95,0,0,1,17.62-6.437"
        transform="translate(0 0.001)"
        fill="#960b38"
      />
      <path
        id="Caminho_1361"
        data-name="Caminho 1361"
        d="M166.079,146.585h-3.347v-5.84a13.341,13.341,0,0,0-26.682,0v5.84H132.7a4.409,4.409,0,0,0-4.4,4.4V168.2a14.977,14.977,0,0,0,14.96,14.96h12.266a14.977,14.977,0,0,0,14.96-14.96V150.99a4.41,4.41,0,0,0-4.4-4.4m-28.39,3.388c.018,0,.036.005.054.005s.036,0,.054-.005h23.186c.018,0,.036.005.054.005s.036,0,.055-.005h4.986a1.018,1.018,0,0,1,1.017,1.017V168.2a11.585,11.585,0,0,1-11.572,11.572H143.257A11.586,11.586,0,0,1,131.684,168.2V150.99a1.018,1.018,0,0,1,1.017-1.017Zm21.655-3.388H139.437v-5.84a9.953,9.953,0,0,1,19.907,0Z"
        transform="translate(-74.637 -74.12)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1362"
        data-name="Caminho 1362"
        d="M176.372,196.857a1.7,1.7,0,0,0,1.693-1.694v-2.856a1.694,1.694,0,0,0-3.388,0v2.856a1.7,1.7,0,0,0,1.694,1.694"
        transform="translate(-101.619 -110.889)"
        fill="#ffb500"
      />
    </g>
  </svg>
</template>

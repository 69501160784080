<template>
  <svg
    id="Grupo_456"
    data-name="Grupo 456"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="100"
    height="88.205"
    viewBox="0 0 100 88.205"
  >
    <g id="Grupo_455" data-name="Grupo 455">
      <path
        id="Caminho_1029"
        data-name="Caminho 1029"
        d="M19.386,57.323l-2.627.785a3.564,3.564,0,0,1-4.435-2.393L2.578,23.105a3.567,3.567,0,0,1,2.393-4.435L58.813,2.578a3.569,3.569,0,0,1,4.435,2.394l8.46,28.305a1.214,1.214,0,1,0,2.326-.7L65.574,4.276A6,6,0,0,0,58.118.251L4.277,16.344A6,6,0,0,0,.251,23.8L10,56.409a5.99,5.99,0,0,0,7.457,4.025l2.627-.786a1.214,1.214,0,1,0-.7-2.326"
        transform="translate(0 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1030"
        data-name="Caminho 1030"
        d="M17.536,48.646a1.208,1.208,0,0,0,.348-.051l51.927-15.52a1.214,1.214,0,1,0-.7-2.326L17.189,46.268a1.214,1.214,0,0,0,.347,2.378"
        transform="translate(-6.482 -12.191)"
        fill="#960b38"
      />
      <path
        id="Caminho_1031"
        data-name="Caminho 1031"
        d="M66.333,16.9a1.213,1.213,0,0,0-1.511-.816L12.894,31.6a1.214,1.214,0,1,0,.7,2.326L65.517,18.41a1.214,1.214,0,0,0,.816-1.511"
        transform="translate(-4.777 -6.367)"
        fill="#960b38"
      />
      <path
        id="Caminho_1032"
        data-name="Caminho 1032"
        d="M109.709,62.067H47.365a6.522,6.522,0,0,0-6.515,6.514V106.34a6.522,6.522,0,0,0,6.515,6.515h62.344a6.522,6.522,0,0,0,6.514-6.515V68.581a6.521,6.521,0,0,0-6.514-6.514M113.8,106.34a4.091,4.091,0,0,1-4.086,4.086H47.365a4.091,4.091,0,0,1-4.087-4.086V68.581A4.091,4.091,0,0,1,47.365,64.5h62.344a4.091,4.091,0,0,1,4.086,4.086Z"
        transform="translate(-16.224 -24.65)"
        fill="#960b38"
      />
      <path
        id="Caminho_1033"
        data-name="Caminho 1033"
        d="M65.333,123.851H58.718a1.214,1.214,0,0,0,0,2.428h6.614a1.214,1.214,0,0,0,0-2.428"
        transform="translate(-22.838 -49.188)"
        fill="#960b38"
      />
      <path
        id="Caminho_1034"
        data-name="Caminho 1034"
        d="M90.424,123.851H83.809a1.214,1.214,0,0,0,0,2.428h6.614a1.214,1.214,0,0,0,0-2.428"
        transform="translate(-32.803 -49.188)"
        fill="#960b38"
      />
      <path
        id="Caminho_1035"
        data-name="Caminho 1035"
        d="M116.487,123.851h-6.614a1.214,1.214,0,0,0,0,2.428h6.614a1.214,1.214,0,0,0,0-2.428"
        transform="translate(-43.154 -49.188)"
        fill="#960b38"
      />
      <path
        id="Caminho_1036"
        data-name="Caminho 1036"
        d="M142.55,123.851h-6.614a1.214,1.214,0,0,0,0,2.428h6.614a1.214,1.214,0,0,0,0-2.428"
        transform="translate(-53.505 -49.188)"
        fill="#960b38"
      />
      <path
        id="Caminho_1037"
        data-name="Caminho 1037"
        d="M120.834,95.62h10.019a3.72,3.72,0,0,0,3.715-3.715v-5.57a3.72,3.72,0,0,0-3.715-3.716H120.834a3.72,3.72,0,0,0-3.716,3.716V91.9a3.72,3.72,0,0,0,3.716,3.715m-1.288-9.285a1.289,1.289,0,0,1,1.288-1.288h10.019a1.289,1.289,0,0,1,1.287,1.288V91.9a1.289,1.289,0,0,1-1.287,1.287H120.834a1.289,1.289,0,0,1-1.288-1.287Z"
        transform="translate(-46.514 -32.813)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    viewBox="0 0 55.61 64.01"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <g class="layer">
      <title>Layer 1</title>
      <g id="Grupo_486" data-name="Grupo 486">
        <g id="Grupo_485" data-name="Grupo 485">
          <path
            id="Caminho_942"
            d="m31.39,56.99l0,-1.55a1.04,1.04 0 0 0 -0.07,-0.39l-1.76,-4.38a10.42,10.42 0 0 0 3.25,-3.05a1.03,1.03 0 0 0 -1.7,-1.16a8.22,8.22 0 0 1 -3.24,2.77a1.03,1.03 0 0 0 -0.57,1.34l2.03,5.06l0,0.87l-2.12,0a1.03,1.03 0 1 0 0,2.06l2.85,0a3.75,3.75 0 0 1 1.37,3.17l-27.33,0.02l0,-0.02a3.76,3.76 0 0 1 1.36,-3.16l15.45,0a1.03,1.03 0 0 0 0,-2.07l-14.71,0l0,-0.87l2.03,-5.06a1.03,1.03 0 0 0 0.07,-0.38l0,-30.45c0,-6.45 2.99,-10.04 9.99,-11.99a1.03,1.03 0 1 0 -0.56,-1.99c-7.84,2.19 -11.5,6.63 -11.5,13.98l0,30.25l-2.02,5.06a1.04,1.04 0 0 0 -0.08,0.39l0,1.55a5.79,5.79 0 0 0 -2.1,4.74l0,1.05a1.03,1.03 0 0 0 1.04,1.04l29.39,0a1.03,1.03 0 0 0 1.03,-1.04l0,-1.05a5.79,5.79 0 0 0 -2.1,-4.74"
            data-name="Caminho 942"
            :fill="color"
            transform="translate(0.066 0.185)"
          />
          <path
            id="Caminho_943"
            d="m19.93,2.06l4.47,0l-2.21,3.69l0,0.01l0,0.01a0.89,0.89 0 0 0 -0.06,0.16a1.07,1.07 0 0 0 -0.07,0.22a1,1 0 0 0 0.01,0.17a0.98,0.98 0 0 0 0.01,0.22a1.04,1.04 0 0 0 0.08,0.16a1.01,1.01 0 0 0 0.1,0.2a1.07,1.07 0 0 0 0.15,0.13a0.96,0.96 0 0 0 0.14,0.13l0.01,0l0.01,0.01c0.07,0.04 7.08,4.14 16.04,14.95l-0.93,1.55a3.27,3.27 0 0 1 -3.94,1.38l-5.05,-1.9a1.03,1.03 0 0 0 -0.82,0.05c-0.23,0.11 -5.79,2.79 -10.44,-0.94a1.03,1.03 0 0 0 -1.29,1.62a10.91,10.91 0 0 0 6.98,2.42a1.06,1.06 0 0 0 0.01,0.21c1.04,3.63 3.27,5.85 5.24,7.81a14.03,14.03 0 0 1 3.68,4.86a1.03,1.03 0 1 0 1.94,-0.71a15.82,15.82 0 0 0 -4.16,-5.62c-1.84,-1.83 -3.73,-3.72 -4.64,-6.72a14.2,14.2 0 0 0 3.17,-0.89l4.65,1.75a5.33,5.33 0 0 0 6.44,-2.25l1.31,-2.18c0,-0.01 0,-0.02 0.01,-0.03a0.98,0.98 0 0 0 0.07,-0.21a0.54,0.54 0 0 0 0.04,-0.37a0.97,0.97 0 0 0 -0.01,-0.19a0.91,0.91 0 0 0 -0.08,-0.16a0.97,0.97 0 0 0 -0.1,-0.2c-0.01,-0.01 -0.01,-0.02 -0.02,-0.03c-7.67,-9.37 -13.93,-13.94 -16.19,-15.42l2.63,-4.39a1.03,1.03 0 0 0 -0.88,-1.56l-6.3,0c-9.64,0 -19.93,4.96 -19.93,18.88l0,15.74a1.03,1.03 0 0 0 2.06,0l0,-15.74c0,-12.4 9.23,-16.81 17.87,-16.81"
            data-name="Caminho 943"
            :fill="color"
          />
          <path
            id="Caminho_944"
            d="m54.51,41.08l-6.3,-5.25a1.03,1.03 0 1 0 -1.32,1.59l4.11,3.42l-27.59,0a1.03,1.03 0 1 0 0,2.07l27.59,0l-4.11,3.42a1.03,1.03 0 1 0 1.32,1.59l6.3,-5.25a1.03,1.03 0 0 0 0,-1.59"
            data-name="Caminho 944"
            :fill="color"
            transform="translate(0.722 1.148)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>

<template>
  <svg
    id="Grupo_452"
    data-name="Grupo 452"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="width"
    :height="height"
    viewBox="0 0 135.36 103.479"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_460" data-name="Retângulo 460" width="135.36" height="103.479" fill="#00b4ff" />
      </clipPath>
    </defs>
    <g id="Grupo_451" data-name="Grupo 451">
      <path
        id="Caminho_1016"
        data-name="Caminho 1016"
        d="M134.132,85.552a5.121,5.121,0,0,0-3.9-1.8H123V6.518A6.525,6.525,0,0,0,116.482,0h-97.6a6.525,6.525,0,0,0-6.518,6.518V83.753H5.132a5.129,5.129,0,0,0-5.07,5.9l1.3,9.5a5.106,5.106,0,0,0,5.065,4.323h122.51a5.114,5.114,0,0,0,5.07-4.359l1.29-9.433a5.121,5.121,0,0,0-1.164-4.134M15.688,6.518a3.2,3.2,0,0,1,3.193-3.193h97.6a3.2,3.2,0,0,1,3.193,3.193V83.753H15.688ZM132.008,89.2l-1.289,9.433a1.8,1.8,0,0,1-1.782,1.521H6.427A1.788,1.788,0,0,1,4.65,98.669l-1.3-9.5a1.8,1.8,0,0,1,1.782-2.088h125.1a1.777,1.777,0,0,1,1.372.633,1.8,1.8,0,0,1,.4,1.49"
        transform="translate(0)"
        fill="#00b4ff"
      />
      <path
        id="Caminho_1017"
        data-name="Caminho 1017"
        d="M167.256,236.3H151.493a1.662,1.662,0,1,0,0,3.324h15.763a1.662,1.662,0,0,0,0-3.324"
        transform="translate(-91.693 -144.609)"
        fill="#1f214a"
      />
      <path
        id="Caminho_1018"
        data-name="Caminho 1018"
        d="M132.815,126.208a1.653,1.653,0,0,0-1.785,1.27L124.2,145.848l-7.112-18.381a1.655,1.655,0,0,0-1.781-1.259c-15.385,1.624-23.185,7.61-23.185,17.792v9.36a1.662,1.662,0,0,0,3.325,0V144c0-8.046,6.195-12.744,18.926-14.338l8.309,21.473a1.662,1.662,0,0,0,1.55,1.062h.012a1.661,1.661,0,0,0,1.546-1.083l7.972-21.449c12.717,1.6,18.905,6.293,18.905,14.335v9.36a1.662,1.662,0,0,0,3.325,0V144c0-10.181-7.8-16.167-23.179-17.792"
        transform="translate(-56.378 -77.231)"
        fill="#1f214a"
      />
      <path
        id="Caminho_1019"
        data-name="Caminho 1019"
        d="M214.228,174.332h-3.187a1.662,1.662,0,1,0,0,3.324h3.187a1.662,1.662,0,1,0,0-3.324"
        transform="translate(-128.135 -106.687)"
        fill="#1f214a"
      />
      <path
        id="Caminho_1020"
        data-name="Caminho 1020"
        d="M151.063,66.029a14.813,14.813,0,1,0-14.814-14.814,14.83,14.83,0,0,0,14.814,14.814m0-26.3a11.489,11.489,0,1,1-11.489,11.489,11.5,11.5,0,0,1,11.489-11.489"
        transform="translate(-83.381 -22.277)"
        fill="#1f214a"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

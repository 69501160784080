<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 73.000000 49.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,49.000000) scale(0.100000,-0.100000)" :fill="color" stroke="none">
      <path
        d="M82 474 c-21 -15 -22 -20 -20 -187 l2 -172 6 167 c3 117 9 171 18
180 19 19 496 24 532 5 25 -12 25 -14 30 -182 l6 -170 2 172 c2 167 1 172 -20
187 -33 24 -523 24 -556 0z"
      />
      <path
        d="M236 401 c17 -7 25 -27 39 -88 9 -43 24 -84 33 -90 19 -15 162 -18
162 -3 0 5 -37 10 -82 12 -70 3 -83 6 -86 21 -3 15 6 17 86 17 89 0 90 0 102
29 6 16 10 40 8 53 -3 22 -6 23 -98 24 l-95 1 90 -6 90 -6 -2 -34 c-1 -18 -7
-38 -15 -42 -7 -5 -49 -8 -92 -7 l-79 3 -16 60 c-14 52 -19 60 -41 62 -24 3
-24 2 -4 -6z"
      />
      <path
        d="M297 193 c-12 -12 -7 -41 7 -46 22 -9 41 19 26 38 -14 16 -23 19 -33
8z m28 -23 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4
9 -10z"
      />
      <path
        d="M420 185 c-10 -12 -10 -18 0 -29 16 -20 42 -8 38 17 -4 26 -21 32
-38 12z m30 -15 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16
-4 16 -10z"
      />
      <path
        d="M11 71 c-12 -8 -13 -14 -3 -35 l12 -26 339 0 c334 0 340 0 351 21 25
46 11 51 -137 44 -172 -7 -253 -7 -418 0 -74 3 -136 1 -144 -4z m692 -7 c3 -4
2 -15 -4 -25 -9 -18 -26 -19 -342 -17 -318 3 -332 4 -335 22 -4 19 3 19 189
14 156 -4 418 1 475 10 6 1 14 -1 17 -4z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <svg
    viewBox="0 0 62 53.01"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    :height="height"
  >
    <g class="layer">
      <title>Layer 1</title>
      <g id="Grupo_484" data-name="Grupo 484">
        <g id="Grupo_483" data-name="Grupo 483">
          <path
            id="Caminho_936"
            d="m46.26,23.56a6.01,6.01 0 1 0 6.01,6.01a6.02,6.02 0 0 0 -6.01,-6.01m0,9.99a3.98,3.98 0 1 1 3.98,-3.98a3.98,3.98 0 0 1 -3.98,3.98"
            data-name="Caminho 936"
            :fill="color"
            transform="translate(0.732 0.429)"
          />
          <path
            id="Caminho_937"
            d="m60.55,26.73l-3.71,-0.53a10.86,10.86 0 0 0 -0.55,-1.33l2.25,-3a1.02,1.02 0 0 0 -0.09,-1.33l-2.83,-2.83a1.01,1.01 0 0 0 -1.33,-0.1l-3,2.25a10.86,10.86 0 0 0 -1.33,-0.54l-0.53,-3.72a1.02,1.02 0 0 0 -1.01,-0.88l-4,0a1.02,1.02 0 0 0 -1.01,0.88l-0.53,3.72a10.93,10.93 0 0 0 -1.33,0.54l-3,-2.25a1.01,1.01 0 0 0 -1.33,0.1l-2.83,2.83a1.02,1.02 0 0 0 -0.09,1.33l2.25,3a10.86,10.86 0 0 0 -0.55,1.33l-3.72,0.53a1.02,1.02 0 0 0 -0.87,1.01l0,4a1.02,1.02 0 0 0 0.87,1.01l3.72,0.52a11.18,11.18 0 0 0 0.55,1.33l-2.25,3a1.02,1.02 0 0 0 0.09,1.33l2.83,2.83a1.02,1.02 0 0 0 1.33,0.1l3,-2.26a11.09,11.09 0 0 0 1.33,0.55l0.53,3.72a1.02,1.02 0 0 0 1.01,0.87l4,0a1.02,1.02 0 0 0 1.01,-0.87l0.53,-3.72a11.02,11.02 0 0 0 1.33,-0.55l3,2.26a1.02,1.02 0 0 0 1.33,-0.1l2.83,-2.83a1.02,1.02 0 0 0 0.09,-1.33l-2.25,-3a11.01,11.01 0 0 0 0.55,-1.33l3.72,-0.53a1.02,1.02 0 0 0 0.87,-1.01l0,-4a1.02,1.02 0 0 0 -0.87,-1m-1.17,4.12l-3.48,0.5a1.02,1.02 0 0 0 -0.84,0.74a8.91,8.91 0 0 1 -0.87,2.1a1.02,1.02 0 0 0 0.07,1.12l2.11,2.81l-1.58,1.58l-2.81,-2.11a1.02,1.02 0 0 0 -1.12,-0.07a8.91,8.91 0 0 1 -2.1,0.87a1.02,1.02 0 0 0 -0.74,0.84l-0.5,3.48l-2.23,0l-0.5,-3.48a1.02,1.02 0 0 0 -0.74,-0.84a8.9,8.9 0 0 1 -2.1,-0.87a1.02,1.02 0 0 0 -1.12,0.07l-2.81,2.11l-1.57,-1.58l2.11,-2.81a1.02,1.02 0 0 0 0.06,-1.12a8.9,8.9 0 0 1 -0.86,-2.1a1.02,1.02 0 0 0 -0.84,-0.74l-3.48,-0.5l0,-2.23l3.48,-0.5a1.02,1.02 0 0 0 0.84,-0.74a9,9 0 0 1 0.86,-2.1a1.01,1.01 0 0 0 -0.06,-1.12l-2.11,-2.81l1.57,-1.58l2.82,2.11a1.01,1.01 0 0 0 1.12,0.07a8.95,8.95 0 0 1 2.1,-0.87a1.02,1.02 0 0 0 0.74,-0.84l0.5,-3.48l2.23,0l0.5,3.48a1.01,1.01 0 0 0 0.74,0.84a8.91,8.91 0 0 1 2.1,0.87a1.01,1.01 0 0 0 1.12,-0.07l2.8,-2.11l1.58,1.58l-2.11,2.81a1.01,1.01 0 0 0 -0.07,1.12a8.91,8.91 0 0 1 0.87,2.1a1.01,1.01 0 0 0 0.84,0.74l3.48,0.5l0,2.23z"
            data-name="Caminho 937"
            :fill="color"
            transform="translate(0.571 0.268)"
          />
          <path
            id="Caminho_938"
            d="m55.98,43.97a1.02,1.02 0 0 0 -1.01,1.02l0,5a0.98,0.98 0 0 1 -0.98,0.98l-50.97,0a0.98,0.98 0 0 1 -0.98,-0.98l0,-39.96l52.93,0l0,3.98a1.02,1.02 0 0 0 2.03,0l0,-10.99a3.02,3.02 0 0 0 -3.01,-3.02l-50.97,0a3.02,3.02 0 0 0 -3.02,3.02l0,46.97a3.02,3.02 0 0 0 3.02,3.02l50.97,0a3.02,3.02 0 0 0 3.01,-3.02l0,-5a1.02,1.02 0 0 0 -1.02,-1.02m-52.96,-41.93l50.97,0a0.98,0.98 0 0 1 0.98,0.98l0,4.98l-52.93,0l0,-4.98a0.98,0.98 0 0 1 0.98,-0.98"
            data-name="Caminho 938"
            :fill="color"
            transform="translate(0 0)"
          />
          <path
            id="Caminho_939"
            d="m5.93,5.96l1,0a1.02,1.02 0 1 0 0,-2.03l-1,0a1.02,1.02 0 1 0 0,2.03"
            data-name="Caminho 939"
            :fill="color"
            transform="translate(0.089 0.072)"
          />
          <path
            id="Caminho_940"
            d="m10.83,5.96l1,0a1.02,1.02 0 1 0 0,-2.03l-1,0a1.02,1.02 0 1 0 0,2.03"
            data-name="Caminho 940"
            :fill="color"
            transform="translate(0.178 0.072)"
          />
          <path
            id="Caminho_941"
            d="m15.74,5.96l1,0a1.02,1.02 0 0 0 0,-2.03l-1,0a1.02,1.02 0 1 0 0,2.03"
            data-name="Caminho 941"
            :fill="color"
            transform="translate(0.268 0.072)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>

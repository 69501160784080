<template>
  <svg
    id="Icon_Color"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="21.349 109.562 734.098 376.156"
    shape-rendering="auto"
  >
    <g>
      <path
        :class="iconColor"
        d="M454.03,247.12c1.17,1.87,3.31,3.61,6.41,5.23,3.1,1.62,7.37,3.11,12.82,4.48,5.44,1.37,12.36,2.8,20.74,4.3,9.21,1.75,17.38,3.67,24.51,5.79,7.12,2.12,13.02,4.7,17.72,7.75,4.69,3.05,8.25,6.6,10.68,10.65,2.43,4.05,3.65,8.81,3.65,14.29,0,6.23-1.54,11.77-4.6,16.62-3.07,4.86-7.34,8.97-12.8,12.33-5.47,3.36-12.04,5.92-19.71,7.66-7.68,1.74-16.11,2.62-25.3,2.62-13.54,0-26.49-1.71-38.86-5.14-12.37-3.42-23.82-8.69-34.34-15.78l18.55-16.44c8.52,5.48,17.13,9.68,25.82,12.61,8.69,2.93,18.55,4.39,29.58,4.39,9.53,0,17.09-1.49,22.69-4.48,5.6-2.99,8.4-7.03,8.4-12.14,0-2.36-.54-4.48-1.63-6.35-1.09-1.87-3.1-3.58-6.03-5.14-2.93-1.56-6.96-3.02-12.06-4.39-5.11-1.37-11.77-2.8-19.98-4.3-9.39-1.62-17.72-3.42-25.01-5.42-7.29-1.99-13.4-4.48-18.35-7.47-4.94-2.99-8.72-6.6-11.31-10.83-2.6-4.23-3.89-9.4-3.89-15.5,0-5.73,1.49-10.93,4.47-15.6,2.98-4.67,7.09-8.69,12.31-12.05,5.22-3.36,11.5-5.95,18.84-7.75,7.34-1.8,15.35-2.71,24.04-2.71,12.87,0,24.44,1.4,34.72,4.2,10.28,2.8,19.93,6.88,28.95,12.23l-16.55,17.37c-8.02-4.36-15.96-7.69-23.82-9.99-7.86-2.3-15.79-3.46-23.82-3.46-9.02,0-16.04,1.53-21.06,4.58-5.01,3.05-7.52,6.76-7.52,11.11,0,2.61,.58,4.86,1.76,6.72Z"
      />
      <path class="cls-4" :class="iconColor" d="M600.43,206.21v130.75h-30.58V206.21h30.58Z" />
      <path
        :class="iconColor"
        d="M656.34,247.12c1.17,1.87,3.31,3.61,6.41,5.23,3.1,1.62,7.37,3.11,12.82,4.48,5.44,1.37,12.36,2.8,20.74,4.3,9.21,1.75,17.38,3.67,24.51,5.79,7.12,2.12,13.02,4.7,17.72,7.75,4.69,3.05,8.25,6.6,10.68,10.65,2.43,4.05,3.65,8.81,3.65,14.29,0,6.23-1.54,11.77-4.6,16.62-3.07,4.86-7.34,8.97-12.8,12.33-5.47,3.36-12.04,5.92-19.71,7.66-7.68,1.74-16.11,2.62-25.3,2.62-13.54,0-26.49-1.71-38.86-5.14-12.37-3.42-23.82-8.69-34.34-15.78l18.55-16.44c8.52,5.48,17.13,9.68,25.82,12.61,8.69,2.93,18.55,4.39,29.58,4.39,9.53,0,17.09-1.49,22.69-4.48,5.6-2.99,8.4-7.03,8.4-12.14,0-2.36-.54-4.48-1.63-6.35-1.09-1.87-3.1-3.58-6.03-5.14-2.93-1.56-6.96-3.02-12.06-4.39-5.11-1.37-11.77-2.8-19.98-4.3-9.39-1.62-17.72-3.42-25.01-5.42-7.29-1.99-13.4-4.48-18.35-7.47-4.94-2.99-8.72-6.6-11.31-10.83-2.6-4.23-3.89-9.4-3.89-15.5,0-5.73,1.49-10.93,4.47-15.6,2.98-4.67,7.09-8.69,12.31-12.05,5.22-3.36,11.5-5.95,18.84-7.75,7.34-1.8,15.35-2.71,24.04-2.71,12.87,0,24.44,1.4,34.72,4.2,10.28,2.8,19.93,6.88,28.96,12.23l-16.55,17.37c-8.02-4.36-15.96-7.69-23.82-9.99-7.86-2.3-15.79-3.46-23.82-3.46-9.02,0-16.04,1.53-21.06,4.58-5.01,3.05-7.52,6.76-7.52,11.11,0,2.61,.58,4.86,1.76,6.72Z"
      />
    </g>
    <g>
      <g id="Forma_1_copiar">
        <polyline class="cls-2" points="140.98 359.99 24.06 484.69 257.9 484.69 374.81 359.99 140.98 359.99" />
      </g>
      <g id="Forma_1_copiar-2">
        <polygon
          class="cls-3"
          points="140.98 110.58 24.06 235.29 257.9 235.29 257.9 235.29 374.81 110.58 140.98 110.58"
        />
      </g>
      <g id="Forma_1_copiar-3">
        <polyline
          class="cls-1"
          points="257.9 235.29 374.81 359.99 140.98 359.99 140.98 359.99 24.06 235.29 257.9 235.29"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconColor() {
      return this.color == 'black' ? 'cls-5' : 'cls-4'
    },
  },
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #44aee3;
}

.cls-2 {
  fill: #aa1d48;
}

.cls-3 {
  fill: #fdb515;
}

.cls-4 {
  fill: #fff;
}

.cls-5 {
  fill: #010101;
}
</style>

<template>
  <svg
    id="Grupo_709"
    data-name="Grupo 709"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173.164"
    height="124.04"
    viewBox="0 0 173.164 124.04"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_590" data-name="Retângulo 590" width="173.164" height="124.04" fill="#960b38" />
      </clipPath>
    </defs>
    <g id="Grupo_708" data-name="Grupo 708" clip-path="url(#clip-path)">
      <path
        id="Caminho_1589"
        data-name="Caminho 1589"
        d="M92.607,0H27.676c-.011,0-.022,0-.033,0a1.627,1.627,0,0,0-.287.029l-.02.005a1.578,1.578,0,0,0-.258.078c-.029.011-.057.025-.086.038a1.682,1.682,0,0,0-.182.1c-.027.016-.053.032-.078.049a1.692,1.692,0,0,0-.226.185L.484,26.494a1.623,1.623,0,0,0-.186.228c-.017.024-.031.049-.047.073a1.645,1.645,0,0,0-.1.189c-.012.027-.024.053-.035.079a1.687,1.687,0,0,0-.082.272v.005a1.671,1.671,0,0,0-.029.29c0,.011,0,.021,0,.033v92.19a4.191,4.191,0,0,0,4.186,4.186H92.607a4.191,4.191,0,0,0,4.186-4.186V4.187A4.191,4.191,0,0,0,92.607,0M26.023,5.644V26.01H5.647L24.966,6.7Zm67.464,114.21a.88.88,0,0,1-.879.879H4.186a.881.881,0,0,1-.879-.879V29.317H27.676a1.654,1.654,0,0,0,1.653-1.653V3.307H92.607a.881.881,0,0,1,.879.88Z"
        fill="#960b38"
      />
      <path
        id="Caminho_1590"
        data-name="Caminho 1590"
        d="M57.541,76.174h21.01a1.653,1.653,0,1,0,0-3.307H57.541a1.653,1.653,0,1,0,0,3.307"
        transform="translate(-19.649 -25.619)"
        fill="#960b38"
      />
      <path
        id="Caminho_1591"
        data-name="Caminho 1591"
        d="M85.479,107.1H34.2a1.653,1.653,0,1,0,0,3.307H85.479a1.653,1.653,0,0,0,0-3.307"
        transform="translate(-11.444 -37.654)"
        fill="#960b38"
      />
      <path
        id="Caminho_1592"
        data-name="Caminho 1592"
        d="M85.479,128.491H34.2a1.653,1.653,0,1,0,0,3.307H85.479a1.653,1.653,0,0,0,0-3.307"
        transform="translate(-11.444 -45.176)"
        fill="#960b38"
      />
      <path
        id="Caminho_1593"
        data-name="Caminho 1593"
        d="M219.395,79.1a10.738,10.738,0,0,0-10.59,9.077H163.658a1.653,1.653,0,0,0,0,3.307H208.8A10.724,10.724,0,1,0,219.395,79.1m0,18.154a7.423,7.423,0,1,1,7.422-7.423,7.432,7.432,0,0,1-7.422,7.423"
        transform="translate(-56.959 -27.811)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1594"
        data-name="Caminho 1594"
        d="M163.658,41.48h12.693A1.654,1.654,0,0,0,177.52,41L188.3,30.219a10.773,10.773,0,1,0-2.338-2.338L175.667,38.173H163.658a1.653,1.653,0,1,0,0,3.307m30.955-27.339a7.423,7.423,0,1,1-7.422,7.423,7.432,7.432,0,0,1,7.422-7.423"
        transform="translate(-56.959 -3.809)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1595"
        data-name="Caminho 1595"
        d="M194.613,142.386a10.659,10.659,0,0,0-6.316,2.076L177.52,133.685a1.654,1.654,0,0,0-1.169-.484H163.658a1.653,1.653,0,1,0,0,3.307h12.009L185.959,146.8a10.717,10.717,0,1,0,8.654-4.414m0,18.154a7.423,7.423,0,1,1,7.424-7.423,7.431,7.431,0,0,1-7.424,7.423"
        transform="translate(-56.959 -46.832)"
        fill="#ffb500"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    id="Grupo_592"
    data-name="Grupo 592"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="146.011"
    height="99.299"
    viewBox="0 0 146.011 99.299"
  >
    <g id="Grupo_591" data-name="Grupo 591">
      <path
        id="Caminho_1370"
        data-name="Caminho 1370"
        d="M106.926,131.761H83.341a1.524,1.524,0,0,0,0,3.047h23.585a1.524,1.524,0,0,0,0-3.047"
        transform="translate(-50.985 -82.055)"
        fill="#960b38"
      />
      <path
        id="Caminho_1371"
        data-name="Caminho 1371"
        d="M103.717,101.921H70.27a1.524,1.524,0,0,0,0,3.047h33.447a1.524,1.524,0,0,0,0-3.047"
        transform="translate(-42.845 -63.472)"
        fill="#960b38"
      />
      <path
        id="Caminho_1372"
        data-name="Caminho 1372"
        d="M83.341,75.128h23.585a1.524,1.524,0,0,0,0-3.047H83.341a1.524,1.524,0,0,0,0,3.047"
        transform="translate(-50.985 -44.889)"
        fill="#960b38"
      />
      <path
        id="Caminho_1373"
        data-name="Caminho 1373"
        d="M236.091,168.47a3.735,3.735,0,0,0,.247.55,2.82,2.82,0,0,0,.511.64,2.872,2.872,0,0,0,.685.484,3.968,3.968,0,0,0,.543.22,3.673,3.673,0,0,0,.613.1,2.932,2.932,0,0,0,.86-.064,1.508,1.508,0,0,0,.277-.079,3.616,3.616,0,0,0,.567-.254,2.725,2.725,0,0,0,.677-.553,3.315,3.315,0,0,0,.629-1.077,1.51,1.51,0,0,0,.072-.259,4.1,4.1,0,0,0,.077-.661,2.91,2.91,0,0,0-.124-.848,2.836,2.836,0,0,0-.359-.767,3.411,3.411,0,0,0-.818-.829,2.934,2.934,0,0,0-1.6-.516,1.533,1.533,0,0,0-.239.01,3.866,3.866,0,0,0-.615.1,2.882,2.882,0,0,0-1.4.871,3.4,3.4,0,0,0-.6,1.01,2.949,2.949,0,0,0-.164.834,2.757,2.757,0,0,0,.086.877,1.655,1.655,0,0,0,.071.218"
        transform="translate(-146.924 -102.476)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1374"
        data-name="Caminho 1374"
        d="M273.141,169.02a2.812,2.812,0,0,0,.511.64,2.878,2.878,0,0,0,.685.483,3.989,3.989,0,0,0,.543.22,3.685,3.685,0,0,0,.614.1,2.929,2.929,0,0,0,.859-.064,1.511,1.511,0,0,0,.277-.079,3.664,3.664,0,0,0,.567-.254,2.733,2.733,0,0,0,.677-.553,3.32,3.32,0,0,0,.63-1.077,1.506,1.506,0,0,0,.072-.26,4.047,4.047,0,0,0,.078-.661,2.924,2.924,0,0,0-.124-.848,2.828,2.828,0,0,0-.359-.767,3.4,3.4,0,0,0-.818-.829,2.931,2.931,0,0,0-1.6-.516,1.538,1.538,0,0,0-.239.01,3.9,3.9,0,0,0-.615.1,2.881,2.881,0,0,0-1.4.871,3.385,3.385,0,0,0-.6,1.01,2.964,2.964,0,0,0-.164.834,2.778,2.778,0,0,0,.086.877,1.579,1.579,0,0,0,.071.218,3.7,3.7,0,0,0,.247.55"
        transform="translate(-169.844 -102.476)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1375"
        data-name="Caminho 1375"
        d="M309.944,169.02a2.812,2.812,0,0,0,.511.64,2.879,2.879,0,0,0,.685.483,3.987,3.987,0,0,0,.543.22,3.685,3.685,0,0,0,.614.1,2.929,2.929,0,0,0,.859-.064,1.511,1.511,0,0,0,.277-.079,3.665,3.665,0,0,0,.567-.254,2.733,2.733,0,0,0,.677-.553,3.319,3.319,0,0,0,.63-1.077,1.5,1.5,0,0,0,.072-.26,4.014,4.014,0,0,0,.077-.661,2.91,2.91,0,0,0-.124-.848,2.829,2.829,0,0,0-.359-.767,3.4,3.4,0,0,0-.818-.829,2.931,2.931,0,0,0-1.6-.516,1.538,1.538,0,0,0-.239.01,3.9,3.9,0,0,0-.615.1,2.881,2.881,0,0,0-1.4.871,3.384,3.384,0,0,0-.6,1.01,2.961,2.961,0,0,0-.164.834,2.779,2.779,0,0,0,.086.877,1.6,1.6,0,0,0,.071.218,3.7,3.7,0,0,0,.247.55"
        transform="translate(-192.763 -102.476)"
        fill="#ffb500"
      />
      <path
        id="Caminho_1376"
        data-name="Caminho 1376"
        d="M81.985,59.515a35.783,35.783,0,0,0,1.1-39.2C66.978-5.694,25.539-6.773,7.264,17.331-3.6,31.66-1.934,50.909,9.964,63.99l-2.557,11c-.311,1.338-.9,2.844,0,4.073.944,1.3,2.255.936,3.537.567,4.469-1.286,8.9-2.746,13.339-4.118a8.979,8.979,0,0,1,1.98-.611c1.016-.038,2.6.972,3.808,1.332a51.494,51.494,0,0,0,14.083,2.026c14.687-.139,29.288-6.489,37.836-18.74M44.149,75.208a49.142,49.142,0,0,1-10-1.071c-1.284-.282-2.554-.622-3.809-1.012-1.175-.364-2.833-1.331-4.06-1.354-1.658-.031-3.771,1.094-5.314,1.57l-9.069,2.8c-.286.088-1.091.217-1.723.4,1.022-2.679,1.356-5.853,2.005-8.645.383-1.646,1.423-3.867.522-5.415-1.326-2.279-3.613-4.138-5.024-6.458a33.315,33.315,0,0,1-3.64-8.311,31.882,31.882,0,0,1-.524-15.131C7.618,13.163,27.708,2.1,46.5,3,65.03,3.88,83.89,16.957,85.355,36.6c1.713,22.967-20.108,38.41-41.206,38.61"
        transform="translate(0 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1377"
        data-name="Caminho 1377"
        d="M245.9,115.5c11.9-13.083,13.558-32.325,2.7-46.654-10.626-14.018-28.976-19.471-45.92-16.618-1.929.325-1.111,3.262.81,2.938,14.261-2.4,29.61,1.582,39.8,12.141,10.045,10.406,12.765,26.091,5.648,38.932a36,36,0,0,1-2.723,4.212c-.847,1.124-2.933,2.7-3.373,4.033-.334,1.016.244,2.37.477,3.374l1.465,6.3c.124.531.323,2.564.665,3.867-.422-.1-.859-.177-1.036-.231l-6.01-1.855c-2.666-.823-6.384-2.793-9.229-2.6-1.98.135-4.355,1.559-6.361,2.054a45.592,45.592,0,0,1-8.724,1.271,47.108,47.108,0,0,1-13.185-1.192A42.791,42.791,0,0,1,178.956,112.6c-1.318-1.457-3.467.7-2.154,2.154,12.512,13.831,33.06,18.38,50.585,12.481,1.132-.381,1.451-.825,2.444-.753a14.782,14.782,0,0,1,2.93.9l8.059,2.488c2.517.777,8.668,3.659,8.048-1.585-.5-4.259-1.937-8.59-2.969-12.79"
        transform="translate(-109.849 -32.071)"
        fill="#ffb500"
      />
    </g>
  </svg>
</template>

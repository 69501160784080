<template>
  <svg
    viewBox="0 0 50.91 56"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <g class="layer">
      <title>Layer 1</title>
      <g id="Grupo_480" data-name="Grupo 480">
        <g id="Grupo_479" data-name="Grupo 479">
          <path
            id="Caminho_931"
            d="m37.05,7.3a1.02,1.02 0 0 0 -1.44,0l-4.08,4.07a1.02,1.02 0 1 0 1.44,1.44l2.34,-2.33l0,12.81a5.1,5.1 0 0 1 -5.09,5.09l-5.09,0a7.14,7.14 0 0 0 -7.13,7.13l0,8.14a1.02,1.02 0 0 0 2.04,0l0,-8.14a5.1,5.1 0 0 1 5.09,-5.09l5.09,0a7.14,7.14 0 0 0 7.13,-7.13l0,-12.81l2.33,2.33a1.02,1.02 0 0 0 1.44,-1.44l-4.07,-4.07z"
            data-name="Caminho 931"
            :fill="color"
            transform="translate(0.327 0.127)"
          />
          <path
            id="Caminho_932"
            d="m25.18,18.09a5.09,5.09 0 1 0 -5.09,5.09a5.1,5.1 0 0 0 5.09,-5.09m-8.14,0a3.06,3.06 0 1 1 3.05,3.06a3.06,3.06 0 0 1 -3.05,-3.06"
            data-name="Caminho 932"
            :fill="color"
            transform="translate(0.273 0.236)"
          />
          <path
            id="Caminho_933"
            d="m49.89,0l-42.76,0a1.02,1.02 0 0 0 -1.02,1.02l0,46.83l-5.09,0a1.02,1.02 0 0 0 -1.02,1.02a7.14,7.14 0 0 0 7.13,7.13l35.63,0a1,1 0 0 0 0.23,-0.05a7.22,7.22 0 0 0 0.79,0.05a7.14,7.14 0 0 0 7.13,-7.13l0,-47.85a1.02,1.02 0 0 0 -1.02,-1.02m-42.76,53.96a5.1,5.1 0 0 1 -4.99,-4.07l34.6,0a7.11,7.11 0 0 0 2.06,4.07l-31.67,0zm36.65,0a5.1,5.1 0 0 1 -5.09,-5.09a1.02,1.02 0 0 0 -1.02,-1.02l-29.53,0l0,-45.81l40.73,0l0,46.83a5.1,5.1 0 0 1 -5.09,5.09"
            data-name="Caminho 933"
            :fill="color"
          />
          <path
            id="Caminho_934"
            d="m40.86,34.47a1.02,1.02 0 0 0 -1.44,0l-2.75,2.76l-2.76,-2.76a1.02,1.02 0 1 0 -1.44,1.44l2.76,2.76l-2.76,2.75a1.02,1.02 0 1 0 1.44,1.44l2.76,-2.75l2.75,2.75a1.02,1.02 0 0 0 1.44,-1.44l-2.75,-2.75l2.75,-2.76a1.02,1.02 0 0 0 0,-1.44"
            data-name="Caminho 934"
            :fill="color"
            transform="translate(0.585 0.621)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>

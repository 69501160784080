<template>
  <svg
    id="Grupo_477"
    data-name="Grupo 477"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="69.017"
    height="58.832"
    viewBox="0 0 69.017 58.832"
  >
    <g id="Grupo_477-2" data-name="Grupo 477">
      <path
        id="Caminho_1059"
        data-name="Caminho 1059"
        d="M75.4,112.929l-1.533,1.533A.806.806,0,0,0,75,115.6l1.533-1.533,1.533,1.533a.806.806,0,0,0,1.139-1.139l-1.533-1.533,1.533-1.533a.806.806,0,0,0-1.139-1.139l-1.533,1.533L75,110.257a.806.806,0,0,0-1.139,1.139l1.533,1.533"
        transform="translate(-57.153 -85.404)"
        fill="#960b38"
      />
      <path
        id="Caminho_1060"
        data-name="Caminho 1060"
        d="M140.9,188.2l1.533-1.533a.806.806,0,0,0-1.139-1.139l-1.533,1.533-1.533-1.533a.806.806,0,0,0-1.139,1.139l1.533,1.533-1.533,1.533a.806.806,0,0,0,1.139,1.139l1.533-1.533,1.533,1.533a.806.806,0,0,0,1.139-1.139L140.9,188.2"
        transform="translate(-106.233 -143.838)"
        fill="#960b38"
      />
      <path
        id="Caminho_1061"
        data-name="Caminho 1061"
        d="M153.277,123.394a3.778,3.778,0,1,0,0,.459,1.222,1.222,0,0,0,0-.459m-5.923.229c0-2.814,4.194-2.769,4.334,0-.14,2.766-4.334,2.813-4.334,0"
        transform="translate(-113.135 -93.031)"
        fill="#960b38"
      />
      <path
        id="Caminho_1062"
        data-name="Caminho 1062"
        d="M80.717,188.4a3.778,3.778,0,1,0,0,.459,1.222,1.222,0,0,0,0-.459m-5.923.229c0-2.814,4.194-2.769,4.334,0-.14,2.766-4.334,2.813-4.334,0"
        transform="translate(-56.809 -143.495)"
        fill="#960b38"
      />
      <path
        id="Caminho_1063"
        data-name="Caminho 1063"
        d="M78.553,94.4l1.554,1.265c.8.649,1.944-.484,1.139-1.139l-2.929-2.385-.008-.006a.754.754,0,0,0-.168-.11.79.79,0,0,0-.779,0,.755.755,0,0,0-.177.115l-.007,0L74.249,94.53c-.8.655.342,1.789,1.139,1.139L76.943,94.4q0,3.98,0,7.959a5.6,5.6,0,0,1-3.7,5.583,16.122,16.122,0,0,1-4.916.281H62.942a.806.806,0,0,0,0,1.611H67.89a21.211,21.211,0,0,0,5.494-.253,7.122,7.122,0,0,0,5.166-6.632c.05-1.544,0-3.1,0-4.642Z"
        transform="translate(-48.255 -71.357)"
        fill="#960b38"
      />
      <path
        id="Caminho_1064"
        data-name="Caminho 1064"
        d="M45.494,62.439a10.531,10.531,0,0,0-.124-2.952c-.573-1.859-2.328-1.868-3.89-1.868H12.739c-1.4,0-2.991-.193-3.9,1.163-.634.945-.47,2.2-.47,3.283V96.694H.806A.815.815,0,0,0,0,97.5c.073,2.813,1.807,6.053,4.99,6.053H40.5a.88.88,0,0,0,.185-.019,4.254,4.254,0,0,0,1.79-.472c2.222-1.145,3.009-3.662,3.015-6,.01-3.866,0-7.732,0-11.6V62.439Zm-36.04,39.5H4.99c-1.955,0-3.017-1.888-3.3-3.636H35.575a6.585,6.585,0,0,0,1.543,3.636ZM43.883,63.879V97.034c0,2.013-.913,4.92-3.321,4.913-.02,0-.037-.006-.058-.006h-.119c-.052,0-.1-.005-.156-.011-2.109-.23-3.056-2.587-3.1-4.431a.819.819,0,0,0-.805-.805H9.979V61.5c0-.951-.193-2.154,1.135-2.269,1.069-.093,2.188,0,3.26,0H41.884c.973,0,1.927-.018,2,1.271.063,1.118,0,2.259,0,3.378"
        transform="translate(0 -44.72)"
        fill="#960b38"
      />
      <path
        id="Caminho_1065"
        data-name="Caminho 1065"
        d="M205.719,18.933a6.052,6.052,0,0,0-.75-3.058,15.929,15.929,0,0,0-2.51-2.479c-.952-.891-1.927-1.767-2.849-2.69-.781-.782-1.108-1.889-1.935-2.621a23.439,23.439,0,0,0-2.29-1.687c.719-1.148,1.443-2.3,2.11-3.477A1.878,1.878,0,0,0,197.482.5a2.4,2.4,0,0,0-2.7-.174c-1.357.636-2.671,1.375-4.024,2.023a19.708,19.708,0,0,1-3.721.984,22.584,22.584,0,0,0-3.668,1.314,24.879,24.879,0,0,0-6.713,4.269c-.762.706.379,1.843,1.139,1.139a23.1,23.1,0,0,1,10.949-5.462,9.583,9.583,0,0,0,2.459-.677q1.476-.7,2.934-1.442c.283-.145,1.326-.879,1.607-.816.834.187-.143,1.286-.321,1.582q-.9,1.492-1.823,2.971a.825.825,0,0,0,.289,1.1c.777.566,1.566,1.119,2.34,1.691a5.984,5.984,0,0,1,1.391,1.857,23.3,23.3,0,0,0,3.857,3.825,19.742,19.742,0,0,1,2.019,1.9,4.853,4.853,0,0,1,.645,2.787c.04.488.457,1.919-.043,2.235s-2.374-.519-2.847-.655a.816.816,0,0,0-.91,1.183c.078.184.638,1.185.56,1.32-.174.3-1.188.192-1.471.162-1.5-.155-1.694-1.576-2.645-2.526a3,3,0,0,0-2.039-.735c-1.24-.1-2.48-.187-3.72-.286-.783-.063-1.627-.249-2.221.387a3.107,3.107,0,0,0-.8,2.227c.178.833,1.287,1.659,1.828,2.238,1.922,2.053,4.2,3.727,6.1,5.791A6.8,6.8,0,0,1,198,36.249c-.2,1.305-.5,2.6-.654,3.921A18.368,18.368,0,0,0,197.5,46a9.272,9.272,0,0,0,1.7,3.736H186.718a.806.806,0,0,0,0,1.611h15.895a2.455,2.455,0,0,1-1.971,2.939,7.087,7.087,0,0,1-1.07.025H185.642a.806.806,0,0,0,0,1.611h12.7a14.024,14.024,0,0,0,2.731-.082,4.064,4.064,0,0,0,2.8-5.7.794.794,0,0,0-.7-.4h-1.861a8.321,8.321,0,0,1-2.256-4.164,16.436,16.436,0,0,1-.162-4.811c.27-2.85,1.334-5.423.153-8.247s-3.83-4.61-6.012-6.624c-.668-.617-1.284-1.283-1.9-1.946a13.9,13.9,0,0,1-1.476-1.592,4.844,4.844,0,0,1,.288-.759,5.494,5.494,0,0,1,1.31.106c1.021.081,2.042.154,3.063.236a2.225,2.225,0,0,1,1.338.3,8,8,0,0,1,1.049,1.507c.374.542.689,1.144,1.339,1.385a6.428,6.428,0,0,0,2.2.1,2.647,2.647,0,0,0,1.853-.4,1.589,1.589,0,0,0,.38-1.8c1.269.36,2.6.49,3.279-.883.447-.9.051-2.254-.027-3.213"
        transform="translate(-136.939 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_1066"
        data-name="Caminho 1066"
        d="M157.525,10.881A25.277,25.277,0,0,1,167.1,4.094c.946-.419.127-1.807-.813-1.391a26.269,26.269,0,0,0-9.9,7.039c-.655.794.478,1.939,1.139,1.139"
        transform="translate(-121.244 -2.039)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

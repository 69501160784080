<template>
  <svg
    viewBox="0 0 57.69 56"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <g class="layer">
      <title>Layer 1</title>
      <g id="Grupo_482" data-name="Grupo 482">
        <g id="Grupo_481" data-name="Grupo 481">
          <path
            id="Caminho_935"
            d="m56.19,48.63l-15.37,-12.8a22.3,22.3 0 0 0 4.32,-13.26a22.54,22.54 0 0 0 -0.88,-6.2l5.92,-5.92l-0.5,3.54a1.02,1.02 0 0 0 0.86,1.15a1,1 0 0 0 0.15,0.01a1.02,1.02 0 0 0 1,-0.87l0.94,-6.56c0,-0.03 0,-0.05 0,-0.07a1.14,1.14 0 0 0 0,-0.15c0,-0.02 0.01,-0.05 0,-0.07a0.5,0.5 0 0 0 -0.02,-0.06a1.01,1.01 0 0 0 -0.03,-0.12a1.07,1.07 0 0 0 -0.06,-0.12a0.87,0.87 0 0 0 -0.06,-0.12a0.9,0.9 0 0 0 -0.08,-0.1c-0.01,-0.02 -0.02,-0.04 -0.04,-0.06s-0.04,-0.02 -0.05,-0.04a0.93,0.93 0 0 0 -0.1,-0.08a0.99,0.99 0 0 0 -0.11,-0.06a0.93,0.93 0 0 0 -0.13,-0.05a0.95,0.95 0 0 0 -0.13,-0.04c-0.01,0 -0.03,-0.02 -0.05,-0.02s-0.05,0.01 -0.07,0a1.13,1.13 0 0 0 -0.14,0c-0.03,0 -0.05,-0.01 -0.08,0l-6.56,0.94a1.02,1.02 0 0 0 0.15,2.03a0.99,0.99 0 0 0 0.14,-0.01l3.54,-0.51l-6.34,6.34l-9.99,10l-8.66,-8.65a1.02,1.02 0 0 0 -1.44,0l-11.71,11.71a1.02,1.02 0 1 0 1.44,1.44l10.99,-10.99l8.65,8.65a1.02,1.02 0 0 0 1.44,0l9.47,-9.47a20.59,20.59 0 0 1 0.51,4.53a20.32,20.32 0 0 1 -4.48,12.79a20.88,20.88 0 0 1 -2.76,2.86a20.54,20.54 0 1 1 4.66,-25.57a1.02,1.02 0 1 0 1.79,-0.99a22.59,22.59 0 1 0 -5.88,28.69l14.11,14.4a4.14,4.14 0 0 0 2.95,1.25l0.02,0a4.17,4.17 0 0 0 4.16,-4.36a4.18,4.18 0 0 0 -1.49,-3.01m-1.16,4.71a2.11,2.11 0 0 1 -1.51,0.62l-0.01,0a2.12,2.12 0 0 1 -1.51,-0.64l-14.02,-14.3q0.82,-0.76 1.56,-1.61l15.34,12.79a2.13,2.13 0 0 1 0.15,3.14"
            data-name="Caminho 935"
            :fill="color"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>

<template>
  <svg
    viewBox="0 0 52.089999999999996 64"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
    :height="height"
  >
    <g class="layer">
      <title>Layer 1</title>
      <g id="Grupo_478" data-name="Grupo 478">
        <g id="Grupo_477" data-name="Grupo 477">
          <path
            id="Caminho_928"
            d="m27.69,18a6.19,6.19 0 1 0 6.2,6.19a6.2,6.2 0 0 0 -6.2,-6.19m0,10.32a4.13,4.13 0 1 1 4.13,-4.13a4.13,4.13 0 0 1 -4.13,4.13"
            data-name="Caminho 928"
            :fill="color"
            transform="translate(0.694 0.581)"
          />
          <path
            id="Caminho_929"
            d="m40.49,32.62l-2.33,-3.11a11.5,11.5 0 0 0 0.57,-1.39l3.85,-0.55a1.03,1.03 0 0 0 0.89,-1.02l0,-4.13a1.03,1.03 0 0 0 -0.89,-1.02l-3.85,-0.55a11.48,11.48 0 0 0 -0.57,-1.4l2.33,-3.11a1.03,1.03 0 0 0 -0.1,-1.34l-2.92,-2.92a1.03,1.03 0 0 0 -1.35,-0.1l-3.11,2.33a11.49,11.49 0 0 0 -1.39,-0.57l-0.55,-3.85a1.03,1.03 0 0 0 -1.02,-0.89l-4.13,0a1.03,1.03 0 0 0 -1.02,0.89l-0.55,3.85a11.5,11.5 0 0 0 -1.4,0.57l-3.11,-2.33a1.03,1.03 0 0 0 -1.34,0.1l-2.92,2.91a1.03,1.03 0 0 0 -0.1,1.35l2.33,3.11a11.43,11.43 0 0 0 -0.58,1.4l-3.85,0.55a1.03,1.03 0 0 0 -0.88,1.02l0,4.13a1.03,1.03 0 0 0 0.88,1.02l3.85,0.55a11.5,11.5 0 0 0 0.58,1.39l-2.33,3.11a1.03,1.03 0 0 0 0.1,1.35l2.91,2.92a1.03,1.03 0 0 0 1.35,0.1l3.11,-2.33a11.34,11.34 0 0 0 1.4,0.58l0.55,3.84a1.03,1.03 0 0 0 1.02,0.89l4.13,0a1.03,1.03 0 0 0 1.02,-0.89l0.55,-3.85a11.26,11.26 0 0 0 1.39,-0.57l3.11,2.33a1.03,1.03 0 0 0 1.35,-0.1l2.92,-2.92a1.03,1.03 0 0 0 0.1,-1.35m-3.84,2.18l-2.92,-2.19a1.03,1.03 0 0 0 -1.14,-0.07a9.21,9.21 0 0 1 -2.17,0.9a1.03,1.03 0 0 0 -0.75,0.85l-0.52,3.61l-2.34,0l-0.51,-3.61a1.03,1.03 0 0 0 -0.75,-0.85a9.24,9.24 0 0 1 -2.18,-0.9a1.03,1.03 0 0 0 -1.13,0.07l-2.92,2.19l-1.65,-1.66l2.19,-2.91a1.03,1.03 0 0 0 0.07,-1.14a9.22,9.22 0 0 1 -0.9,-2.17a1.03,1.03 0 0 0 -0.86,-0.75l-3.61,-0.52l0,-2.34l3.61,-0.51a1.03,1.03 0 0 0 0.86,-0.75a9.17,9.17 0 0 1 0.9,-2.18a1.03,1.03 0 0 0 -0.07,-1.13l-2.19,-2.92l1.65,-1.65l2.92,2.19a1.03,1.03 0 0 0 1.13,0.07a9.27,9.27 0 0 1 2.18,-0.9a1.03,1.03 0 0 0 0.75,-0.85l0.51,-3.62l2.34,0l0.52,3.62a1.03,1.03 0 0 0 0.75,0.85a9.27,9.27 0 0 1 2.17,0.9a1.03,1.03 0 0 0 1.13,-0.07l2.92,-2.19l1.66,1.65l-2.19,2.92a1.03,1.03 0 0 0 -0.07,1.13a9.18,9.18 0 0 1 0.9,2.18a1.03,1.03 0 0 0 0.85,0.75l3.61,0.51l0,2.34l-3.61,0.52a1.03,1.03 0 0 0 -0.85,0.75a9.22,9.22 0 0 1 -0.9,2.17a1.03,1.03 0 0 0 0.07,1.14l2.19,2.92l-1.65,1.65z"
            data-name="Caminho 929"
            :fill="color"
            transform="translate(0.403 0.29)"
          />
          <path
            id="Caminho_930"
            d="m46.26,7.72c-4.63,-5.05 -11.57,-7.72 -20.08,-7.72c-14.68,0 -21.26,10.11 -21.26,20.13l0,5.43l-4.48,8.98a4.13,4.13 0 0 0 2.38,5.76l2.18,0.73a64.07,64.07 0 0 0 1.93,10.15a5.68,5.68 0 0 0 6.38,3.94l2.55,-0.43l0,8.28a1.03,1.03 0 1 0 2.06,0l0,-8.52a1.02,1.02 0 0 0 -0.02,-0.11l5.5,-0.93a1.03,1.03 0 0 0 -0.35,-2.04l-10.09,1.71a3.59,3.59 0 0 1 -4.05,-2.5a65.47,65.47 0 0 1 -1.93,-10.42a1.03,1.03 0 0 0 -0.7,-0.88l-2.8,-0.94a2.07,2.07 0 0 1 -1.2,-2.88l4.6,-9.19a1.04,1.04 0 0 0 0.11,-0.46l0,-5.68c0,-8.99 5.93,-18.07 19.19,-18.07c7.91,0 14.34,2.44 18.56,7.05c3.9,4.25 5.74,10.15 5.19,16.6c-0.65,7.52 -3.26,11.94 -5.78,16.22c-2.34,3.97 -4.55,7.72 -4.55,13.29l0,7.74a1.03,1.03 0 1 0 2.06,0l0,-7.73c0,-5 1.98,-8.36 4.27,-12.25c2.51,-4.27 5.36,-9.1 6.05,-17.09c0.61,-7.04 -1.42,-13.5 -5.71,-18.17"
            data-name="Caminho 930"
            :fill="color"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>

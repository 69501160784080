<template>
  <svg
    id="Grupo_442"
    data-name="Grupo 442"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="111.738"
    height="106.683"
    viewBox="0 0 111.738 106.683"
  >
    <g id="Grupo_441" data-name="Grupo 441">
      <path
        id="Caminho_988"
        data-name="Caminho 988"
        d="M46.585,27.2h.038A10.97,10.97,0,0,0,46.587,5.26h-.038A10.97,10.97,0,0,0,46.585,27.2M46.558,8.173a8.056,8.056,0,1,1,.055,16.112h-.028a8.056,8.056,0,0,1-.027-16.112"
        transform="translate(-23.504 -3.471)"
        fill="#960b38"
      />
      <path
        id="Caminho_989"
        data-name="Caminho 989"
        d="M63.11,85.828c-1.123-3.67-5.028-3.67-9.163-3.67-7.825,0-10.584.021-19.735.223l-2,.046c-2.394.056-4.655.109-6.62.109-7.391,0-11.688.306-15.326,1.093C3.357,85.122,0,89.949,0,98.386c0,.016,0,.032,0,.049l.782,23.423a6.6,6.6,0,0,0,6.434,6.719,5.979,5.979,0,0,0,3.428-1.057l.534,29.924A3.538,3.538,0,0,0,14.7,160.9h6.557a3.5,3.5,0,0,0,2.064-.67,3.5,3.5,0,0,0,2.064.67h6.524a3.537,3.537,0,0,0,3.52-3.46l1.053-61.173c4.187-.224,16.651-1.012,21.067-2.629,5.279-1.933,6.3-5.416,5.563-7.811m-28.127,7.6a1.457,1.457,0,0,0-1.392,1.43l-1.076,62.535a.611.611,0,0,1-.607.6H25.384a.609.609,0,0,1-.608-.608v-37.3a1.457,1.457,0,1,0-2.913,0v37.3a.608.608,0,0,1-.607.608H14.7a.611.611,0,0,1-.608-.6l-1-56.31a1.457,1.457,0,1,0-2.913.052l.371,20.832a1.449,1.449,0,0,0-.149.643,3.207,3.207,0,0,1-3.179,3.055,3.745,3.745,0,0,1-3.52-3.831c0-.016,0-.032,0-.049L2.913,98.362c.008-9.008,4.26-11.084,7.965-11.884,3.419-.739,7.544-1.027,14.711-1.027,2,0,4.277-.053,6.688-.11l2-.046c9.123-.2,11.873-.223,19.671-.223,4.092,0,5.932.152,6.377,1.609.482,1.575-.966,3.193-3.779,4.223-4.807,1.761-21.4,2.515-21.562,2.523"
        transform="translate(0 -54.219)"
        fill="#960b38"
      />
      <path
        id="Caminho_990"
        data-name="Caminho 990"
        d="M114.61,2.913h64.86V59h-59.6a1.457,1.457,0,1,0,0,2.913h61.054a1.457,1.457,0,0,0,1.457-1.457v-59A1.457,1.457,0,0,0,180.926,0H114.61a1.457,1.457,0,1,0,0,2.913"
        transform="translate(-74.673)"
        fill="#1f214a"
      />
      <path
        id="Caminho_991"
        data-name="Caminho 991"
        d="M155.9,210.13a9.331,9.331,0,0,0-.031,18.662h.033a9.331,9.331,0,0,0,.031-18.662Zm6.418,9.353a6.417,6.417,0,0,1-6.417,6.4h-.022a6.417,6.417,0,0,1,.022-12.835h.022a6.424,6.424,0,0,1,6.4,6.439"
        transform="translate(-96.729 -138.671)"
        fill="#1f214a"
      />
      <path
        id="Caminho_992"
        data-name="Caminho 992"
        d="M152.66,276.84c-2.808-1.4-5.979-2.013-10.313-2-4.334-.014-7.516.6-10.324,2-2.317,1.154-5.075,3.181-5.854,8.663a1.457,1.457,0,0,0,2.884.41c.612-4.308,2.524-5.6,4.269-6.464a19.5,19.5,0,0,1,9.025-1.694,19.263,19.263,0,0,1,9.015,1.694c1.744.869,3.656,2.156,4.268,6.464a1.457,1.457,0,0,0,1.441,1.252,1.477,1.477,0,0,0,.207-.015,1.457,1.457,0,0,0,1.238-1.647c-.779-5.482-3.538-7.509-5.854-8.663"
        transform="translate(-83.253 -181.375)"
        fill="#1f214a"
      />
      <path
        id="Caminho_993"
        data-name="Caminho 993"
        d="M265.735,285.5c-.778-5.482-3.537-7.509-5.854-8.663-2.808-1.4-5.99-2-10.314-2a22.173,22.173,0,0,0-10.323,2c-2.317,1.154-5.076,3.181-5.854,8.663a1.457,1.457,0,0,0,1.237,1.647,1.48,1.48,0,0,0,.207.015,1.457,1.457,0,0,0,1.441-1.252c.612-4.308,2.524-5.6,4.268-6.464a19.5,19.5,0,0,1,9.025-1.694c3.871-.009,6.635.508,9.015,1.694,1.745.869,3.657,2.156,4.269,6.464a1.457,1.457,0,1,0,2.884-.409"
        transform="translate(-154.011 -181.375)"
        fill="#1f214a"
      />
      <path
        id="Caminho_994"
        data-name="Caminho 994"
        d="M262.866,210.13a9.331,9.331,0,0,0-.031,18.662h.033a9.331,9.331,0,0,0,.031-18.662Zm6.418,9.353a6.417,6.417,0,0,1-6.417,6.4h-.022a6.417,6.417,0,0,1,.022-12.835h.022a6.424,6.424,0,0,1,6.4,6.439"
        transform="translate(-167.316 -138.671)"
        fill="#1f214a"
      />
      <path
        id="Caminho_995"
        data-name="Caminho 995"
        d="M199.626,85.5a1.463,1.463,0,0,0,.184-.012l21.409-2.7a1.457,1.457,0,1,0-.365-2.891l-21.409,2.7a1.457,1.457,0,0,0,.18,2.9"
        transform="translate(-130.779 -52.723)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="83.787"
    height="83.801"
    viewBox="0 0 83.787 83.801"
  >
    <g id="Grupo_515" data-name="Grupo 515" transform="translate(0 0)">
      <g id="Grupo_513" data-name="Grupo 513" transform="translate(0 0)">
        <path
          id="Caminho_1125"
          data-name="Caminho 1125"
          d="M64.55,14.651a1.043,1.043,0,0,0-.244-.358,1.352,1.352,0,0,0-.347-.217A35.107,35.107,0,0,0,51.243,6,34.52,34.52,0,0,0,39.564,3.982a1.035,1.035,0,0,0-.277-.051,1.258,1.258,0,0,0-.249.056A35.829,35.829,0,0,0,14.385,14.213a1.067,1.067,0,0,0-.119.08c-.022.027-.032.06-.053.086a33.914,33.914,0,0,0-2.3,2.522A35.726,35.726,0,0,0,3.99,38.851a1.065,1.065,0,0,0-.086.463,1.382,1.382,0,0,0,.075.33A34.587,34.587,0,0,0,14.16,64.177a.921.921,0,0,0,.106.156,1.38,1.38,0,0,0,.181.114A35.931,35.931,0,0,0,38.979,74.639a1.026,1.026,0,0,0,.308.058,1.226,1.226,0,0,0,.245-.056A34.114,34.114,0,0,0,63.867,64.615a1.082,1.082,0,0,0,.439-.282,1.3,1.3,0,0,0,.222-.35A36.066,36.066,0,0,0,74.67,39.314,35.96,35.96,0,0,0,64.55,14.651m-24.02,57.5V67.133a1.244,1.244,0,1,0-2.487,0v5a33.372,33.372,0,0,1-21.1-8.724l2.991-2.991c.186-.186.37-.371.557-.557a1.243,1.243,0,0,0-1.759-1.757q-1.778,1.776-3.556,3.555c-.393-.419-.791-.834-1.162-1.277A32.713,32.713,0,0,1,6.493,40.557h4.972a1.244,1.244,0,1,0,0-2.487H6.49A32.7,32.7,0,0,1,11.066,22.4a32.13,32.13,0,0,1,4.123-5.425l2.989,2.991.557.557A1.244,1.244,0,1,0,20.5,18.763q-1.771-1.771-3.54-3.538A33.456,33.456,0,0,1,38.043,6.452v5.042a1.244,1.244,0,0,0,2.487,0V6.444a31.784,31.784,0,0,1,5.238.608,32.531,32.531,0,0,1,15.809,8.212q-1.47,1.472-2.941,2.941l-.557.557a1.244,1.244,0,1,0,1.759,1.759l3.492-3.493A33.38,33.38,0,0,1,72.119,38.07H67.107a1.244,1.244,0,1,0,0,2.487h5.019a33.594,33.594,0,0,1-8.754,21.082q-1.487-1.487-2.976-2.976-.279-.279-.557-.555a1.243,1.243,0,0,0-1.759,1.757q1.773,1.773,3.545,3.545a32.056,32.056,0,0,1-11.042,6.814A32.627,32.627,0,0,1,40.53,72.149"
          transform="translate(2.568 2.585)"
          fill="#960b38"
        />
        <path
          id="Caminho_1126"
          data-name="Caminho 1126"
          d="M55.983,2.408A41.947,41.947,0,0,0,6.017,63.683,42.365,42.365,0,0,0,50.112,83.017C69.567,79.175,83.65,61.6,83.787,41.9a42.406,42.406,0,0,0-27.8-39.49m-.662,76.583a39.863,39.863,0,0,1-43.9-11.986,39.455,39.455,0,0,1,38.3-63.77A39.862,39.862,0,0,1,81.3,41.9,39.846,39.846,0,0,1,55.321,78.991"
          transform="translate(0 -0.001)"
          fill="#960b38"
        />
        <path
          id="Caminho_1127"
          data-name="Caminho 1127"
          d="M35.519,38.684,26.99,33.231V13.343a1.244,1.244,0,0,0-2.487,0V27c0,2.16-.051,4.327,0,6.488a1.716,1.716,0,0,0,.726,1.568c.761.569,1.627,1.04,2.427,1.552l6.607,4.225c1.353.865,2.6-1.287,1.255-2.147"
          transform="translate(16.108 7.989)"
          fill="#960b38"
        />
      </g>
    </g>
  </svg>
</template>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="111.91"
    height="118.024"
    viewBox="0 0 111.91 118.024"
  >
    <defs>
      <clipPath id="clip-path">
        <rect id="Retângulo_435" data-name="Retângulo 435" width="111.91" height="118.024" fill="#fb9335" />
      </clipPath>
    </defs>
    <g id="Grupo_423" data-name="Grupo 423" clip-path="url(#clip-path)">
      <path
        id="Caminho_955"
        data-name="Caminho 955"
        d="M161.44,105.926a5.551,5.551,0,0,0-4.626-5.277,5.3,5.3,0,0,0-5.991,3.192c-.04.094-.079.19-.114.283l-6.46,16.965a4.763,4.763,0,0,0-4.632.643L128.2,130.447a2.382,2.382,0,0,1-1.968.432l-.862-.192a11.219,11.219,0,0,0-13.647,10.28L111.122,154h-.1a3.807,3.807,0,0,0-2.782,1.125,3.852,3.852,0,0,0-1.15,2.773l.02,5.346a.941.941,0,0,0,.94.937h0a.94.94,0,0,0,.937-.944l-.02-5.346A2,2,0,0,1,111,155.879l.852.011a.966.966,0,0,0,.125.018h.044a.9.9,0,0,0,.157-.016l21.133.266a.938.938,0,0,0,.721.341.968.968,0,0,0,.111-.006.932.932,0,0,0,.285-.085,2,2,0,0,1,1.062,1.747l.021,5.443a.94.94,0,0,0,.94.937h0a.94.94,0,0,0,.937-.944l-.021-5.443a3.9,3.9,0,0,0-2.462-3.592,9.45,9.45,0,0,1,4.137-8.056l10.438-7.114a10.594,10.594,0,0,0,4.183-5.738l7.4-25.059a7.258,7.258,0,0,0,.375-2.665m-2.169,2.1-7.411,25.092a8.706,8.706,0,0,1-3.438,4.716l-10.437,7.114a11.323,11.323,0,0,0-4.963,9.328L113,154.023l.6-12.955a9.338,9.338,0,0,1,11.358-8.546l.863.192a4.25,4.25,0,0,0,3.519-.773l11.412-8.715a2.885,2.885,0,1,1,3.5,4.586l-12.643,9.656a.94.94,0,1,0,1.142,1.495l12.643-9.655a4.736,4.736,0,0,0,.459-7.17l6.607-17.349c.026-.07.054-.139.082-.2a3.436,3.436,0,0,1,3.925-2.085,3.66,3.66,0,0,1,3.085,3.514,5.444,5.444,0,0,1-.29,2.011"
        transform="translate(-49.537 -46.512)"
        fill="#960b38"
      />
      <path
        id="Caminho_956"
        data-name="Caminho 956"
        d="M50.477,154h-.051l-.1,0-.607-13.046a11.22,11.22,0,0,0-13.647-10.267l-.862.193a2.382,2.382,0,0,1-1.967-.432l-11.412-8.715a4.74,4.74,0,0,0-4.633-.646l-6.46-16.962c-.037-.1-.075-.193-.116-.285a5.294,5.294,0,0,0-5.989-3.189,5.551,5.551,0,0,0-4.627,5.276,7.212,7.212,0,0,0,.364,2.631l7.411,25.092a10.592,10.592,0,0,0,4.183,5.738L22.4,146.5a9.45,9.45,0,0,1,4.137,8.055,3.9,3.9,0,0,0-2.462,3.592l-.021,5.443a.941.941,0,0,0,.937.944h0a.94.94,0,0,0,.94-.937l.021-5.443a2,2,0,0,1,1.062-1.747.922.922,0,0,0,.285.085.863.863,0,0,0,.111.007.941.941,0,0,0,.722-.341l21.133-.265a.952.952,0,0,0,.157.015h.044a.861.861,0,0,0,.125-.018l.853-.011a1.953,1.953,0,0,1,1.434.58,1.987,1.987,0,0,1,.592,1.43l-.021,5.346a.941.941,0,0,0,.937.944h0a.94.94,0,0,0,.94-.937l.021-5.346a3.883,3.883,0,0,0-3.88-3.9m-27.015-9.051-10.438-7.114a8.7,8.7,0,0,1-3.437-4.716L2.165,107.99a5.393,5.393,0,0,1-.278-1.978A3.658,3.658,0,0,1,4.972,102.5,3.436,3.436,0,0,1,8.9,104.586c.03.068.057.137.084.207l6.6,17.344a4.859,4.859,0,0,0-.436.489,4.767,4.767,0,0,0,.895,6.681l12.643,9.656a.941.941,0,0,0,1.142-1.5l-12.643-9.656a2.885,2.885,0,1,1,3.5-4.586L32.1,131.942a4.255,4.255,0,0,0,3.52.773l.862-.193a9.339,9.339,0,0,1,11.358,8.533l.6,12.969-20.02.252a11.326,11.326,0,0,0-4.962-9.328"
        transform="translate(0 -46.512)"
        fill="#960b38"
      />
      <path
        id="Caminho_957"
        data-name="Caminho 957"
        d="M99.185,99.156a2.147,2.147,0,0,0,2.111-2.509L99.522,86.3a.262.262,0,0,1,.076-.232l7.515-7.326a2.144,2.144,0,0,0-1.188-3.657L95.538,73.579a.263.263,0,0,1-.2-.143L90.7,64.023h0a2.132,2.132,0,0,0-1.923-1.195h0a2.13,2.13,0,0,0-1.922,1.2l-4.645,9.411a.262.262,0,0,1-.2.144L71.621,75.088a2.144,2.144,0,0,0-1.189,3.657l7.516,7.326a.262.262,0,0,1,.076.233L76.249,96.648a2.144,2.144,0,0,0,3.111,2.26l9.29-4.884a.263.263,0,0,1,.245,0l9.29,4.885a2.148,2.148,0,0,0,1,.248M88.773,92.113a2.144,2.144,0,0,0-1,.246l-9.29,4.884a.263.263,0,0,1-.382-.277l1.775-10.344a2.144,2.144,0,0,0-.616-1.9L71.745,77.4a.263.263,0,0,1,.146-.448L82.278,75.44a2.143,2.143,0,0,0,1.614-1.173l4.645-9.411a.263.263,0,0,1,.471,0l4.645,9.412a2.144,2.144,0,0,0,1.614,1.173l10.387,1.509a.263.263,0,0,1,.146.449l-7.516,7.326a2.143,2.143,0,0,0-.616,1.9l1.774,10.345a.263.263,0,0,1-.382.277l-9.29-4.884a2.144,2.144,0,0,0-1-.246"
        transform="translate(-32.28 -29.063)"
        fill="#fb9335"
      />
      <path
        id="Caminho_958"
        data-name="Caminho 958"
        d="M133.411,64.022l-2.9,5.874a.941.941,0,0,0,1.687.832l2.9-5.874a.263.263,0,0,1,.471,0l4.644,9.411a2.142,2.142,0,0,0,1.614,1.174l10.387,1.509a.263.263,0,0,1,.146.449l-7.515,7.326a2.142,2.142,0,0,0-.617,1.9L146,96.965a.263.263,0,0,1-.382.277L139.6,94.079a.941.941,0,0,0-.875,1.665l6.016,3.163a2.144,2.144,0,0,0,3.111-2.26L146.082,86.3a.266.266,0,0,1,.076-.234l7.515-7.326a2.144,2.144,0,0,0-1.188-3.657L142.1,73.578a.262.262,0,0,1-.2-.143l-4.645-9.412a2.144,2.144,0,0,0-3.845,0"
        transform="translate(-60.327 -29.062)"
        fill="#fb9335"
      />
      <path
        id="Caminho_959"
        data-name="Caminho 959"
        d="M43.5,86.07a.263.263,0,0,1,.076.233L41.8,96.648a2.141,2.141,0,0,0,3.111,2.26l6.016-3.163a.94.94,0,1,0-.875-1.664l-6.016,3.163a.263.263,0,0,1-.382-.277l1.774-10.344a2.142,2.142,0,0,0-.616-1.9L37.3,77.4a.263.263,0,0,1,.145-.448l10.387-1.51a2.144,2.144,0,0,0,1.614-1.173l4.645-9.412a.263.263,0,0,1,.471,0l2.9,5.874a.94.94,0,1,0,1.686-.832l-2.9-5.873a2.144,2.144,0,0,0-3.845,0l-4.645,9.411a.263.263,0,0,1-.2.143l-10.387,1.51a2.144,2.144,0,0,0-1.188,3.657Z"
        transform="translate(-16.344 -29.062)"
        fill="#fb9335"
      />
      <path
        id="Caminho_960"
        data-name="Caminho 960"
        d="M73.328,32.725a.94.94,0,0,0,1.188-.6c2.1-6.343,8.376-10.606,15.608-10.606,7.409,0,13.9,4.586,15.777,11.153a.941.941,0,0,0,.9.681.92.92,0,0,0,.26-.037.94.94,0,0,0,.645-1.163,17.607,17.607,0,0,0-13.112-12,10.583,10.583,0,1,0-8.958,0,17.555,17.555,0,0,0-12.91,11.382.941.941,0,0,0,.6,1.189m8.1-22.142a8.7,8.7,0,1,1,8.7,8.7,8.711,8.711,0,0,1-8.7-8.7"
        transform="translate(-33.621 0)"
        fill="#960b38"
      />
    </g>
  </svg>
</template>

<script lang="ts">
export default {
  props: {
    width: {
      type: String,
      default: '80px',
    },
    height: {
      type: String,
      default: '80px',
    },
  },
}
</script>

<template>
  <svg
    id="Grupo_444"
    data-name="Grupo 444"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="117"
    height="97.913"
    viewBox="0 0 117 97.913"
  >
    <g id="Grupo_443" data-name="Grupo 443">
      <path
        id="Caminho_996"
        data-name="Caminho 996"
        d="M37.565,97.913a3.871,3.871,0,0,1-3.135-1.576L7.536,60.135,7.513,60.1A37.566,37.566,0,0,1,35.592.05,37.565,37.565,0,0,1,67.617,60.1l-.023.032L40.7,96.336a3.87,3.87,0,0,1-3.135,1.576M9.921,58.425,36.786,94.586a.97.97,0,0,0,1.558,0L65.208,58.425l.035-.048A34.63,34.63,0,1,0,2.935,37.563,34.327,34.327,0,0,0,9.887,58.377l.035.048"
        transform="translate(0 0)"
        fill="#960b38"
      />
      <path
        id="Caminho_997"
        data-name="Caminho 997"
        d="M69.108,90.342a21.2,21.2,0,1,1,21.2-21.2,21.223,21.223,0,0,1-21.2,21.2m0-39.464a18.175,18.175,0,1,0,5.432.821,18.285,18.285,0,0,0-5.432-.821"
        transform="translate(-31.495 -31.517)"
        fill="#960b38"
      />
      <path
        id="Caminho_998"
        data-name="Caminho 998"
        d="M222.187,157.373a3.076,3.076,0,0,1-2.491-1.253l-18.043-24.287c-.487-.648-.954-1.333-1.381-2.03-.37-.6-.719-1.232-1.038-1.863a1.468,1.468,0,0,1,2.622-1.32c.282.559.591,1.114.919,1.65.379.619.793,1.226,1.229,1.805l18.048,24.295a.168.168,0,0,0,.269,0l18.043-24.287a22.759,22.759,0,0,0-26.413-34.894c-.705.274-1.406.588-2.081.932a1.468,1.468,0,0,1-1.333-2.615,25.687,25.687,0,0,1,32.177,38.335L224.678,156.12a3.075,3.075,0,0,1-2.49,1.253"
        transform="translate(-130.87 -59.637)"
        fill="#1f214a"
      />
      <path
        id="Caminho_999"
        data-name="Caminho 999"
        d="M241.373,153.714a13.161,13.161,0,1,1,13.16-13.16,13.175,13.175,0,0,1-13.16,13.16m0-23.385a10.173,10.173,0,1,0,3.041.459,10.236,10.236,0,0,0-3.041-.459"
        transform="translate(-150.023 -83.747)"
        fill="#1f214a"
      />
    </g>
  </svg>
</template>
